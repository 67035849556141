const notificationData = [
  {
    key: "high_trafic_alert",
    title: "Alertes de Volume Élevé",
    description:
      "Notifications lorsque le volume de mails ou de demandes  dépasse un certain seuil, indiquant une actvité inhabituelle ou un pic de demandes (Ajouter la possibilité d’avoir un seuil journalier)",
  },
  {
    key: "email_transfer",
    title: "Notifications de Transfert de Mail",
    description:
      "Alertes lorsqu'un mail est transféré à un membre de l'équipe ou à un département spécifique, en fonction des règles de transfert        définies",
  },
  {
    key: "urgent_response_alert",
    title: "Alertes de Réponse Urgente",
    description:
      " Notifications pour les mails marqués comme urgents ou ceux nécessitant une attention immédiate, comme les réclamations ou les problèmes techniques majeurs. (Toutes les 4 heures par exemple)",
  },
  {
    key: "follow_up_reminder",
    title: "Rappels de Suivi",
    description:
      "Notifications pour les mails ou les tickets qui nécessitent un suivi, en particulier ceux restés sans réponse pendant une durée spécifiée. Peut être 24h par exemple)",
  },
  {
    key: "tag_updates",
    title: "Mises à Jour des Tags",
    description:
      "Alertes lorsque de nouveaux tags sont utilisés ou lorsque des tags existants connaissent une augmentation d'activité.",
  },
  {
    key: "performance_updates",
    title: "Notfications de Performance",
    description:
      "Rapports périodiques sur les performances de l'IA, y compris les taux de réponse, l'efficacité, et les retours clients (Choisir un délai, 7 jours  par exemple)",
  },
  {
    key: "security_alerts",
    title: "Alertes de Sécurité",
    description:
      "Notifications en cas de problèmes de sécurité potentiels, comme  des tentatives de phishing ou des activités suspectes (tentatives d’envoi massive d’emails provenant de même adresse IP)",

  },
  {
    key: "important_customer_feedback",
    title: "Feedback Client Important",
    description:
      " Alertes pour les retours clients particulièrement positifs ou négatifs qui pourraient nécessiter une attention spéciale",
  },
  {
    key: "system_updates",
    title: "Mises à Jour du Système",
    description:
      "Notifications concernant les mises à jour du système, les maintenances planifiées, ou les interruptions de service",
  },
  {
    key: "technical_issue_alerts",
    title: "Alertes de Problèmes Techniques",
    description:
      "Notifications en cas de dysfonctionnement de l'IA ou de problèmes techniques impactant le service",
  },
];
export default notificationData;
