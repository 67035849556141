import {
  UPDATE_VISIBILITY,
} from "./actionTypes"

export const updateVisibility = (show) => {
  console.error("show value", show);
  
  return {
    type: UPDATE_VISIBILITY,
    payload: { show },
  }
} 
