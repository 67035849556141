
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import affiliateMarketing from "../../assets/images/affiliate-marketing.png";
import payment from "../../assets/images/charity.png";
import compliant from "../../assets/images/compliant.png";
import error from "../../assets/images/error.png";
import projectmanagment from "../../assets/images/project-management.png";
import warning from "../../assets/images/warning.png";
import { Link } from "react-router-dom";

const Help = () => {

  // modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  };
  const svgs = {
    known_issues: <svg xmlns="http://www.w3.org/2000/svg" width="98" height="62" viewBox="0 0 98 92" fill="none">
      <path d="M89.2961 92H9.01266C5.98094 92 3.26319 90.434 1.74287 87.8111C0.253669 85.2415 0.230904 82.2107 1.68027 79.495L41.8224 4.30029C43.2598 1.60758 46.0009 0 49.1544 0C52.3081 0 55.049 1.60758 56.4866 4.30029L96.6285 79.4949C98.0783 82.2103 98.0553 85.2417 96.5659 87.8111C95.0458 90.434 92.3282 92 89.2961 92Z" fill="#6B5EFF" />
      <path d="M96.6304 79.4949L56.4884 4.30029C55.0508 1.60758 52.3099 0 49.1562 0V92H89.2982C92.3301 92 95.0478 90.434 96.5678 87.8111C98.057 85.2417 98.0799 82.2103 96.6304 79.4949Z" fill="#542BDA" />
      <path d="M49.1508 79.6733C44.9666 79.6733 41.5625 76.2694 41.5625 72.0852C41.5625 67.901 44.9666 64.4969 49.1508 64.4969C53.335 64.4969 56.7391 67.901 56.7391 72.0852C56.7391 76.2694 53.335 79.6733 49.1508 79.6733ZM49.151 56.9086C44.9666 56.9086 41.5625 53.5045 41.5625 49.3203V32.9106C41.5625 28.7264 44.9666 25.3223 49.1508 25.3223C53.335 25.3223 56.7391 28.7264 56.7391 32.9106V49.3203C56.7391 53.5045 53.3352 56.9086 49.151 56.9086Z" fill="#FFC042" />
      <path d="M49.1564 25.3223H49.1562V56.9086H49.1566C53.3408 56.9086 56.7447 53.5045 56.7447 49.3203V32.9106C56.7447 28.7266 53.3406 25.3223 49.1564 25.3223ZM49.1564 64.4969H49.1562V79.6733H49.1564C53.3406 79.6733 56.7447 76.2694 56.7447 72.0852C56.7447 67.901 53.3406 64.4969 49.1564 64.4969Z" fill="#FFAA00" />
    </svg>,
    arrow_fwrd : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M11.9716 15.7637L20 8.03942L11.9716 0.315174C11.8991 0.224039 11.807 0.148879 11.7015 0.0947829C11.596 0.0406863 11.4796 0.00891594 11.3601 0.00162216C11.2407 -0.00567163 11.121 0.011682 11.0092 0.0525076C10.8974 0.0933332 10.796 0.156678 10.712 0.238253C10.628 0.319829 10.5633 0.417731 10.5222 0.525331C10.4812 0.632931 10.4647 0.747717 10.4741 0.861919C10.4834 0.97612 10.5182 1.08707 10.5763 1.18726C10.6343 1.28745 10.7141 1.37454 10.8104 1.44263L16.817 7.23981L0.835422 7.23981C0.613855 7.23981 0.401363 7.32405 0.24469 7.47401C0.0880182 7.62396 -2.69305e-07 7.82735 -2.76601e-07 8.03942C-2.83898e-07 8.25149 0.0880181 8.45487 0.24469 8.60483C0.401363 8.75479 0.613855 8.83903 0.835422 8.83903L16.817 8.83903L10.8104 14.6362C10.6542 14.7868 10.5668 14.9906 10.5676 15.2028C10.5684 15.415 10.6572 15.6182 10.8145 15.7677C10.9718 15.9172 11.1848 16.0007 11.4065 16C11.6282 15.9992 11.8405 15.9142 11.9967 15.7637L11.9716 15.7637Z" fill="#FF7049"/>
  </svg>, 
  close_icon : <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.37 19.6142C19.0771 19.8983 18.778 20.038 18.4725 20.0334C18.1504 20.0115 17.8473 19.8542 17.5632 19.5613L12.9511 14.8068L8.27403 19.3437C7.98118 19.6278 7.68204 19.7675 7.3766 19.7629C7.07117 19.7582 6.78476 19.6181 6.5174 19.3425C6.25003 19.0668 6.11866 18.7763 6.12331 18.4709C6.12795 18.1654 6.2767 17.8707 6.56955 17.5866L11.2466 13.0497L6.78484 8.45016C6.48405 8.14008 6.33598 7.83232 6.34062 7.52688C6.34527 7.22145 6.50263 6.91834 6.81271 6.61755C7.10556 6.33347 7.39609 6.2021 7.6843 6.22346C7.98973 6.2281 8.29284 6.38546 8.59364 6.69554L13.0554 11.295L17.7324 6.75812C18.0252 6.47404 18.3244 6.33432 18.6298 6.33896C18.9353 6.34361 19.2217 6.48374 19.489 6.75937C19.7564 7.03499 19.8878 7.32552 19.8831 7.63096C19.8785 7.93639 19.7297 8.23115 19.4369 8.51523L14.7598 13.0522L19.372 17.8067C19.6561 18.0996 19.8041 18.4073 19.8162 18.73C19.8288 19.0187 19.68 19.3135 19.37 19.6142Z" fill="currentColor"/>
  </svg>
  }
  return (
    <React.Fragment>
      <Modal isOpen={modal_list} toggle={tog_list} centered className="help-page-modal">
        <ModalBody>
          <Button className="close_button" style={{backgroundColor:"transparent", border:"none", padding:"0px", float:"right"}} onClick={()=>{tog_list()}}>{svgs.close_icon}</Button>
          <h1 className="modal_title" style={{marginTop:"8%", marginBottom:"6%"}}>login issue</h1>
          <Row className="gap-4 mb-5" style={{paddingLeft:"8%", paddingRight:"8%"}}>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between">eget congue elit diam eget nulla? {svgs.arrow_fwrd}</Link>
            </Col>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between">In vel nibh tellus. Fusce tincidunt elit nec tortor pharetra? {svgs.arrow_fwrd}</Link>
            </Col>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between">eget congue elit diam eget nulla? {svgs.arrow_fwrd}</Link>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content help-page">
        <div className="mx-auto col-xxl-9 col-xl-11" style={{ paddingTop: "34px" }}>
          <Breadcrumbs title="iAssistant" breadcrumbItem="Help" />
          <Card style={{
            borderRadius: "20px",
            background: "#563BFF",
            boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.05)",
            paddingTop: "40px",
          }}>
            <CardTitle className="mb-0 font-tertiary" style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "38.869px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "112.5%"
            }}>How can we help?
            </CardTitle>
            <CardBody className="p-0">
              <InputGroup
                className="mx-auto"
                style={{
                  backgroundColor: "#F9FAFB",
                  borderRadius: "13.511px",
                  marginTop: "40px",
                  marginBottom: "69px",
                  maxWidth: "625px",
                }}
              >
                <Input
                  className="font-primary"
                  placeholder="Recherche..."
                  style={{
                    font: "normal 400 15.2px/normal",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791",
                  }}
                // value={recherche}
                // onChange={(e) => setRecherche(e.target.value)}
                // onKeyPress={handleKeyPressForSearch}
                />
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <button
                    className="btn"
                    style={{ padding: "0", border: "none" }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                      <path d="M27.04 25.6258L19.4868 18.0726C21.3019 15.8936 22.207 13.0987 22.0139 10.2693C21.8207 7.4399 20.5442 4.7939 18.4499 2.88173C16.3556 0.969553 13.6046 -0.0615751 10.7694 0.00284662C7.93418 0.0672683 5.23293 1.22228 3.22761 3.22761C1.22228 5.23293 0.0672683 7.93418 0.00284662 10.7694C-0.0615751 13.6046 0.969553 16.3556 2.88173 18.4499C4.7939 20.5442 7.4399 21.8207 10.2693 22.0139C13.0987 22.207 15.8936 21.3019 18.0726 19.4868L25.6258 27.04L27.04 25.6258ZM2.03608 11.0375C2.03608 9.25718 2.564 7.51685 3.55309 6.03658C4.54218 4.5563 5.94801 3.40257 7.5928 2.72127C9.23759 2.03998 11.0475 1.86172 12.7936 2.20904C14.5397 2.55636 16.1436 3.41366 17.4025 4.67253C18.6613 5.9314 19.5186 7.5353 19.8659 9.2814C20.2133 11.0275 20.035 12.8374 19.3537 14.4822C18.6724 16.127 17.5187 17.5328 16.0384 18.5219C14.5581 19.511 12.8178 20.0389 11.0375 20.0389C8.65098 20.0363 6.36297 19.087 4.67546 17.3995C2.98794 15.712 2.03873 13.424 2.03608 11.0375Z" fill="#FF7049" />
                    </svg>
                  </button>
                </InputGroupText>
              </InputGroup>
            </CardBody>
          </Card>
          <Col style={{ marginTop: "60px", marginBottom:"60px" }}>
            <Row className="row-gap-4">
              <Col xl={4}>
                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                    <img src={error} height={62}/>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">login issue</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>

                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                    <img src={payment} height={62}/>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">payment help</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>
                </Card>

              </Col>
              <Col xl={4}>

                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                    <img src={projectmanagment} height={62}/>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">plan and invoice</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>

                </Card>
              </Col>
            {/* </Row> */}
            {/* <Row style={{ marginTop: "40px" }}> */}
              <Col xl={4}>

                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                    <img src={compliant} height={62}/>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">privacy</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>
                </Card>

              </Col>
              <Col xl={4}>

                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                    <img src={affiliateMarketing} height={62}/>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">affiliations</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>

                </Card>
              </Col>
              <Col xl={4}>

                <Card className="mb-0 help__section_card" onClick={()=>{tog_list()}}>
                  <CardBody className="d-flex align-items-center gap-4">
                  <img src={warning} height={62}/>
                  {/* {svgs.known_issues} */}
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2">Known Issues</div>
                      <p className="help__section_articles mb-0">3 Articles</p>
                    </div>
                  </CardBody>

                </Card>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Help;
