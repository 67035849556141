import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../withRouter';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

import { Client, Account } from 'appwrite';

const ProfileMenu = (props) => {
  const navigate = useNavigate();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState('Admin');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake' || process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.username);
      }
    } else {
      navigate('/login', {
        replace: false,
      });
    }
  }, [props.success, props.user_infos, navigate]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="p-0 bg-transparent border-0" id="page-header-user-dropdown" tag="button">
          {/* <img className="header-profile-user" src={user1} alt="Header Avatar" /> */}
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              fontSize: '230%',
              backgroundColor: 'grey',
              color: 'white',
              padding: '0',
              borderRadius: '13.511px',
              textAlign: 'center',
              width: '50.667px',
              height: '50.667px',
            }}>
            {(props.user_infos ? props.user_infos.name : '')
              .split(' ')
              .map((part) => part.charAt(0).toUpperCase())
              .join('')}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="user-name d-none d-xl-inline-block ms-3">
              {/* {username} */}
              {props.user_infos ? props.user_infos.name : ''}
              <i className="user-chevron-down-icon mdi mdi-chevron-down d-none d-xl-inline-block" style={{ marginLeft: '14px' }} />
            </span>
            <span className="profile-activity ms-3">
              <span className="me-1">Actif</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none" className="profile-activity-dot">
                <path
                  d="M3.34802 6.29629C4.9026 6.29629 6.16283 5.03605 6.16283 3.48147C6.16283 1.92689 4.9026 0.666656 3.34802 0.666656C1.79344 0.666656 0.533203 1.92689 0.533203 3.48147C0.533203 5.03605 1.79344 6.29629 3.34802 6.29629Z"
                  fill="#1E8E3E"
                />
              </svg>
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/settings">
            {' '}
            <i className="ri-user-line align-middle me-2" />
            {props.t('Profile')}{' '}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <i className="ri-wallet-2-line align-middle me-2" />
            {props.t('My Wallet')}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-2" />
            {props.t('Settings')}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t('Lock screen')}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
