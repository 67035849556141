import React, { useEffect, useState } from "react";
import ListTables from "./list";

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";


const Resources = () => {
  return (
    <React.Fragment>
      <div className="page-content ressources-content" style={{ paddingLeft: "0" }}>
        <ListTables></ListTables>
      </div>
    </React.Fragment>
  );
};
export default Resources;
