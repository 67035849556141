import {
  UPDATE_VISIBILITY,
} from "./actionTypes";

const initialState = {
    show: false,
};
const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VISIBILITY:
     return {
        ...state,
       show: action.payload.show,
      }; 
    default:
      return state;
  }
};

export default modalReducer;
