import React, { useState, useEffect } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.scss";
import COUNTRY_CODE_NAME from "../Utility/CountryCodeName";

const Vectormap = (props) => {
  const [addresses, setAddresses] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipCity, setTooltipCity] = useState("");

  const handleMouseMove = (e) => {
    const x = e.pageX;
    const y = e.pageY;
    setTooltipPosition({ x, y });
    console.log("setTooltipPosition", x, y);
  };

  const clickFunction = (event, city) => {
    setTooltipCity(city); 
    handleMouseMove(event);
  };

  useEffect(() => {
    const data = props.message;
    setAddresses(data);
  }, [props.message]);

  return (
    <>
      <div
        style={{ width: props.width, height: 500, position: "relative" }}
        onMouseMove={handleMouseMove}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: tooltipPosition.y - 30, // Ajustez cette valeur pour positionner la tooltip au-dessus du curseur
            border: "solid 1px #cdcdcd",
            borderRadius: "3px",
            backgroundColor: "#292929",
            color: "white",
            fontSize: "smaller",
            padding: "3px",
            fontFamily: "sans-serif, Verdana",
          }}
        >
          { COUNTRY_CODE_NAME[tooltipCity]}
        </div>
        <VectorMap
          map={props.value}
          backgroundColor="transparent"
          containerStyle={{
            width: "100%",
            height: "90%",
          }}
          zoomOnScroll={false}
          onRegionOver={(event, code) => {
            clickFunction(event, code);
          }}
          regionStyle={{
            initial: {
              fill: "#563bff",
              "fill-opacity": 1,
              stroke: "white",
              "stroke-width": 0.1,
              "stroke-opacity": 1,
            },
            hover: {
              "fill-opacity": 0.8,
              cursor: "pointer",
            },
            selected: {
              fill: "#2938bc", // La couleur du pays cliqué
            },
            selectedHover: {},
          }}
          markers={addresses}
          onMarkerOver={(event, code) => {
            clickFunction(event, code);
          }}
          markerStyle={{
            initial: {
              fill: "#18d96c",
              stroke: "transparent",
              "fill-opacity": 1,
              "stroke-width": 1,
              "stroke-opacity": 1,
              r: 5,
            },
            hover: {
              stroke: "#14296c",
              "stroke-width": 2,
              cursor: "pointer",
            },
            selected: {
              fill: "blue",
            },
            selectedHover: {},
          }}
          containerClassName="map"
        />
      </div>
    </>
  );
};

export default Vectormap;
