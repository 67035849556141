import React, { useState, useCallback, useEffect, useRef, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import sidebarData from './SidebarData';
//Simple bar
import SimpleBar from 'simplebar-react';
// MetisMenu
import MetisMenu from 'metismenujs';
import withRouter from '../../components/Common/withRouter';
import { Link } from 'react-router-dom';
//i18n
import { withTranslation } from 'react-i18next';
import { updateVisibility } from '../../store/actions';
import { UPDATE_VISIBILITY } from '../../store/modal/actionTypes';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { Client, Account, Databases, Query, ID } from 'appwrite';
import axios from 'axios';

const Sidebar = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [currentEmployee, setCurrentEmployee] = useState(undefined);
  const [ownerInfos, setOwnerInfos] = useState({});
  const [totalUnseen, setBoxesTotalUnseen] = useState(undefined);

  const { store } = useContext(ReactReduxContext);
  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }
    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El = parent.childNodes && parent.childNodes.length && parent.childNodes[1] ? parent.childNodes[1] : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }
        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove('mm-show');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };
  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu-item');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);
    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  async function getEmployeeLink() {
    var user_data_string = localStorage.getItem('authUser');

    if (!user_data_string) return;

    var user = JSON.parse(user_data_string);

    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.listDocuments('default', 'employee_link', [Query.equal('employee_id', user.userId)]);

    return promise.then(
      async function (response) {
        console.log('Sidebar.js: Employee link is ', response.documents);
        return response.documents;
      },
      function (error) {
        console.log('Error creating Employee link', error); // Failure
        return null;
      }
    );
  }

  function setDatas(employeeData) {
    console.log('setDatas entered');
    if (employeeData.length > 0) {
      console.log('employeeData.length > 0');
      if (localStorage.getItem('authUser')) {
        // console.log('Got localStorage authUser');
        const userId = JSON.parse(localStorage.getItem('authUser')).userId;
        const employeesList = employeeData[0].employees;
        const employees = employeesList.map((element) => JSON.parse(element));
        const currentEmployee = employees.filter((element) => element.id === userId);
        if (currentEmployee && currentEmployee.length > 0) {
          setCurrentEmployee(currentEmployee[0]);
        }
        if (employeeData[0].owner) {
          setOwnerInfos(JSON.parse(employeeData[0].owner));
        }
      } else {
        console.log('localStorage authUser is null');
      }
    } else {
      console.log('employeeData.length is 0');
      getUserAccount();
    }
  }

  async function getCompanyEmployeesData(company_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.listDocuments('default', 'employee', [Query.equal('company_id', company_id)]);

    return promise.then(
      async function (response) {
        console.log('Got company employees data');
        const employeeData = response.documents;
        setDatas(employeeData);
        return employeeData;
      },
      function (error) {
        console.log('Error listing company employees data', error);
        console.log(error); // Failure
        return null;
      }
    );
  }

  async function createEmployeeLink(employee_account_id, company_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'employee_link', ID.unique(), { employee_id: employee_account_id, company_id: company_id });

    return promise.then(
      async function (response) {
        console.log('Employee link created ', response); // Failure
        return response;
      },
      function (error) {
        console.log('Error creating Employee link', error); // Failure
        return null;
      }
    );
  }

  async function triggerTimeout() {
    console.log('Start timeout from Sidebar.js');
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await getUserAccount();
  }

  async function createCompanyEmployeesData(owner, employees, company_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'employee', ID.unique(), {
      owner: owner,
      employees: employees.map((element) => JSON.stringify(element)),
      company_id: company_id,
    });

    return promise.then(
      async function (response) {
        console.log('Employees list created ', response); // Failure
        setDatas([response]);
        return response;
      },
      function (error) {
        console.log('Error creating Employees list', error); // Failure
        return null;
      }
    );
  }

  const fetchMailBoxes = async function (company_infos_id) {
    const apiUrl = 'https://mailer-server-test.semecloud.tech/get-boxes';
    // const apiUrl = "http://localhost:55000/get-boxes";
    axios
      .get(apiUrl, {
        params: {
          company_id: company_infos_id,
        },
      })
      .then(async (response) => {
        // console.log("all boxes (sidebar)", response);
        const theTotalUnseen = response.folders.reduce((total, folder) => {
          return total + (folder.status && folder.status.UNSEEN ? folder.status.UNSEEN : 0);
        }, 0);
        setBoxesTotalUnseen(theTotalUnseen);
      })
      .catch((error) => {
        // Handle the error
        console.log('Boxes fetch error', error);
      });
  };

  async function getUserAccount() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = account.get();
    return promise.then(
      async function (response) {
        console.log('userAccount is', response);
        // setRequest(false);
        //     if(company_infos.mail_config){
        //    }
        //fetchMailBoxes(data);
        const user = response;
        // setUserInfos(user);

        let employeeLink = await getEmployeeLink();
        if (!employeeLink) {
          triggerTimeout();
        } else if (employeeLink && employeeLink.length === 0) {
          if (user.prefs && user.prefs['owner']) {
            const theEmployeeLink = await createEmployeeLink(user.$id, user.$id);
            localStorage.setItem('companyId', theEmployeeLink.company_id);
            fetchMailBoxes(theEmployeeLink.company_id);
            await createCompanyEmployeesData(
              JSON.stringify({
                id: user.$id,
                last_name: user.name,
                first_name: '',
                email: user.email,
              }),
              [],
              user.$id
            );
          }
        } else if (employeeLink && employeeLink.length > 0) {
          localStorage.setItem('companyId', employeeLink[0].company_id);
          fetchMailBoxes(employeeLink[0].company_id);

          const employees = await getCompanyEmployeesData(employeeLink[0].company_id);
          if (employees && employees.length === 0) {
            if (user.prefs && user.prefs['owner']) {
              await createCompanyEmployeesData(
                JSON.stringify({
                  id: user.$id,
                  last_name: user.name,
                  first_name: '',
                  email: user.email,
                }),
                [],
                user.$id
              );
            }
          }
        }
      },
      function (error) {
        console.log('Error getting user Account ', error);
        console.log(error); // Failure
        // setRequest(false);
      }
    );
  }

  useEffect(() => {
    ref.current.recalculate();
  }, []);
  useEffect(() => {
    new MetisMenu('#side-menu-item');
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  useEffect(() => {
    console.log('Enter useEffect');

    if (localStorage.getItem('companyId')) {
      console.log('has companyId is localStorage', localStorage.getItem('companyId'));
      getCompanyEmployeesData(localStorage.getItem('companyId'));
      fetchMailBoxes(localStorage.getItem('companyId'));
    } else {
      getUserAccount();
    }
  }, []);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {(sidebarData || []).map((item, sidebarDataIndex) =>
                item.isMainMenu ? (
                  <li key={sidebarDataIndex} className="menu-title">
                    {props.t(item.label)}
                  </li>
                ) : (currentEmployee && currentEmployee.autorizations && item.id && currentEmployee.autorizations.includes(item.id)) ||
                  (localStorage.getItem('authUser') && ownerInfos.id === JSON.parse(localStorage.getItem('authUser')).userId) ? (
                  <li key={sidebarDataIndex}>
                    <Link to={item.url ? item.url : '/#'} className={item.issubMenubadge || item.isHasArrow ? ' ' : 'has-arrow'}>
                      {props.t(item.label) !== 'OnBoarding' ? (
                        <>{item.icon}</>
                      ) : (
                        <i
                          className={item.icon}
                          style={{
                            marginRight: '5px',
                            color: 'white !important',
                          }}></i>
                      )}

                      {props.t(item.label) === 'Email' && (
                        <span
                          className="position-absolute top-0 end-0 translate-middle-y badge"
                          style={{
                            minWidth: '28px',
                            borderRadius: '6px',
                            boxShadow: '0px 0px 9px 0px rgba(0, 0, 0, 0.10)',
                            font: 'normal 500 12px/16px Poppins, sans-serif',
                          }}>
                          {totalUnseen}
                        </span>
                      )}
                      {/* 
                        <span
                          className={
                            "badge rounded-pill float-end " + item.bgcolor
                          }
                        >
                          {" "}
                          {item.badgeValue}{" "}
                        </span>
                      */}
                      <span className="menu-item-label">{props.t(item.label)}</span>
                    </Link>
                    {item.subItem && (
                      <ul key={sidebarDataIndex} className="sub-menu">
                        {item.subItem.map((item, item_index) => (
                          <li key={item_index}>
                            {item.action ? (
                              <Link to={item.link ? item.link : '#'} className={item.subMenu && 'has-arrow waves-effect'}>
                                <span
                                  className=""
                                  onClick={() => {
                                    dispatch(updateVisibility(true));
                                  }}>
                                  {props.t(item.sublabel)}
                                </span>
                              </Link>
                            ) : (
                              <Link to={item.link ? item.link : '#'} className={item.subMenu && 'has-arrow waves-effect'}>
                                {' '}
                                {props.t(item.sublabel)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Sidebar));
