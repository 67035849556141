export default function getFrenchTag(tag) {
  switch (tag) {
    case 'product_info':
      return 'Info prod.';
    case 'product_availability':
      return 'Dispo. prod.';
    case 'product_advice':
      return 'Conseils prod.';
    case 'product_problems':
      return 'Probl. prod.';
    case 'product_price':
      return 'Prix';
    case 'promotions':
      return 'Promos';
    case 'order_info':
      return 'Info cmd';
    case 'order_process':
      return 'Cmd. en cours';
    case 'payments':
      return 'Paiements';
    case 'refunds':
      return 'Rembours.';
    case 'disputes':
      return 'Litiges';
    case 'billing':
      return 'Facturation';
    case 'delivery':
      return 'Livraison';
    case 'returns':
      return 'Retours';
    case 'customer_account':
      return 'Compte client';
    case 'password':
      return 'Mot de passe';
    case 'security':
      return 'Sécurité';
    case 'privacy':
      return 'Confidentialité';
    case 'customer_data':
      return 'Données client';
    case 'after_sales':
      return 'S.A.V.';
    case 'technical_problems':
      return 'Probl. tech.';
    case 'website_feedback':
      return 'Feedback web';
    case 'special_programs':
      return 'Prog. spéciaux';
    case 'affiliation':
      return 'Affiliation';
    case 'customer_loyalty':
      return 'Fidélité client';
    case 'feedback':
      return 'Retour client';
    case 'complaints':
      return 'Réclamations';
    case 'company_information':
      return 'Info. société';
    case 'ethics_social_responsibility':
      return 'Éthique & resp. soc.';
    case 'subscription_management':
      return 'Gest. abonnements';
    case 'store_services':
      return 'Serv. en mag.';
    case 'influencers':
      return 'Influenceurs';
    case 'content_creators':
      return 'Créa. contenu';
    case 'spam':
      return 'Spam';
    case 'other':
      return 'Autre';
    case 'Monday':
      return 'Lundi';
    case 'Tuesday':
      return 'Mardi';
    case 'Wednesday':
      return 'Mercredi';
    case 'Thursday':
      return 'Jeudi';
    case 'Friday':
      return 'Vendredi';
    case 'Saturday':
      return 'Samedi';
    case 'Sunday':
      return 'Dimanche';
    case 'Drafts':
      return 'Brouillons';
    case 'Trash':
      return 'Corbeille';
    case 'Untreated':
      return 'Non traités';
    case 'Treated':
      return 'Traités';
    case 'Spam':
      return 'Spam';
    case 'Sent':
      return 'Envoyés';
    case 'Starred':
      return 'Suivis';
    default:
      return tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase();
  }
}
