import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'; // Si vous utilisez React Router

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    TabPane,
    Progress,
    NavLink,
    NavItem,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ShopifyConfiguration from "./shopifyConfiguration";
import WordPressConfiguration from "./wordPressConfiguration";
import FilesConfiguration from "./filesConfiguration";
import WebConfiguration from "./webConfiguration";
const Configuration = () => {
    const [shopify_store_url, setShopifyStoreUrl] = useState('');
    const [shopify_api_key, setShopifyStoreApiKey] = useState('');
    let componentToRender;
    const searchParams = new URLSearchParams(window.location.search);
    const testValue = searchParams.get("testvalue");
 
    
    switch (searchParams.get("testvalue")) {
        case "Shopify":
            componentToRender = <ShopifyConfiguration />;
            break;
        case "WordPress":
            componentToRender = <WordPressConfiguration />;
            break;
        case "Files":
            componentToRender = <FilesConfiguration />;
            break;
        case "Website":
            componentToRender = <WebConfiguration />;
            break;
        default:
            componentToRender = <div>No matching component</div>;
            break;
    }
    useEffect(() => {

    }, []);
    return (
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs title="iAssistant" breadcrumbItem="Configuration" />
                {componentToRender}
            </Container>
        </div>
    );
};

export default Configuration;
