import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { indexOf } from "lodash";
import getFrenchTag from "../../helpers/local_text_helper";
const values = [
    "product_info",
    "product_availability",
    "product_advice",
    "product_problems",
    "price",
    "promotions",
    "order_process",
    "order_info",
    "payments",
    "refunds",
    "disputes",
    "billing",
    "delivery",
    "returns",
    "customer_account",
    "password",
    "security",
    "privacy",
    "customer_data",
    "after_sales",
    "technical_problems",
    "website_feedback",
    "special_programs",
    "affiliation",
    "customer_loyalty",
    "feedback",
    "complaints",
    "company_information",
    "ethics_social_responsibility",
    "subscription_management",
    "store_services",
    "influencers",
    "content_creators",
    "spam",
    "other"
];

const stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}
const colorMap = {};
values.forEach((value) => {
    colorMap[value] = stringToColour(value);
});

const UntreatedMails = () => {
    //  const PieChartData = {
    //   options: {
    //     labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
    //     colors: ["rgb(61, 142, 248)", "rgb(17, 196, 110)", "#f1b44c", "#f46a6a", "rgb(239, 242, 247)"],
    //     legend: {
    //       show: true,
    //       position: "bottom",
    //       horizontalAlign: "center",
    //       verticalAlign: "middle",
    //       floating: false,
    //       fontSize: "14px",
    //       offsetX: 0,
    //       offsetY: -10,
    //     },
    //     responsive: [
    //       {
    //         breakpoint: 600,
    //         options: {
    //           chart: {
    //             height: 240,
    //           },
    //           legend: {
    //             show: false,
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   series: contactReasonsValueList,
    // };



    let PieChartData = null;
    const navigate = useNavigate();
    const contactReasonsMap = new Map();
    const [contactReasonsValueList, setcontactReasonsValueList] = useState();
    const [contactReasonsKeyList, setcontactReasonsKeyList] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedDates, setSelectedDates] = useState([Date.now() - (7 * 24 * 60 * 60 * 1000), Date.now()]);
    const [calendarOpen, setCalendarOpen] = useState(false);

    const handleDateChange = (dates) => {
        let date = [Date.parse(dates[0]), Date.parse(dates[1])];
        //    if(date[1] !== NaN && date[1] !== undefined){
        // setSelectedDates([Date.parse(date[0]), Date.parse(date[1])]); 
        getMessageData(dates);
        //    }
    };
    async function getMessageData(date) {
        const client = new Client();
        const databases = new Databases(client, 'default');
        client
            .setEndpoint('https://api.shopybase.com/v1')
            .setProject('ai_project_dev');
        return databases.listDocuments("default", "message",
            [
                Query.greaterThanEqual("message_date", Date.parse(date[0])),
                Query.lessThanEqual("message_date", Date.parse(date[1])),
            ]
        ).then(function (response) {
            var data = response.documents;
            if (data.length === 0) {
                contactReasonsMap.set("other", 1);
            } else {
                data.forEach((message) => {
                    const contactReasons = message.contact_reasons;
                    contactReasons.forEach((reason) => {
                        if (contactReasonsMap.has(reason)) {
                            contactReasonsMap.set(reason, contactReasonsMap.get(reason) + 1);
                        } else {
                            contactReasonsMap.set(reason, 1);
                        }
                    });
                });
            }
            setcontactReasonsValueList(Array.from(contactReasonsMap.values()));
            setcontactReasonsKeyList(Array.from(contactReasonsMap.keys()));
            setLoading(false);

        }, function (error) {
            console.log(error); // Echec
            console.log('Failed to fetch message data:', error);
            setLoading(false);

        });
    };

    if (loading) {
        // Use the existing PieChartData when loading is false
        PieChartData = {
            options: {
                labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
                colors: ["rgb(61, 142, 248)", "rgb(17, 196, 110)", "#f1b44c", "#f46a6a", "rgb(239, 242, 247)"],
                legend: {
                    show: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    fontSize: "14px",
                    offsetX: 0,
                    offsetY: -10,
                },
                responsive: [
                    {
                        breakpoint: 600,
                        options: {
                            chart: {
                                height: 240,
                            },
                            legend: {
                                show: false,
                            },
                        },
                    },
                ],
            },
            series: [4, 15, 5],
        };
    }
    else {
        if (contactReasonsKeyList) {
            const colors = contactReasonsKeyList.map((label) => colorMap[label]);
            const defaultColor = "rgb(239, 242, 247)";
            colors.forEach((color, index) => {
                if (!color) {
                    colors[index] = defaultColor;
                }
            });
            const translatedProcessStateKeyList = contactReasonsKeyList.map(tag => getFrenchTag(tag));
            PieChartData = {
                options: {
                    labels: translatedProcessStateKeyList,
                    colors: colors,
                    legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                        verticalAlign: "middle",
                        floating: false,
                        fontSize: "14px",
                        offsetX: 0,
                        offsetY: -10,
                    },
                    responsive: [
                        {
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                },
                series: contactReasonsValueList,
            };
        } else {

            PieChartData = {
                options: {
                    labels: [],
                    colors: [],
                    legend: {
                        show: true,
                        position: "bottom",
                        horizontalAlign: "center",
                        verticalAlign: "middle",
                        floating: false,
                        fontSize: "14px",
                        offsetX: 0,
                        offsetY: -10,
                    },
                    responsive: [
                        {
                            breakpoint: 600,
                            options: {
                                chart: {
                                    height: 240,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                },
                series: [],
            };
        }

    }
    useEffect(() => {
        getMessageData([new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)), new Date()]);
    }, []);

    return (
        <React.Fragment>
            <h5>Mails non traités (40)</h5>
            <p className="card-title-desc">Vue globale des différents mails non traités</p>
          
            {loading ? (
                // Show this when loading is false
                <div>
                    Loading off
                </div>
            ) : (
                <ReactApexChart
                    options={PieChartData.options}
                    series={PieChartData.series}
                    type="pie"
                    height="300"
                    className="apex-charts"
                />
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
                {!contactReasonsKeyList ? null :
                    (
                        <div style={{ textAlign: "center", marginRight: "10px" }}>
                            {contactReasonsKeyList.map((label, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "inline-block",
                                        backgroundColor: PieChartData.options.colors[index],
                                        padding: "5px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        color: "white",
                                        fontSize: "10px",
                                    }}
                                >
                                    {getFrenchTag(label)} ({((contactReasonsValueList[index] * 100 / contactReasonsValueList.reduce((partialSum, a) => partialSum + a, 0))).toFixed(1)} %)
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
             <div style={{ display: "flex", marginLeft:"15px", marginTop:"10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCalendarAlt}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => setCalendarOpen(!calendarOpen)}
                    />
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M, yyyy"
                        options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                        }}
                        value={selectedDates}
                        onChange={handleDateChange}
                        open={calendarOpen}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
export default UntreatedMails;