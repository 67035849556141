import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, InputGroup, InputGroupText, Input} from "reactstrap";

const Breadcrumbs = (props) => {
  const [recherche, setRecherche] = useState("");
  const handleKeyPressForSearch = (event) => {
    // if (event.key === "Enter") {
    //   // Si la touche "Entrée" est pressée, lancez la recherche
    //   if (recherche.length > 0) {
    //     closeMessageView();
    //     searchMails(company_infos);
    //   }
    // }
  };
  const clearSearch = (event) => {
    setRecherche("");
    // fetchMails(company_infos);
  };
  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="page-title">{props.breadcrumbItem}</h4>
            <div className="page-title-right d-flex">
              {/* <Breadcrumb listClassName="m-0">
                <BreadcrumbItem>
                  <Link
                    style={{ color: "black" }}
                    to={props.link ? props.link : "#"}
                  >
                    {props.title}
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb> */}
           
              {/* 
                 <div className="d-inline-block">
                <button
                  type="button"
                  className="info__button"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "6.756px",
                    border: "none",
                    background: "#E8F7F7",
                    padding: "0",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.888672 12.9637C0.888672 12.3269 0.888672 11.6902 0.888672 11.0592C0.900252 11.0071 0.911832 10.9608 0.917623 10.9087C1.12028 8.94056 1.78614 7.15187 2.93838 5.54263C5.8682 1.46164 11.0562 -0.112867 15.6883 1.53111C20.8705 3.3661 23.9508 8.60482 22.9086 14.0056C22.0401 18.4976 19.2897 21.3919 14.9182 22.7407C14.2581 22.9433 13.5807 23.0243 12.8974 23.1111C12.2895 23.1111 11.6815 23.1111 11.0793 23.1111C10.7145 23.059 10.3498 23.0301 9.99078 22.9491C5.52658 21.9881 2.62571 19.3254 1.26503 14.9839C1.06238 14.3298 0.975524 13.6467 0.888672 12.9637ZM2.46359 11.9912C2.4578 17.2357 6.71935 21.5193 11.9536 21.5308C17.2053 21.5366 21.4958 17.282 21.5074 12.0548C21.519 6.79297 17.2632 2.5036 12.0173 2.49781C6.75409 2.48623 2.46938 6.74667 2.46359 11.9912Z"
                      fill="#329993"
                    />
                    <path
                      d="M11.833 6.41064C12.5394 6.4338 13.2169 6.54957 13.8306 6.92004C14.9018 7.56837 15.2666 8.74346 14.7455 9.88383C14.4965 10.428 14.1201 10.8679 13.6801 11.2615C13.5585 11.3715 13.4311 11.4815 13.2979 11.5799C12.7537 11.9793 12.4989 12.5119 12.5221 13.1833C12.5336 13.4728 12.5047 13.7506 12.192 13.8722C11.8157 14.0227 11.4335 14.0574 11.0687 13.8259C10.9124 13.7275 10.8603 13.5538 10.8371 13.3744C10.704 12.2977 10.9935 11.3773 11.862 10.6769C12.111 10.4801 12.3599 10.2832 12.5973 10.0691C12.8174 9.87225 12.9795 9.6407 13.049 9.34548C13.159 8.87081 12.9621 8.45403 12.5105 8.2688C11.9199 8.03146 10.9471 8.01988 10.4608 8.83029C10.1944 9.28181 9.86438 9.35706 9.43012 9.16603C9.04218 8.99238 8.8569 8.61033 8.96691 8.15881C9.14641 7.41787 9.67331 6.98951 10.3334 6.70008C10.8198 6.49169 11.3293 6.42222 11.833 6.41064Z"
                      fill="#329993"
                    />
                    <path
                      d="M11.6468 14.9431C12.2779 14.9431 12.799 15.4467 12.8106 16.0661C12.8222 16.6913 12.2837 17.2296 11.6526 17.2238C11.0272 17.2238 10.5177 16.7029 10.5235 16.0719C10.5293 15.4409 11.0214 14.9431 11.6468 14.9431Z"
                      fill="#329993"
                    />
                  </svg>
                </button>
              </div>
              <InputGroup
                className=""
                style={{
                  backgroundColor: "#FFF",
                  borderRadius: "13.511px",
                  height: "fit-content",
                  marginRight: "10px",
                }}
              >
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: "0",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M23.4144 21.7504L19.1272 17.4631C20.013 16.1281 20.5315 14.5295 20.5315 12.8111C20.5315 8.15482 16.7433 4.36664 12.087 4.36664C7.43076 4.36664 3.64258 8.15482 3.64258 12.8111C3.64258 17.4673 7.43076 21.2555 12.087 21.2555C13.8055 21.2555 15.404 20.737 16.7391 19.8512L21.0263 24.1385C21.685 24.798 22.7557 24.798 23.4144 24.1385C24.0739 23.4789 24.0739 22.4099 23.4144 21.7504ZM6.17591 12.8111C6.17591 9.55153 8.82747 6.89997 12.087 6.89997C15.3466 6.89997 17.9981 9.55153 17.9981 12.8111C17.9981 16.0706 15.3466 18.7222 12.087 18.7222C8.82747 18.7222 6.17591 16.0706 6.17591 12.8111Z"
                      fill="#5D5FEF"
                    />
                  </svg>
                </InputGroupText>
                <Input
                  placeholder="Recherche..."
                  style={{
                    font: "normal 400 15.2px/normal Poppins, sans-serif",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791",
                  }}
                  value={recherche}
                  onChange={(e) => setRecherche(e.target.value)}
                  onKeyPress={handleKeyPressForSearch}
                />
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  {recherche.length > 0 && (
                    <button className="btn" onClick={() => clearSearch()}>
                      <span
                        style={{ cursor: "pointer" }}
                        className="detail-bar-menu-icon"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.3276 17.8933C16.9957 18.2251 16.6488 18.3911 16.2867 18.3911C15.9096 18.376 15.5551 18.2025 15.2233 17.8706L11.5124 14.1597L7.86934 17.8028C7.55256 18.1195 7.22069 18.2855 6.87374 18.3006C6.5117 18.3006 6.17229 18.1422 5.8555 17.8254C5.53872 17.5086 5.38787 17.1767 5.40295 16.8298C5.40295 16.4677 5.56135 16.1283 5.87813 15.8116L9.52114 12.1685L5.92339 8.57078C5.59152 8.23891 5.42558 7.89195 5.42558 7.52992C5.42558 7.16788 5.59906 6.81338 5.94601 6.46643C6.27788 6.13456 6.61729 5.97617 6.96425 5.99125C7.32628 5.99125 7.67324 6.15719 8.00511 6.48906L11.6029 10.0868L15.2459 6.4438C15.5777 6.11193 15.9172 5.95354 16.2641 5.96862C16.6111 5.95354 16.9429 6.10439 17.2597 6.42117C17.5765 6.73796 17.7349 7.07737 17.7349 7.43941C17.7349 7.77127 17.569 8.10314 17.2371 8.43501L13.5941 12.078L17.305 15.7889C17.6368 16.1208 17.8103 16.4753 17.8254 16.8524C17.8405 17.1994 17.6746 17.5463 17.3276 17.8933Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </button>
                  )}
                  <button
                    className="btn"
                    style={{ padding: "0", border: "none" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                    >
                      <rect
                        x="5"
                        width="14"
                        height="3"
                        rx="1.5"
                        fill="#5D5FEF"
                      />
                      <circle cx="1.5" cy="1.5" r="1.5" fill="#5D5FEF" />
                      <rect
                        x="9"
                        y="14"
                        width="10"
                        height="3"
                        rx="1.5"
                        fill="#5D5FEF"
                      />
                      <circle cx="5.5" cy="15.5" r="1.5" fill="#5D5FEF" />
                      <rect
                        y="7"
                        width="14"
                        height="3"
                        rx="1.5"
                        fill="#5D5FEF"
                      />
                      <circle cx="17.5" cy="8.5" r="1.5" fill="#5D5FEF" />
                    </svg>
                  </button>
                </InputGroupText>
              </InputGroup>
              <button
                type="button"
                style={{
                  border: "none",
                  padding: "8px 19px 8px 19px",
                  borderRadius: "14px",
                  background: "#FFA412",
                  boxShadow:
                    "0px 16.20517px 37.81205px 0px rgba(255, 164, 18, 0.20)",
                  color: "#FFFAF1",
                  font: "500 15px/24px Poppins, sans-serif",
                  gap: "5px",
                }}
                className="d-flex align-items-center"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.4 14.104C6.98933 14.104 6.672 13.992 6.448 13.768C6.224 13.5253 6.112 13.1987 6.112 12.788V8.196H1.604C1.212 8.196 0.904 8.09333 0.68 7.888C0.456 7.664 0.344 7.356 0.344 6.964C0.344 6.572 0.456 6.27333 0.68 6.068C0.904 5.844 1.212 5.732 1.604 5.732H6.112V1.28C6.112 0.869333 6.224 0.551999 6.448 0.327999C6.672 0.103999 6.99867 -0.00800037 7.428 -0.00800037C7.83867 -0.00800037 8.14667 0.103999 8.352 0.327999C8.576 0.551999 8.688 0.869333 8.688 1.28V5.732H13.196C13.6067 5.732 13.9147 5.844 14.12 6.068C14.344 6.27333 14.456 6.572 14.456 6.964C14.456 7.356 14.344 7.664 14.12 7.888C13.9147 8.09333 13.6067 8.196 13.196 8.196H8.688V12.788C8.688 13.1987 8.576 13.5253 8.352 13.768C8.14667 13.992 7.82933 14.104 7.4 14.104Z"
                    fill="white"
                  />
                </svg>
                <span style={{ whiteSpace: "nowrap" }}>
                  Ajouter au Dashboard
                </span>
              </button> */}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}



export default Breadcrumbs;
