import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Toast,
  ToastHeader,
  ToastBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Spinner,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  CardTitle,
  InputGroup,
  Input,
  InputGroupText,
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
// Import Flatepicker
import Flatpickr from 'react-flatpickr';
import { Client, Account, Databases } from 'appwrite';

// Import Images
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../assets/images/users/avatar-4.jpg';
import avatar5 from '../../assets/images/users/avatar-5.jpg';
import getFrenchTag from '../../helpers/local_text_helper';
import { forEach } from 'lodash';

const ListTables = () => {
  const [toastMessage, setToastMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [company_infos, setCompanyInfos] = useState(undefined);
  const [site_infos_more, setSiteInfosMore] = useState('');
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list_edit, setmodal_list_edit] = useState(false);
  const [modal_topics, setModal_topics] = useState(false);
  // let setMyNewList = [];
  const [topicList, setTopicList] = useState([]);
  const [ressourcesList, setRessourcesList] = useState([]);
  const [topics_to_show, setTopicsToShow] = useState([]);
  // const [itemStates, setItemStates] = useState(Array(myList.length).fill({ spanVisible: false, buttonDisabled: false, element_loading: false }));
  const [itemStates, setItemStates] = useState([]);

  //
  const [recherche, setRecherche] = useState('');

  const searchTopics = (textToResearch) => {
    const textToLowerCase = textToResearch.toLowerCase();
    // Filtrer le tableau pour trouver les éléments qui contiennent le texte de recherche
    const results = topicList.filter(
      (item) => item.information.toLowerCase().includes(textToLowerCase) || item.statusText.toLowerCase().includes(textToLowerCase)
    );
    console.log('results are: ', results);
    return results;
  };

  const handleBunttonClickForSearch = (research) => {
    onRessourceListChanged(company_infos.contact_reasons);
    const updatedRessourcesList = [];
    // if (research.length > 0) {
    const filteredTopics = searchTopics(research);
    ressourcesList.forEach((ressource) => {
      filteredTopics.forEach((topic) => {
        if (topic.statusText === ressource && !updatedRessourcesList.includes(ressource)) {
          updatedRessourcesList.push(ressource);
        }
      });
    });
    //}
    onRessourceListChanged(updatedRessourcesList);
  };

  const handleBunttonClickForSearchOver = (e) => {
    console.log('Here is handleBunttonClickForSearchOver');
    onRessourceListChanged(company_infos.contact_reasons);
    setRecherche('');
  };

  // if (loading) {
  //     console.log("toto is: already loading", loading);
  // } else {

  // };

  function tog_list() {
    setmodal_list(!modal_list);
  }

  const tags = [
    'product_info',
    'product_availability',
    'product_advice',
    'product_problems',
    'product_price',
    'promotions',
    'order_process',
    'order_info',
    'payments',
    'refunds',
    'disputes',
    'billing',
    'delivery',
    'returns',
    'customer_account',
    'password',
    'security',
    'privacy',
    'customer_data',
    'after_sales',
    'technical_problems',
    'website_feedback',
    'special_programs',
    'affiliation',
    'customer_loyalty',
    'feedback',
    'complaints',
    'company_information',
    'ethics_social_responsibility',
    'subscription_management',
    'store_services',
    'influencers',
    'content_creators',
    'spam',
    'other',
    'Treated',
    'Untreated',
  ];

  const StatusList = tags.map((tag, index) => {
    return {
      id: index + 1,
      statusName: tag,
    };
  });

  const [modalInfo, setModalInfo] = useState({
    id: '', // Initialisez ces valeurs à vide
    statusText: '',
    information: '',
    date: '',
  });

  // const [myList, setMyList] = useState([
  //     {
  //         id: 1,
  //         statusText: "product_info",
  //         information: "Information part 1. Lorem ipsum dolor sit amet",
  //         date: Date.parse("06 Apr, 2021"),
  //     },
  //     {
  //         id: 2,
  //         statusText: "delivery",
  //         information: "Information part 2",
  //         date: Date.parse("08 Apr, 2021"),
  //     },
  //     {
  //         id: 3,
  //         statusText: "customer_data",
  //         information: "Lorem Ipsum dolor sit amet, consectetur adipiscing elit",
  //         date: Date.parse("18 Apr, 2021"),
  //     },
  // ]);

  const editElement = (index) => {
    console.log('editElement clicked');

    const updatedItemStates = [...itemStates];
    updatedItemStates[index] = { spanVisible: true, buttonDisabled: false, element_loading: false };
    setItemStates(updatedItemStates);
  };

  const handleModalUpdate = () => {
    // Créez une nouvelle entrée à partir de modalInfo
    const newItem = {
      id: Date.now(),
      statusText: modalInfo.statusText,
      information: modalInfo.information,
      date: Date.now(),
    };

    const updatedList = [...topicList, newItem];
    setItemStates(Array(updatedList.length).fill({ spanVisible: false, buttonDisabled: false, element_loading: false }));
    console.log(updatedList);
    setTopicList(updatedList);
    setModalInfo({
      id: '', // Initialisez ces valeurs à vide
      statusText: '',
      information: '',
      date: '',
    });
    tog_list();
    updateCompanyInfos(updatedList);
    // ...
  };

  const saveElementData = async () => {
    console.log('saveElementData clicked');
    // const updatedItemStates = [...itemStates];
    // updatedItemStates[index] = { spanVisible: false, buttonDisabled: false, element_loading: true };
    await updateCompanyInfos(topicList);

    // const resetItemStates = [...updatedItemStates];
    // resetItemStates[index] = { spanVisible: false, buttonDisabled: false, element_loading: false };
    // setItemStates(resetItemStates);
  };

  const removeData = async (id, statusText) => {
    const updatedList = [...topicList];
    const elements = updatedList.filter((item) => item.id !== id);
    console.log('removeData', elements);
    await updateCompanyInfos(elements, statusText);

    // const the_topics_to_show = getTopics(statusText, elements);
    // onTopicToShowChanged(the_topics_to_show);
    // console.log("the_topics_to_show is:", the_topics_to_show);
  };
  async function updateCompanyInfos(data, statusText) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.updateDocument('default', 'client_infos', localStorage.getItem('companyId'), {
      company_data: data.map((item) => JSON.stringify(item, null, 4)),
    });
    promise.then(
      async function (response) {
        onLoadingChanged(false);

        if (statusText) {
          onDeleteLoadingChanged(false);
          onTopicListChanged(data);
          const the_topics_to_show = getTopics(statusText, data);
          onTopicToShowChanged(the_topics_to_show);
        }

        setToastMessage({ header: 'Succès', body: 'Modifications effectuées' });
        console.log('response message: ', response);
        toggleToast();
        console.log(response); // Success
      },
      function (error) {
        onLoadingChanged(false);

        onDeleteLoadingChanged(false);
        setToastMessage({ header: 'Echec', body: error.message });
        toggleToast();
        console.log(error); // Failure
      }
    );
  }
  const getTopics = (data, updatedList) => {
    if (company_infos && (updatedList ?? topicList)) {
      const topics = (updatedList ?? topicList).filter((item) => item.statusText === data);
      return topics;
    } else {
      return [];
    }
  };

  const getCompanyInfos = async function () {
    onLoadingChanged(true);
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.getDocument('default', 'client_infos', localStorage.getItem('companyId'));

    promise.then(
      function (response) {
        console.log('getCompanyInfos', response);
        onCompanyInfoChanged(response);
        const clientInfos = response;
        console.log('clientInfos.company_data avant le parsing :', clientInfos.company_data);

        const parsedCompanyData = clientInfos.company_data.map((item) => {
          try {
            return JSON.parse(item);
          } catch (error) {
            console.error('Erreur de parsing JSON :', error);
            return null;
          }
        });
        setTopicList(parsedCompanyData);
        setItemStates(Array(parsedCompanyData.length).fill({ spanVisible: false, buttonDisabled: false, element_loading: false }));
        console.log('clientInfos.company_data après le parsing :', parsedCompanyData);

        setSiteInfosMore(clientInfos.more_info ?? '');
        onLoadingChanged(false);
        onRessourceListChanged(clientInfos.contact_reasons);
        // return response;
      },
      function (error) {
        onLoadingChanged(false);
        console.log(error); // Failure
        return null;
      }
    );
  };

  const toggleToast = () => {
    console.log('setToast before: ' + toast);
    setToast(true);
    console.log('setToast after: ' + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  const onRessourceListChanged = (val) => {
    setRessourcesList(val);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const onTopicListChanged = (val) => {
    setTopicList(val);
  };

  const onTopicToShowChanged = (val) => {
    setTopicsToShow(val);
  };

  const topicToShowActions = (item) => {
    const the_topics_to_show = getTopics(item);
    onTopicToShowChanged(the_topics_to_show);
    tog_topics();
  };

  const RemoveTopicActions = (topic) => {
    setDeleteLoading(topic.id);
    removeData(topic.id, topic.statusText);
  };

  function changeColor(tag) {
    switch (tag) {
      case 'product_info':
        return 'primary';
      case 'product_availability':
        return 'primary';
      case 'product_advice':
        return 'primary';
      case 'product_problems':
        return 'danger';
      case 'product_price':
        return 'primary';
      case 'promotions':
        return 'primary';
      case 'order_info':
        return 'danger';
      case 'order_process':
        return 'primary';
      case 'payments':
        return 'danger';
      case 'refunds':
        return 'danger';
      case 'disputes':
        return 'danger';
      case 'billing':
        return 'warning';
      case 'delivery':
        return 'danger';
      case 'returns':
        return 'warning';
      case 'customer_account':
        return 'info';
      case 'password':
        return 'info';
      case 'security':
        return 'info';
      case 'privacy':
        return 'info';
      case 'customer_data':
        return 'info';
      case 'after_sales':
        return 'info';
      case 'technical_problems':
        return 'primary';
      case 'website_feedback':
        return 'info';
      case 'special_programs':
        return 'info';
      case 'affiliation':
        return 'info';
      case 'customer_loyalty':
        return 'info';
      case 'feedback':
        return 'info';
      case 'complaints':
        return 'warning';
      case 'company_information':
        return 'info';
      case 'ethics_social_responsibility':
        return 'info';
      case 'subscription_management':
        return 'info';
      case 'store_services':
        return 'info';
      case 'influencers':
        return 'info';
      case 'content_creators':
        return 'info';
      case 'spam':
        return 'warning';
      case 'other':
        return 'info';
      default:
        return 'warning';
    }
  }

  function convertDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const dateFormated = `${day}/${month}/${year}`;
    return dateFormated;
  }

  useEffect(() => {
    getCompanyInfos();
  }, [setTopicList]);

  //
  const [btnmenu1, setbtnmenu1] = useState(false);
  //
  const [btnprimary1, setBtnprimary1] = useState(false);

  // modal
  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [editItem, setTopicToEdit] = useState({});
  function tog_list_edit() {
    setmodal_list_edit(!modal_list_edit);
  }

  const onEditTopic = (item) => {
    setTopicToEdit(item);
  };

  const [deleteLoading, setDeleteLoading] = useState();

  const onDeleteLoadingChanged = (item) => {
    setDeleteLoading(item);
  };
  // modal topics
  function tog_topics() {
    setModal_topics(!modal_topics);
  }

  const svgs = {
    known_issues: (
      <svg xmlns="http://www.w3.org/2000/svg" width="98" height="62" viewBox="0 0 98 92" fill="none">
        <path
          d="M89.2961 92H9.01266C5.98094 92 3.26319 90.434 1.74287 87.8111C0.253669 85.2415 0.230904 82.2107 1.68027 79.495L41.8224 4.30029C43.2598 1.60758 46.0009 0 49.1544 0C52.3081 0 55.049 1.60758 56.4866 4.30029L96.6285 79.4949C98.0783 82.2103 98.0553 85.2417 96.5659 87.8111C95.0458 90.434 92.3282 92 89.2961 92Z"
          fill="#6B5EFF"
        />
        <path
          d="M96.6304 79.4949L56.4884 4.30029C55.0508 1.60758 52.3099 0 49.1562 0V92H89.2982C92.3301 92 95.0478 90.434 96.5678 87.8111C98.057 85.2417 98.0799 82.2103 96.6304 79.4949Z"
          fill="#542BDA"
        />
        <path
          d="M49.1508 79.6733C44.9666 79.6733 41.5625 76.2694 41.5625 72.0852C41.5625 67.901 44.9666 64.4969 49.1508 64.4969C53.335 64.4969 56.7391 67.901 56.7391 72.0852C56.7391 76.2694 53.335 79.6733 49.1508 79.6733ZM49.151 56.9086C44.9666 56.9086 41.5625 53.5045 41.5625 49.3203V32.9106C41.5625 28.7264 44.9666 25.3223 49.1508 25.3223C53.335 25.3223 56.7391 28.7264 56.7391 32.9106V49.3203C56.7391 53.5045 53.3352 56.9086 49.151 56.9086Z"
          fill="#FFC042"
        />
        <path
          d="M49.1564 25.3223H49.1562V56.9086H49.1566C53.3408 56.9086 56.7447 53.5045 56.7447 49.3203V32.9106C56.7447 28.7266 53.3406 25.3223 49.1564 25.3223ZM49.1564 64.4969H49.1562V79.6733H49.1564C53.3406 79.6733 56.7447 76.2694 56.7447 72.0852C56.7447 67.901 53.3406 64.4969 49.1564 64.4969Z"
          fill="#FFAA00"
        />
      </svg>
    ),
    arrow_fwrd: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
        <path
          d="M11.9716 15.7637L20 8.03942L11.9716 0.315174C11.8991 0.224039 11.807 0.148879 11.7015 0.0947829C11.596 0.0406863 11.4796 0.00891594 11.3601 0.00162216C11.2407 -0.00567163 11.121 0.011682 11.0092 0.0525076C10.8974 0.0933332 10.796 0.156678 10.712 0.238253C10.628 0.319829 10.5633 0.417731 10.5222 0.525331C10.4812 0.632931 10.4647 0.747717 10.4741 0.861919C10.4834 0.97612 10.5182 1.08707 10.5763 1.18726C10.6343 1.28745 10.7141 1.37454 10.8104 1.44263L16.817 7.23981L0.835422 7.23981C0.613855 7.23981 0.401363 7.32405 0.24469 7.47401C0.0880182 7.62396 -2.69305e-07 7.82735 -2.76601e-07 8.03942C-2.83898e-07 8.25149 0.0880181 8.45487 0.24469 8.60483C0.401363 8.75479 0.613855 8.83903 0.835422 8.83903L16.817 8.83903L10.8104 14.6362C10.6542 14.7868 10.5668 14.9906 10.5676 15.2028C10.5684 15.415 10.6572 15.6182 10.8145 15.7677C10.9718 15.9172 11.1848 16.0007 11.4065 16C11.6282 15.9992 11.8405 15.9142 11.9967 15.7637L11.9716 15.7637Z"
          fill="#FF7049"
        />
      </svg>
    ),
    close_icon: (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.37 19.6142C19.0771 19.8983 18.778 20.038 18.4725 20.0334C18.1504 20.0115 17.8473 19.8542 17.5632 19.5613L12.9511 14.8068L8.27403 19.3437C7.98118 19.6278 7.68204 19.7675 7.3766 19.7629C7.07117 19.7582 6.78476 19.6181 6.5174 19.3425C6.25003 19.0668 6.11866 18.7763 6.12331 18.4709C6.12795 18.1654 6.2767 17.8707 6.56955 17.5866L11.2466 13.0497L6.78484 8.45016C6.48405 8.14008 6.33598 7.83232 6.34062 7.52688C6.34527 7.22145 6.50263 6.91834 6.81271 6.61755C7.10556 6.33347 7.39609 6.2021 7.6843 6.22346C7.98973 6.2281 8.29284 6.38546 8.59364 6.69554L13.0554 11.295L17.7324 6.75812C18.0252 6.47404 18.3244 6.33432 18.6298 6.33896C18.9353 6.34361 19.2217 6.48374 19.489 6.75937C19.7564 7.03499 19.8878 7.32552 19.8831 7.63096C19.8785 7.93639 19.7297 8.23115 19.4369 8.51523L14.7598 13.0522L19.372 17.8067C19.6561 18.0996 19.8041 18.4073 19.8162 18.73C19.8288 19.0187 19.68 19.3135 19.37 19.6142Z"
          fill="currentColor"
        />
      </svg>
    ),
    edit_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
          d="M0 15.6448V18.9334C0 19.2363 0.237992 19.4743 0.54089 19.4743H3.8295C3.97013 19.4743 4.11077 19.4202 4.20813 19.312L16.0212 7.50978L11.9645 3.4531L0.162267 15.2553C0.054089 15.3635 0 15.4933 0 15.6448ZM19.1535 4.37308C19.5754 3.95119 19.5754 3.26967 19.1535 2.84777L16.6221 0.316405C16.42 0.113839 16.1456 0 15.8594 0C15.5733 0 15.2989 0.113839 15.0968 0.316405L13.1171 2.29606L17.1738 6.35274L19.1535 4.37308Z"
          fill="#329993"
        />
      </svg>
    ),
    delete_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        <path
          d="M23.4933 10.5732L22.595 24.3845C22.5409 25.2252 22.1809 26.0133 21.5875 26.5897C20.9942 27.1661 20.2118 27.4878 19.3983 27.4899H8.595C7.78152 27.4878 6.99912 27.1661 6.40579 26.5897C5.81246 26.0133 5.45241 25.2252 5.39833 24.3845L4.5 10.5732H23.4933ZM17.6273 6.94824H10.366C10.0426 6.94811 9.72377 6.86843 9.43587 6.71576C9.14796 6.5631 8.89918 6.34181 8.70997 6.07011C8.52077 5.79841 8.39656 5.48406 8.34757 5.15294C8.29858 4.82181 8.3262 4.48336 8.42817 4.16545L8.819 2.95591C8.95372 2.53494 9.21316 2.16868 9.56044 1.90923C9.90771 1.64977 10.3251 1.51033 10.7533 1.51074H17.2377C17.6663 1.51028 18.0842 1.6498 18.4319 1.90947C18.7796 2.16913 19.0394 2.53573 19.1743 2.95712L19.5628 4.16424C19.6653 4.48213 19.6933 4.82068 19.6446 5.15199C19.5959 5.4833 19.4718 5.79787 19.2827 6.06977C19.0935 6.34167 18.8447 6.56312 18.5567 6.71586C18.2687 6.86859 17.9497 6.94824 17.6262 6.94824H17.6273ZM10.7545 3.32324C10.6934 3.32284 10.6337 3.3425 10.584 3.3794C10.5343 3.41631 10.4972 3.46856 10.478 3.52866L10.0883 4.7382C10.0737 4.78378 10.0697 4.83233 10.0768 4.87981C10.0839 4.92729 10.1018 4.97234 10.1291 5.01121C10.1563 5.05008 10.1922 5.08166 10.2336 5.10332C10.275 5.12498 10.3208 5.13609 10.3672 5.13574H17.6285C17.6749 5.13596 17.7207 5.1247 17.7621 5.1029C17.8035 5.08109 17.8392 5.04937 17.8664 5.01037C17.8936 4.97137 17.9113 4.92622 17.9182 4.87867C17.9252 4.83112 17.921 4.78255 17.9062 4.73699L17.5177 3.52987C17.4983 3.46954 17.461 3.4171 17.4111 3.38C17.3613 3.3429 17.3014 3.32304 17.24 3.32324H10.7545Z"
          fill="#F10944"
        />
        <path
          d="M11.6641 20.2396C11.432 20.2396 11.2094 20.1441 11.0453 19.9741C10.8813 19.8042 10.7891 19.5737 10.7891 19.3333V14.5C10.7891 14.2596 10.8813 14.0291 11.0453 13.8592C11.2094 13.6892 11.432 13.5938 11.6641 13.5938C11.8961 13.5938 12.1187 13.6892 12.2828 13.8592C12.4469 14.0291 12.5391 14.2596 12.5391 14.5V19.3333C12.5391 19.5737 12.4469 19.8042 12.2828 19.9741C12.1187 20.1441 11.8961 20.2396 11.6641 20.2396ZM16.3307 20.2396C16.0987 20.2396 15.8761 20.1441 15.712 19.9741C15.5479 19.8042 15.4557 19.5737 15.4557 19.3333V14.5C15.4557 14.2596 15.5479 14.0291 15.712 13.8592C15.8761 13.6892 16.0987 13.5938 16.3307 13.5938C16.5628 13.5938 16.7854 13.6892 16.9494 13.8592C17.1135 14.0291 17.2057 14.2596 17.2057 14.5V19.3333C17.2057 19.5737 17.1135 19.8042 16.9494 19.9741C16.7854 20.1441 16.5628 20.2396 16.3307 20.2396Z"
          fill="#EDEBEA"
        />
        <path
          d="M23.3333 5.13574H4.66667C4.12518 5.13574 3.60588 5.35853 3.22299 5.75509C2.8401 6.15165 2.625 6.6895 2.625 7.25033C2.625 7.81115 2.8401 8.349 3.22299 8.74556C3.60588 9.14212 4.12518 9.36491 4.66667 9.36491H23.3333C23.8748 9.36491 24.3941 9.14212 24.777 8.74556C25.1599 8.349 25.375 7.81115 25.375 7.25033C25.375 6.6895 25.1599 6.15165 24.777 5.75509C24.3941 5.35853 23.8748 5.13574 23.3333 5.13574Z"
          fill="#F10944"
        />
      </svg>
    ),
  };
  return (
    <React.Fragment>
      {/* Topics Modal */}
      <Modal isOpen={modal_topics} toggle={tog_topics} centered className="help-page-modal">
        <ModalBody>
          <Button
            className="close_button"
            style={{ backgroundColor: 'transparent', border: 'none', padding: '0px', float: 'right' }}
            onClick={() => {
              tog_topics();
            }}>
            {svgs.close_icon}
          </Button>
          <h1 className="modal_title" style={{ marginTop: '8%', marginBottom: '6%' }}>
            Topics
          </h1>
          <Row className="gap-4 mb-5" style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            {topics_to_show.length > 0 ? (
              topics_to_show.map((topic, index) => (
                <Col key={index} xl={12}>
                  <div className="help-page-modal-button d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column col-10">
                      <h3>{getFrenchTag(topic.statusText)}</h3>
                      <p
                        className="m-0"
                        style={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          '-webkit-box-orient': 'vertical',
                          '-webkit-line-clamp': '2',
                        }}>
                        {topic.information}
                      </p>
                    </div>
                    <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
                      <Button
                        className="me-xxl-2 me-xl-1"
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => {
                          onEditTopic(topic);
                          tog_list_edit();
                        }}>
                        {svgs.edit_icon}
                      </Button>
                      {deleteLoading === topic.id ? (
                        <Spinner animation="border" className="" />
                      ) : (
                        <Button style={{ backgroundColor: 'transparent', border: 'none', padding: '0' }} onClick={() => RemoveTopicActions(topic)}>
                          {svgs.delete_icon}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="font-primary text-center" style={{ fontSize: '18px' }}>
                Vide
              </div>
            )}
          </Row>
        </ModalBody>
        <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '1005' }}>
          <Toast isOpen={toast}>
            <ToastHeader toggle={toggleToast}>{toastMessage.header}</ToastHeader>
            <ToastBody color="primary">{toastMessage.body}</ToastBody>
          </Toast>
        </div>
      </Modal>

      <Row>
        <Col xs="12" xxl={9} xl={11} className="mx-auto">
          <div className="ps-0 pe-0 page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex flex-column" style={{ width: 'fit-content' }}>
              <h4 className="page-title">Centre de ressources</h4>
              <p
                className="mb-0 mt-2 simple-text font-primary"
                style={{
                  color: '#666969',
                  fontStyle: 'normal',
                  fontWeight: '400',
                }}>
                Ajoutez ici, toutes les informations utiles et essentielles au bon fonctionnement de vos agents IA
              </p>
            </div>
            <div className="d-flex align-items-center" style={{ height: 'fit-content' }}>
              <button className="action-btn action-btn-xl" onClick={() => tog_list()} style={{ border: 'none', marginRight: '30px' }}>
                <i className="ri-add-line align-bottom me-1"></i> Ajouter
              </button>
            </div>
          </div>
        </Col>
      </Row>

      {/* Add Modal */}
      <Modal isOpen={modal_list} toggle={tog_list} centered>
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={tog_list}>
          Ajouter une ressource
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div>
              <label htmlFor="status-field" className="form-label">
                Tags
              </label>
              <select
                className={`form-control ${modalInfo.statusText === '' && 'border border-danger'}`}
                data-trigger
                name="status-field"
                id="status-field"
                value={modalInfo.statusText}
                onChange={(e) => setModalInfo({ ...modalInfo, statusText: e.target.value, ressource: e.target.value })}>
                <option value="">Aucun</option>
                {StatusList.map((item, index) => (
                  <option key={index} value={item.statusName}>
                    {getFrenchTag(item.statusName)}
                  </option>
                ))}
              </select>
              {modalInfo.statusText === '' && <span className="font-primary text-danger">Veuillez sélectionner un tag</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Informations
              </label>
              <textarea
                id="customername-field"
                style={{
                  border: 'none',
                  position: 'relative', // Rendre le textarea positionnel
                }}
                required
                className={`text-area col-12 form-control ${modalInfo.information === '' && 'border border-danger'}`}
                placeholder="Entrer les informations"
                rows="3"
                cols="45"
                value={modalInfo.information}
                onChange={(e) => setModalInfo({ ...modalInfo, information: e.target.value })}></textarea>
              {modalInfo.information === '' && <span className="font-primary text-danger">Veuillez entrer les informations</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={tog_list}>
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-success"
                id="edit-btn"
                onClick={() => {
                  if (modalInfo.statusText !== '' && modalInfo.information !== '') {
                    handleModalUpdate();
                  }
                }}>
                Ajouter
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '1005' }}>
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>
            {/* <img src={logo} alt="" className="me-2" height="18" /> */}
            {toastMessage.header}
          </ToastHeader>
          <ToastBody color="primary">{toastMessage.body}</ToastBody>
        </Toast>
      </div>

      {/* Edit Modal */}
      <Modal isOpen={modal_list_edit} toggle={tog_list_edit} centered>
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={tog_list_edit}>
          Modifier la ressource
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="customername-field" className="form-label">
                Informations
              </label>
              <textarea
                id="customername-field"
                style={{
                  border: 'none',
                  position: 'relative', // Rendre le textarea positionnel
                  minHeight: '300px',
                }}
                required
                className="text-area col-12 form-control"
                rows="3"
                cols="45"
                value={editItem.information}
                onChange={(e) => {
                  // const updatedList = [...topicList];
                  // const element = updatedList.find(item => item.id === editItem.id);
                  // element.information = e.target.value;
                  // const finalList = [...topicList, element];
                  // setTopicList(finalList);

                  const updatedList = [...topicList];
                  const foundIndex = updatedList.findIndex((item) => item.id === editItem.id);
                  updatedList[foundIndex].information = e.target.value;
                  setTopicList(updatedList);
                }}></textarea>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={tog_list_edit}>
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-success"
                id="edit-btn"
                onClick={() => {
                  saveElementData();
                  tog_list_edit();
                  toggleToast();
                }}>
                Modifier
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      <div className="help-page">
        <div className="mx-auto col-xxl-9 col-xl-11">
          <Card
            style={{
              borderRadius: '20px',
              background: '#563BFF',
              boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.05)',
              paddingTop: '40px',
            }}>
            <CardTitle
              className="mb-0 font-tertiary"
              style={{
                color: '#FFF',
                textAlign: 'center',
                fontSize: '38.869px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '112.5%',
              }}>
              Recherche rapide
            </CardTitle>
            <CardBody className="p-0">
              <InputGroup
                className="mx-auto"
                style={{
                  backgroundColor: '#F9FAFB',
                  borderRadius: '13.511px',
                  marginTop: '40px',
                  marginBottom: '69px',
                  maxWidth: '625px',
                }}>
                <Input
                  className="font-primary"
                  placeholder="Recherche..."
                  style={{
                    font: 'normal 400 15.2px/normal',
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: '#737791',
                  }}
                  value={recherche}
                  onChange={(e) => {
                    setRecherche(e.target.value);
                    if (e.target.value.length > 2) {
                      handleBunttonClickForSearch(e.target.value);
                    } else if (e.target.value === '') {
                      onRessourceListChanged(company_infos.contact_reasons);
                    }
                  }}
                />
                <InputGroupText
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                  }}>
                  {recherche.length > 0 ? (
                    // Close Button
                    <button className="btn" style={{ padding: '0', border: 'none', color: '#FF7049' }} onClick={handleBunttonClickForSearchOver}>
                      {svgs.close_icon}
                    </button>
                  ) : (
                    // Research Button
                    <button className="btn" style={{ padding: '0', border: 'none' }} onClick={handleBunttonClickForSearch}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path
                          d="M27.04 25.6258L19.4868 18.0726C21.3019 15.8936 22.207 13.0987 22.0139 10.2693C21.8207 7.4399 20.5442 4.7939 18.4499 2.88173C16.3556 0.969553 13.6046 -0.0615751 10.7694 0.00284662C7.93418 0.0672683 5.23293 1.22228 3.22761 3.22761C1.22228 5.23293 0.0672683 7.93418 0.00284662 10.7694C-0.0615751 13.6046 0.969553 16.3556 2.88173 18.4499C4.7939 20.5442 7.4399 21.8207 10.2693 22.0139C13.0987 22.207 15.8936 21.3019 18.0726 19.4868L25.6258 27.04L27.04 25.6258ZM2.03608 11.0375C2.03608 9.25718 2.564 7.51685 3.55309 6.03658C4.54218 4.5563 5.94801 3.40257 7.5928 2.72127C9.23759 2.03998 11.0475 1.86172 12.7936 2.20904C14.5397 2.55636 16.1436 3.41366 17.4025 4.67253C18.6613 5.9314 19.5186 7.5353 19.8659 9.2814C20.2133 11.0275 20.035 12.8374 19.3537 14.4822C18.6724 16.127 17.5187 17.5328 16.0384 18.5219C14.5581 19.511 12.8178 20.0389 11.0375 20.0389C8.65098 20.0363 6.36297 19.087 4.67546 17.3995C2.98794 15.712 2.03873 13.424 2.03608 11.0375Z"
                          fill="#FF7049"
                        />
                      </svg>
                    </button>
                  )}
                </InputGroupText>
              </InputGroup>
            </CardBody>
          </Card>
          <Col style={{ marginTop: '60px', marginBottom: '60px' }}>
            {ressourcesList && ressourcesList !== undefined ? (
              <Row className="row-gap-4">
                {ressourcesList.map((item, index) => (
                  <Col xl={4} key={index}>
                    <Card className="mb-0 help__section_card" onClick={() => topicToShowActions(item)}>
                      <CardBody className="d-flex align-items-center">
                        <div className="d-flex flex-column" style={{ height: 'fit-content' }}>
                          <div className="help__section_title mb-2">{getFrenchTag(item)}</div>
                          <p className="help__section_articles mb-0">
                            {getTopics(item).length > 1 ? `${getTopics(item).length} topics` : `${getTopics(item).length} topic`}{' '}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
                <div className="d-flex justify-content-between mt-5 mb-5">
                  <div className="">
                    <Link className="page-item pagination-next" to="#">
                      Next Page{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                        <path
                          d="M11.9716 15.7637L20 8.03942L11.9716 0.315174C11.8991 0.224039 11.807 0.148879 11.7015 0.0947829C11.596 0.0406863 11.4796 0.00891594 11.3601 0.00162216C11.2407 -0.00567163 11.121 0.011682 11.0092 0.0525076C10.8974 0.0933332 10.796 0.156678 10.712 0.238253C10.628 0.319829 10.5633 0.417731 10.5222 0.525331C10.4812 0.632931 10.4647 0.747717 10.4741 0.861919C10.4834 0.97612 10.5182 1.08707 10.5763 1.18726C10.6343 1.28745 10.7141 1.37454 10.8104 1.44263L16.817 7.23981L0.835422 7.23981C0.613855 7.23981 0.401363 7.32405 0.24469 7.47401C0.0880182 7.62396 -2.69305e-07 7.82735 -2.76601e-07 8.03942C-2.83898e-07 8.25149 0.0880181 8.45487 0.24469 8.60483C0.401363 8.75479 0.613855 8.83903 0.835422 8.83903L16.817 8.83903L10.8104 14.6362C10.6542 14.7868 10.5668 14.9906 10.5676 15.2028C10.5684 15.415 10.6572 15.6182 10.8145 15.7677C10.9718 15.9172 11.1848 16.0007 11.4065 16C11.6282 15.9992 11.8405 15.9142 11.9967 15.7637L11.9716 15.7637Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="pagination-style d-flex align-items-center justify-content-end">
                    Page<span className="currentPage">1</span> sur <span style={{ marginLeft: '15px' }}>1</span>
                  </div>
                </div>
              </Row>
            ) : (
              <div></div>
            )}
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListTables;
