import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'; // Si vous utilisez React Router
 
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    TabPane,
    Progress,
    NavLink,
    NavItem,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const WordPressConfiguration = () => {
   
    
         useEffect(() => {
           
        }, []);
    return ( 
                <Row>
                    WordPressConfiguration
                </Row> 
    );
};

export default WordPressConfiguration;
