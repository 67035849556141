import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import articleImage from "../../assets/images/article-image.png"
import { Link } from "react-router-dom";
const Details = () => {
  const svgs = {
    back_arrow:
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
        <path d="M7.04361 12.9989C6.79327 13.0126 6.59399 12.8973 6.41337 12.7431C5.61183 12.0586 4.81139 11.3729 4.01205 10.686C2.81157 9.65751 1.61073 8.62942 0.409523 7.60169C-0.122457 7.14877 -0.143319 6.54707 0.387013 6.09195C2.22616 4.51339 4.06713 2.93795 5.90993 1.36562C6.10757 1.19653 6.30082 1.0225 6.5034 0.859446C6.92174 0.523459 7.47952 0.575613 7.80288 0.974186C8.11855 1.36398 8.04773 1.90748 7.64312 2.255C5.89987 3.74425 4.1568 5.2335 2.41391 6.72274C2.29533 6.82321 2.32992 6.86878 2.42764 6.95223C4.13429 8.41036 5.84003 9.86978 7.54485 11.3305C7.84845 11.5896 8.02742 11.8916 7.93683 12.3C7.84076 12.731 7.48775 12.9989 7.04361 12.9989Z" fill="currentColor" />
      </svg>
  }
  return (
    <React.Fragment>
      <div className="page-content details-page px-0" style={{padding:"0"}}>
        <div className="mt-2 mx-auto" style={{width:"80%"}}>
          <Breadcrumbs breadcrumbItem="In vel nibh tellus. Fusce tincidunt elit nec tortor pharetra?" />
          <img className="article-picture mb-5" width="100%" src={articleImage}/>
          <div className="">
            <span className="article-badge">Login Issue</span>
            <div className="details-page-content mt-4 mb-3" style={{width:"100%"}}>
              <p>
              Lorem ipsum dolor sit amet. Et blanditiis modi aut neque odio aut ducimus voluptatem sed voluptatem nesciunt et commodi aperiam et nihil saepe. Sit officiis facilis sit possimus praesentium id doloribus cumque qui ratione adipisci et sint exercitationem.<br />
              Non autem rerum non Quis voluptas et dolorem internos cum omnis itaque. Eum porro autem ut explicabo voluptates ab tenetur quibusdam 33 itaque ipsum in exercitationem repudiandae. Et voluptatibus nisi ut molestiae quia quo omnis consequuntur. 
              Et rerum sint aut quos omnis hic dicta iste et eveniet sequi cum repudiandae nemo id voluptatem repellendus ut reprehenderit officia.
              </p>
              <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
              It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently 
              with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
          </div>
          <div className="mt-4" style={{marginBottom:"79px"}}>
            <Link to="/help" className="details-link d-flex align-items-center gap-2">{svgs.back_arrow} Go Back</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Details;
