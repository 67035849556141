import React, {
  useState,
  useEffect,
  componentDidMount,
  useCallback,
} from "react";
import LangageRadialChart from "./LangageRadialChart";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Client, Account, Databases, Query } from "appwrite";
import { SocialSourceData } from "../../CommonData/Data/index";
import LANGAGE_CODE_NAME from "../Utility/LangageCodeName";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import getFrenchTag from "../../helpers/local_text_helper";

const LangageStateChart = (props) => {
  let options = null;
  const [loading, setLoading] = useState(true);
  const [clientLanguageList, setclientLanguageList] = useState();
  const [labels, setlabelsList] = useState();
  const [series, setseriesList] = useState();
  const [dataGraph, setDataGraph] = useState({
    series: ["fr", "en"],
    options: {
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      labels: ["Français", "Anglais"],
      colors: ["#563BFF", "#FF7049"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  });
  const fakeDatagraph = {
    series: [69, 22, 6, 3],
    options: {
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      labels: ["Français", "Espagnol", "Anglais", "Autres"],
      colors: ["#563BFF", "#FF7049", "rgb(241, 9, 68)", "rgb(255, 164, 18)"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };
  const theMessages = props.messages;
  const [total, settotal] = useState();

  const getFrenchTransation = (tag) => {
    switch (tag) {
      case "fr":
        return "Français";
      case "en":
        return "Anglais";
      default:
        return "-";
    }
  };

  const getLanguageColor = (tag) => {
    switch (tag) {
      case "fr":
        return "#563BFF";
      case "en":
        return "#FF7049";
      default:
        return "#FF7049";
    }
  };

  async function getMessageData(datas) {
    let the_data = datas;
    if (!the_data || the_data.length === 0) {
      the_data = [{ client_language: "-", }]
    }

    const languageCounts = {};
    the_data.forEach((message) => {
      const clientLanguage = message.client_language;
      if (clientLanguage) {
        if (languageCounts.hasOwnProperty(clientLanguage)) {
          languageCounts[clientLanguage]++;
        } else {
          languageCounts[clientLanguage] = 1;
        }
      }
    });
    setlabelsList(Object.keys(languageCounts));
    const languageCountsValues = Object.values(languageCounts);
    const totalSum = languageCountsValues.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    settotal(total);
    const percentages = languageCountsValues.map((value) =>
      ((value / totalSum) * 100).toFixed(2)
    );
    console.log("languageCounts is: ", Object.values(languageCounts));
    console.log("languages are: ", Object.keys(languageCounts));
    console.log("languages map is: ", languageCounts);

    console.log("percentages is: ", percentages);
    console.log("percentages is: ", total);
    setseriesList(percentages);
    setLoading(false);

    const dountchartData = {
      series: Object.values(languageCounts),
      options: {
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        labels: Object.keys(languageCounts).map(language => getFrenchTransation(language)),
        colors: Object.keys(languageCounts).map(language => getLanguageColor(language)),
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    }
    setDataGraph(dountchartData);
  }
  function convertirCodePaysEnNomComplet(codePays) {
    const nomComplet = LANGAGE_CODE_NAME[codePays];
    return nomComplet || "Langue inconnu";
  }
  const seriesVide = ["100.00"];
  useEffect(() => {
    getMessageData(theMessages);
  }, [theMessages]);

  // const data = {
  //   labels: ["Anglais", "Français"],
  //   datasets: [
  //     {
  //       data: [21, 16],
  //       backgroundColor: ["#FF7049", "#563BFF"],
  //       borderColor: ["#FF7049", "#563BFF"],
  //     },
  //   ],
  // };

  // const option = {

  //   aspectRatio: 1,
  //   // layout: {
  //   //   padding: {
  //   //       left: 0,
  //   //       right: 0,
  //   //       top: 0,
  //   //       bottom: 0,
  //   //   }
  //   // },
  //   legend: {
  //     position: "bottom",
  //   },

  //   responsive: true,
  //   cutoutPercentage: 70,
  //   animation: {
  //     animateScale: true,
  //     animateRotate: true,
  //   },
  // };


  return (
    <React.Fragment>
      <Row>
      {/* <ReactApexChart
          options={dataGraph.options}
          series={dataGraph.series}
          type="donut"
          height="390"
          className="apex-charts"
        /> */}
        <ReactApexChart
          options={fakeDatagraph.options}
          series={fakeDatagraph.series}
          type="donut"
          height="390"
          className="apex-charts"
        />

        

      </Row>
      {/* <div className="d-flex justify-content-center">
        <div className="customLegend" style={{ gap: "24px" }}>
          {dataGraph.options.labels.map((label, index) => (
            <div className="d-flex">
              <div className="legend" style={{ backgroundColor: dataGraph.options.colors[index] }}></div>
              <span>{label}</span>
            </div>
          )
          )}
        </div>
      </div> */}
      <div className="d-flex justify-content-center">
        <div className="customLegend" style={{ gap: "24px" }}>
          {fakeDatagraph.options.labels.map((label, index) => (
            <div className="d-flex">
              <div className="legend" style={{ backgroundColor: fakeDatagraph.options.colors[index] }}></div>
              <span style={{fontSize:"12px"}}>{label}</span>
            </div>
          )
          )}
        </div>
      </div>


    </React.Fragment>
  );
};

export default LangageStateChart;
