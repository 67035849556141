import React from "react";
import { Line } from "react-chartjs-2";
import { Row } from "reactstrap";

const ProgressionBar = () => {
  const data = {
    labels: ["2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
    datasets: [
      {
        label: "",
        lineTension: 0.2,
        borderColor: ["#FF7049"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#FF7049",
        data: [20, 80, 40, 110, 60, 140, 80, 110],
      },
      {
        label: "",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#563BFF"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#563BFF",
        data: [80, 140, 100, 170, 120, 200, 140, 170],
      },
    ],
  };
  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes : [
        {
          gridLines: {
            display: false
          },
        },
      ],
      yAxes: [
        {
          
          ticks: {
            // max: 200,
            // min: 0,
            stepSize: 50,
            // zeroLineColor: "#7b919e",
            // borderDash: [3, 3],
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <div>
        <Line
          style={{
            height: "100px",
          }}
          width={537}
          height={268}
          data={data}
          options={option}
        />
      </div>
    </React.Fragment>
  );
};

export default ProgressionBar;
