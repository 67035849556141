import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col, Row } from "reactstrap";


const TreatedMail = (props) => {
  let DashedLineData = null;
  const [mailReceive, setMailReceive] = useState();
  const [loading, setLoading] = useState(true);
  const dataMap = new Map();
  const theMessages = props.messages;
  const [formattedDataList, setformattedDataList] =  useState({
    series: [
      {
        name: "Nombre de messages",
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#82c2bf"],
      xaxis: {
        type: "datetime",
        categories: [],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  });

   // Fonction pour agréger le nombre de messages par jour
   const aggregateMessagesByDay = (data) => {
    const aggregatedData = {};
    data.forEach((message) => {
      const messageDate = new Date(message.message_date);
      const dayKey = messageDate.getTime();

      if (aggregatedData[dayKey]) {
        aggregatedData[dayKey].count += 1;
      } else {
        aggregatedData[dayKey] = {
          date: dayKey,
          count: 1,
        };
      }
    });

    return Object.values(aggregatedData);
  };

  
  const handleDateChange = (theData) => {
    console.log("last messages", theData[theData.length - 1]);
  var data = theData;
       // Agréger le nombre de messages par jour
       const aggregatedData = aggregateMessagesByDay(data);

       // Mettre à jour le state avec les données agrégées
       setformattedDataList((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Nombre de messages",
            data: aggregatedData.map((dataPoint) => ({
              x: dataPoint.date,
              y: dataPoint.count,
            })),
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: aggregatedData.map((dataPoint) => dataPoint.date),
          },
        },
      }));
     // setformattedDataList(finalChartData);
    

    setLoading(false);
  }
  useEffect(() => {
    handleDateChange(theMessages);
  }, [theMessages]);
  return (
    <React.Fragment>
      <ReactApexChart
        options={formattedDataList.options}
        series={formattedDataList.series}
        type="area"
        height="235"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

export default TreatedMail;

