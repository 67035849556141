import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col, Row } from "reactstrap";


const AnalyseMessageSavHeure = (props) => {

    let barchartData = null; 
    const [messageCountByDayData, setMessageCountByDayData] = useState(undefined);
    const theMessages = props.messages;
    const dataMap = new Map();

   
    const handleDateChange = (theData) => {
        var data = theData;
        console.log("AnalyseMessageSavJour", data);

         const messages = data;

         const hoursOfDay = [
            0,4,8,12,16,20,24
          ];

          const getIntervalleHoraire= (heure) => {
            switch (true) {
                case heure >= 0 && heure < 4:
                    return "00h-04h";
                case heure >= 4 && heure < 8:
                    return "04h-8h";
                case heure >= 8 && heure < 12:
                    return "8h-12h";
                case heure >= 12 && heure < 16:
                    return "12h-16h";
                case heure >= 16 && heure < 20:
                    return "16h-20h";
                case heure >= 20 && heure <= 24:
                    return "20h-00h";
                default:
                    return "Heure non valide";
            }
        }
        

        dataMap.clear();
        hoursOfDay.forEach((hour, index) => {
            dataMap.set(getIntervalleHoraire(hour), {
                day: getIntervalleHoraire(hour),
                val: 0,
                width: (0 * 100/(messages.length === 0 ? 1 : messages.length)),
                backgroundColor: "#FFA412",
              });
          });

  

         messages.forEach((message) => {
             const messageDateEpoch = message.message_date; 
             const messageDate = new Date(messageDateEpoch); 
             const heureDeLaSemaineIndex = messageDate.getHours(); 
             const hourRange = 
            console.log(`hour of message is ${heureDeLaSemaineIndex} with name `+ getIntervalleHoraire(heureDeLaSemaineIndex));

            if (dataMap.has(getIntervalleHoraire(heureDeLaSemaineIndex))) {
                const theVal = dataMap.get(getIntervalleHoraire(heureDeLaSemaineIndex)).val+1;
                dataMap.set(getIntervalleHoraire(heureDeLaSemaineIndex), {
                    day: getIntervalleHoraire(heureDeLaSemaineIndex),
                    val: theVal,
                    width: ((theVal * 100)/messages.length),
                    backgroundColor: "#FFA412",
                  });
              } else {
                const theVal = 1;

                dataMap.set(getIntervalleHoraire(heureDeLaSemaineIndex), {
                    day: getIntervalleHoraire(heureDeLaSemaineIndex),
                    val: theVal,
                    width: ((theVal * 100)/messages.length),
                    backgroundColor: "#FFA412",
                  });
            
              }

   

        });


        console.log("total is",messages.length); // Affichez le tableau résultant
        setMessageCountByDayData(dataMap);
    } 
    
      const getItemData = (item_key, the_statsData) => {

        return {
            day: the_statsData.get(item_key).day,
            val: the_statsData.get(item_key).val,
            width: the_statsData.get(item_key).width.toFixed(0),
            backgroundColor: "#563BFF",
          };
      };


    //
    const newData = [
        {
          text: "L",
          width: "10%",
          backgroundColor: "#FFA412",
        },
        {
          text: "M",
          width: "1%",
          backgroundColor: "#FFA412",
        },
        {
          text: "M",
          width: "1%",
          backgroundColor: "#FFA412",
        },
        {
          text: "J",
          width: "1%",
          backgroundColor: "#FFA412",
        },
        {
          text: "V",
          width: "1%",
          backgroundColor: "#FFA412",
        },
        {
            text: "S",
            width: "1%",
            backgroundColor: "#FFA412",
        },
        {
            text: "D",
            width: "1%",
            backgroundColor: "#FFA412",
        },
      ];

      useEffect(() => {
        handleDateChange(theMessages);
    }, [theMessages]);
    
    return (
        <React.Fragment>
        {/* <ReactApexChart
            options={barchartData.options}
            series={barchartData.series}
            type="bar"
            height="350"
            className="apex-charts"
        /> */}
        {messageCountByDayData && Array.from(messageCountByDayData.keys().length > 0) ? 
          Array.from(messageCountByDayData.keys()).map((item, index) => (
            <>
                <div key={index} className="d-flex align-items-center" style={{ borderBottom: index !== Array.from(messageCountByDayData.keys()).length - 1 ? "2px dashed #E6E7E9" : "none" }}>
                    <div className="col-xxl-2 col-xl-3 customGraph_yAxis__value simple-text font-primary">{getItemData(item, messageCountByDayData).day}</div>
                    <div className="customGraph_bar__container col-xxl-9 col-xl-7">
                        <div
                            key={index}
                            style={{
                                backgroundColor: getItemData(item, messageCountByDayData).backgroundColor,
                                width: getItemData(item, messageCountByDayData).width + "%",
                                whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                            }}
                            className="customGraph_bar"
                        ></div>
                    </div>
                    <div className="col-xxl-1 col-xl-2"
                        style={{
                            color: item.backgroundColor,
                            textAlign: "end",
                            paddingRight: "0",
                        }}
                    >
                        {getItemData(item, messageCountByDayData).width +  "%"}
                    </div>
                    <div className="divider"></div>
                </div>
            </>
        )) : newData.map((item, index) => (
            <>
                <div key={index} className="d-flex align-items-center" style={{ borderBottom: index !== newData.length - 1 ? "2px dashed #E6E7E9" : "none" }}>
                    <div className="col-xxl-1 col-xl-1 customGraph_yAxis__value simple-text font-primary">{item.text}</div>
                    <div className="customGraph_bar__container col-xxl-10 col-xl-9">
                        <div
                            key={index}
                            style={{
                                backgroundColor: item.backgroundColor,
                                width: item.width,
                                whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                            }}
                            className="customGraph_bar"
                        ></div>
                    </div>

                    <div
                        className="col-xxl-1 col-xl-2"
                        style={{
                            color: item.backgroundColor,
                            textAlign: "end",
                            paddingRight: "0",
                        }}
                    >
                        {item.width}
                    </div>
                    <div className="divider"></div>
                </div>
            </>
        ))}
    </React.Fragment>
    )
}

export default AnalyseMessageSavHeure;