import React from "react";
import "./style.css";

function White() {
  return (
    <div className="white">
      <div className="div">
        <div className="div-2">
          <div className="div-2">
            <div className="group">
              <div className="overlap-group">
                <img className="img" alt="Group" src="/img/group-17.png" />
                <img className="frame" alt="Frame" src="/img/frame-3.svg" />
                <div className="overlap">
                  <div className="rectangle" />
                  <div className="rectangle-2" />
                  <div className="element">156</div>
                  <img className="group-2" alt="Group" src="/img/group-16.png" />
                </div>
                <img className="group-3" alt="Group" src="/img/group-237171.png" />
                <img className="group-4" alt="Group" src="/img/group-15.png" />
                <img className="group-5" alt="Group" src="/img/group-14.png" />
                <img className="group-6" alt="Group" src="/img/group-237173.png" />
                <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-6226.svg" />
              </div>
            </div>
          </div>
          <img className="group-7" alt="Group" src="/img/group-237176.png" />
        </div>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="top-bar">
              <div className="overlap-4">
                <img className="group-8" alt="Group" src="/img/group-1000002755.png" />
                <p className="menu">
                  <span className="text-wrapper">64 / </span>
                  <span className="span">500</span>
                </p>
                <div className="overlap-wrapper">
                  <div className="overlap-5">
                    <div className="group-9">
                      <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-1393-1.png" />
                      <div className="group-10">
                        <img className="group-11" alt="Group" src="/img/group-21861.png" />
                        <div className="text-wrapper-2">Active</div>
                        <div className="text-wrapper-3">John Smith</div>
                      </div>
                    </div>
                    <div className="notifications">
                      <div className="div-3">
                        <div className="div-3">
                          <div className="clarity-notification-wrapper">
                            <img
                              className="clarity-notification"
                              alt="Clarity notification"
                              src="/img/clarity-notification-line.svg"
                            />
                          </div>
                          <div className="rectangle-5" />
                        </div>
                        <img className="vector" alt="Vector" src="/img/vector-7.svg" />
                      </div>
                      <img className="vector-2" alt="Vector" src="/img/vector-6.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="group-12" alt="Group" src="/img/group-13.png" />
            <div className="sidebar">
              <div className="overlap-6">
                <div className="base" />
                <div className="icon">
                  <img className="dot" alt="Dot" src="/img/dot-6.svg" />
                </div>
                <div className="rectangle-6" />
                <div className="side-menu">
                  <img className="group-13" alt="Group" src="/img/group-12.png" />
                  <div className="menu-2">Inbox</div>
                  <div className="overlap-7">
                    <div className="menu-3">Sent</div>
                    <div className="element-2">36</div>
                    <img className="group-14" alt="Group" src="/img/group-7.png" />
                  </div>
                  <div className="overlap-8">
                    <img className="group-15" alt="Group" src="/img/group-11.png" />
                    <div className="menu-3">Treaty</div>
                    <div className="element-2">10</div>
                  </div>
                  <div className="overlap-9">
                    <div className="div-4">
                      <img className="dot" alt="Dot" src="/img/dot-6.svg" />
                    </div>
                    <div className="menu-4">Untreated</div>
                  </div>
                  <div className="overlap-10">
                    <div className="div-4">
                      <img className="group-16" alt="Group" src="/img/group-10.png" />
                      <div className="icon-2" />
                    </div>
                    <div className="menu-4">Archive</div>
                  </div>
                  <div className="overlap-11">
                    <div className="div-4">
                      <img className="group-17" alt="Group" src="/img/group-9.png" />
                      <div className="icon-2">
                        <img className="dot-2" alt="Dot" src="/img/dot-5.png" />
                      </div>
                    </div>
                    <div className="menu-4">Draft</div>
                  </div>
                  <div className="element-3">110</div>
                  <div className="overlap-group-2">
                    <div className="base-2" />
                    <img className="group-18" alt="Group" src="/img/group-8.png" />
                    <div className="menu-5">Trash</div>
                    <div className="base-3" />
                    <div className="menu-6">Files</div>
                    <div className="item">
                      <div className="menu-7">Folder 1</div>
                      <div className="text-wrapper-4">+</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-5">Logo Here</div>
            </div>
            <div className="group-19">
              <div className="menu-8">Compose Mail</div>
              <div className="text-wrapper-6">+</div>
            </div>
            <div className="menu-9">Folder 2</div>
            <div className="menu-10">Folder 2</div>
            <div className="menu-11">Folder 2</div>
            <div className="menu-12">Folder 2</div>
            <div className="frame-2">
              <div className="overlap-12">
                <div className="overlap-13">
                  <div className="rectangle-7" />
                  <div className="rectangle-8" />
                  <div className="rectangle-9" />
                  <img className="group-20" alt="Group" src="/img/group-6.png" />
                  <img className="ellipse" alt="Ellipse" src="/img/ellipse-1-4.png" />
                </div>
                <div className="overlap-14">
                  <div className="frame-wrapper">
                    <div className="frame-3" />
                  </div>
                  <div className="rectangle-10" />
                  <img className="ellipse-2" alt="Ellipse" src="/img/ellipse-1-5.png" />
                  <div className="text-wrapper-7">Guy Hawkins</div>
                  <p className="p">when will it be ready?</p>
                  <div className="frame-4">
                    <div className="text-wrapper-8">20s</div>
                    <img className="frame-5" alt="Frame" src="/img/frame-2.svg" />
                  </div>
                </div>
              </div>
              <div className="group-21">
                <div className="text-wrapper-9">All</div>
                <div className="text-wrapper-10">Unread</div>
                <div className="text-wrapper-11">Marked</div>
              </div>
              <div className="group-22">
                <div className="rectangle-11" />
                <div className="ellipse-3" />
                <div className="group-23">
                  <div className="rectangle-12" />
                  <div className="ellipse-3" />
                </div>
                <div className="group-24">
                  <div className="rectangle-13" />
                  <div className="ellipse-4" />
                </div>
              </div>
              <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-1-6.png" />
              <div className="rectangle-14" />
              <div className="rectangle-15" />
              <div className="rectangle-16" />
              <div className="text-wrapper-12">Cameron Williamson</div>
              <p className="text-wrapper-13">Not too bad, just trying to catch up on some work. How about you?</p>
              <div className="frame-6">
                <div className="text-wrapper-8">5s</div>
                <div className="div-wrapper">
                  <div className="text-wrapper-14">1</div>
                </div>
              </div>
              <img className="ellipse-6" alt="Ellipse" src="/img/ellipse-1-3.png" />
              <div className="text-wrapper-15">Bessie Cooper</div>
              <p className="that-s-a-good-idea-i">
                That&#39;s a good idea. I&#39;ll have to try that. So, what&#39;s the latest on the new client account
                we&#39;re wor
              </p>
              <div className="frame-7">
                <div className="text-wrapper-8">1d</div>
                <img className="frame-5" alt="Frame" src="/img/frame-1.svg" />
              </div>
              <img className="ellipse-7" alt="Ellipse" src="/img/ellipse-1-2.png" />
              <div className="text-wrapper-16">Courtney Henry</div>
              <p className="text-wrapper-17">
                Sure, that sounds good. I need to take a break from staring at my computer screen all day.
              </p>
              <div className="frame-8">
                <div className="text-wrapper-8">12h</div>
                <div className="div-wrapper">
                  <div className="text-wrapper-18">1</div>
                </div>
              </div>
              <img className="ellipse-8" alt="Ellipse" src="/img/ellipse-1-1.png" />
              <div className="rectangle-17" />
              <div className="text-wrapper-19">Kathryn Murphy</div>
              <p className="text-wrapper-20">Thanks, I appreciate it. Hey, do you want to grab lunch together later?</p>
              <div className="frame-9">
                <div className="text-wrapper-8">2w</div>
                <div className="div-wrapper">
                  <div className="text-wrapper-21">2</div>
                </div>
              </div>
              <img className="line" alt="Line" src="/img/line-4-1.svg" />
              <img className="line-2" alt="Line" src="/img/line-4-1.svg" />
              <img className="line-3" alt="Line" src="/img/line-5-1.svg" />
              <div className="frame-10">
                <div className="frame-3" />
              </div>
              <img className="line-4" alt="Line" src="/img/line-7.svg" />
              <div className="menu-13">Inbox</div>
              <div className="text-wrapper-22">+</div>
              <div className="group-25" />
            </div>
            <div className="rectangle-18" />
            <div className="group-26">
              <div className="menu-14">Evolution</div>
              <img className="vector-3" alt="Vector" src="/img/vector.svg" />
            </div>
            <div className="dot-wrapper">
              <img className="dot-3" alt="Dot" src="/img/dot-4.svg" />
            </div>
            <div className="img-wrapper">
              <img className="dot-3" alt="Dot" src="/img/dot-3.svg" />
            </div>
            <div className="icon-3">
              <img className="dot-3" alt="Dot" src="/img/dot-2.svg" />
            </div>
            <div className="icon-4">
              <img className="dot-3" alt="Dot" src="/img/dot-1.svg" />
            </div>
            <div className="icon-5">
              <img className="dot-3" alt="Dot" src="/img/dot.svg" />
            </div>
            <div className="group-27">
              <div className="frame-11">
                <div className="text-wrapper-8">18h</div>
                <img className="frame-5" alt="Frame" src="/img/frame.svg" />
              </div>
              <div className="group-28">
                <div className="group-29">
                  <div className="group-30">
                    <div className="menu-15">Trash</div>
                    <img className="group-31" alt="Group" src="/img/group-3.png" />
                  </div>
                  <div className="group-wrapper">
                    <div className="group-32">
                      <div className="menu-16">Move To</div>
                      <img className="group-33" alt="Group" src="/img/group-2.png" />
                    </div>
                  </div>
                  <div className="group-34">
                    <div className="menu-17">Starred</div>
                    <img className="group-35" alt="Group" src="/img/group-1.png" />
                  </div>
                </div>
                <div className="text-wrapper-23">Robert Fox</div>
                <div className="website-design">Website Design &amp; Development</div>
              </div>
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-15">
                <img className="group-36" alt="Group" src="/img/group.png" />
              </div>
            </div>
            <div className="search-bar">
              <img className="magnifier" alt="Magnifier" src="/img/magnifier.svg" />
              <div className="text-wrapper-24">Search here...</div>
              <div className="group-37">
                <div className="rectangle-19" />
                <div className="ellipse-9" />
                <div className="group-23">
                  <div className="rectangle-20" />
                  <div className="ellipse-9" />
                </div>
                <div className="group-24">
                  <div className="rectangle-21" />
                  <div className="ellipse-10" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-12">
            <div className="frame-13">
              <div className="frame-5" />
            </div>
            <div className="frame-14">
              <div className="frame-3" />
            </div>
            <img className="line-5" alt="Line" src="/img/line-8-1.svg" />
            <img className="line-6" alt="Line" src="/img/line-8-1.svg" />
            <img className="line-7" alt="Line" src="/img/line-8-1.svg" />
            <img className="line-8" alt="Line" src="/img/line-13.svg" />
            <div className="menu-18">Customer Details</div>
            <div className="text-wrapper-25">+</div>
            <img className="line-9" alt="Line" src="/img/line-8-1.svg" />
            <div className="menu-19">TAGS</div>
            <div className="menu-20">More Information</div>
            <div className="menu-21">Topics</div>
            <img className="shape" alt="Shape" src="/img/shape.svg" />
            <img className="shape-2" alt="Shape" src="/img/shape.svg" />
            <img className="shape-3" alt="Shape" src="/img/shape.svg" />
            <div className="menu-22">Location:</div>
            <p className="text-wrapper-26">Quis alias qui enim exercitationem qui dolorum sint.</p>
            <div className="text-wrapper-27">Los Angels</div>
            <div className="menu-23">Summary :</div>
            <div className="ellipse-wrapper">
              <img className="ellipse-11" alt="Ellipse" src="/img/ellipse-2.png" />
            </div>
            <div className="group-38">
              <div className="overlap-16">
                <img className="vector-4" alt="Vector" src="/img/vector-5.svg" />
                <div className="group-39">
                  <div className="overlap-group-3">
                    <div className="menu-24">Robert Fox</div>
                    <div className="menu-25">Entrepreneur</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="ellipse-12" alt="Ellipse" src="/img/ellipse-5-1.png" />
          <div className="text-wrapper-28">Robert Fox 5/10/23</div>
          <div className="frame-15">
            <div className="frame-16">
              <img className="vector-5" alt="Vector" src="/img/vector-4.svg" />
              <div className="text-wrapper-29">B</div>
              <div className="text-wrapper-30">I</div>
              <div className="text-wrapper-31">U</div>
              <img className="line-10" alt="Line" src="/img/line-5.svg" />
              <img className="vector-6" alt="Vector" src="/img/vector-3.svg" />
              <img className="vector-7" alt="Vector" src="/img/vector-2.svg" />
              <img className="line-11" alt="Line" src="/img/line-5.svg" />
              <img className="vector-8" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="text-wrapper-32">Type Message</div>
          </div>
          <div className="group-40">
            <div className="rectangle-wrapper">
              <img className="rectangle-22" alt="Rectangle" src="/img/rectangle-1393.png" />
            </div>
            <div className="sit-omnis-enim-sit-wrapper">
              <p className="sit-omnis-enim-sit">
                Sit omnis enim sit omnis eveniet quo autem culpa qui perspiciatis nulla. Et facere animi quo expedita
                accusamus ea rerum quae qui similique dolorum non quia nulla qui magnam odit a corporis sunt!
                <br />
                <br />
                Ea expedita officia et iure similique ut aliquid soluta et saepe dolore 33 nisi facere sed rerum
                ratione. Eos maxime dolorem ut placeat quibusdam qui consectetur voluptatum et quasi recusandae qui
                delectus culpa.
              </p>
            </div>
          </div>
          <div className="frame-17">
            <p className="et-rerum-mollitia-ut">
              Et rerum mollitia ut corrupti architecto eum vero quam. Qui internos maiores eum molestiae enim et.
              <br />
              <br />
              Id Quis alias qui enim exercitationem qui dolorum sint.
            </p>
            <div className="menu-wrapper">
              <div className="menu-26">Product Tips</div>
            </div>
          </div>
          <div className="overlap-17">
            <div className="group-41">
              <div className="menu-27">Website Design &amp; Development</div>
              <p className="assign-by-admin">
                <span className="text-wrapper-33">Assign by</span>
                <span className="text-wrapper-34">
                  {" "}
                  Admin&nbsp;&nbsp;•&nbsp;&nbsp;2 months
                  ago&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                <span className="text-wrapper-35">&nbsp;</span>
                <span className="text-wrapper-36">Due Date</span>
                <span className="text-wrapper-34"> - Oct 10 </span>
              </p>
              <p className="assign-to-john-smith">
                <span className="text-wrapper-37">Assign To</span>
                <span className="text-wrapper-38">
                  {" "}
                  - John Smith&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="text-wrapper-39">Priority</span>
                <span className="text-wrapper-38">
                  {" "}
                  - No priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="text-wrapper-40">Tags</span>
                <span className="text-wrapper-38"> -&nbsp;&nbsp;Customer Support</span>
              </p>
            </div>
            <div className="group-42">
              <div className="overlap-18">
                <div className="group-43">
                  <div className="menu-28">Close</div>
                </div>
              </div>
              <img className="group-44" alt="Group" src="/img/group-5.png" />
              <div className="overlap-19">
                <img className="group-45" alt="Group" src="/img/group-4.png" />
              </div>
              <div className="group-46">
                <div className="rectangle-23" />
                <div className="ellipse-13" />
                <div className="group-23">
                  <div className="rectangle-24" />
                  <div className="ellipse-13" />
                </div>
                <div className="group-24">
                  <div className="rectangle-25" />
                  <div className="ellipse-14" />
                </div>
              </div>
            </div>
          </div>
          <img className="line-12" alt="Line" src="/img/line-8.svg" />
          <div className="group-47">
            <img className="group-48" alt="Group" src="/img/group-1000002738.png" />
            <div className="text-wrapper-41">John Smith 10:45 AM</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default White

