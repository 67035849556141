import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
          style={{
            padding: "10px",
            borderRadius: "6.756px",
            background: "#FFFAF1",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M19.1684 15.7117C18.6255 15.2277 18.1502 14.6729 17.7553 14.0622C17.3242 13.2192 17.0659 12.2986 16.9953 11.3544V8.57333C16.9991 7.09027 16.4611 5.65689 15.4825 4.5425C14.5039 3.42811 13.1521 2.70943 11.681 2.52148V1.79526C11.681 1.59593 11.6018 1.40477 11.4609 1.26383C11.3199 1.12288 11.1287 1.0437 10.9294 1.0437C10.7301 1.0437 10.5389 1.12288 10.398 1.26383C10.257 1.40477 10.1779 1.59593 10.1779 1.79526V2.53274C8.71994 2.73424 7.38443 3.45727 6.4187 4.56791C5.45297 5.67855 4.92246 7.10155 4.92542 8.57333V11.3544C4.85491 12.2986 4.59653 13.2192 4.16542 14.0622C3.77747 14.6715 3.30983 15.2263 2.7749 15.7117C2.71485 15.7645 2.66673 15.8294 2.63372 15.9022C2.60072 15.975 2.5836 16.054 2.5835 16.1339V16.8996C2.5835 17.0489 2.64281 17.1921 2.74838 17.2976C2.85396 17.4032 2.99715 17.4625 3.14646 17.4625H18.7968C18.9461 17.4625 19.0893 17.4032 19.1949 17.2976C19.3005 17.1921 19.3598 17.0489 19.3598 16.8996V16.1339C19.3597 16.054 19.3426 15.975 19.3096 15.9022C19.2766 15.8294 19.2284 15.7645 19.1684 15.7117ZM3.75446 16.3366C4.27824 15.8306 4.73942 15.2636 5.12809 14.6477C5.67113 13.6296 5.98798 12.5062 6.05698 11.3544V8.57333C6.03465 7.91356 6.14532 7.25604 6.3824 6.63993C6.61947 6.02382 6.9781 5.46171 7.43694 4.98709C7.89577 4.51246 8.44543 4.13503 9.05317 3.87725C9.66091 3.61947 10.3143 3.48663 10.9745 3.48663C11.6346 3.48663 12.288 3.61947 12.8958 3.87725C13.5035 4.13503 14.0531 4.51246 14.512 4.98709C14.9708 5.46171 15.3294 6.02382 15.5665 6.63993C15.8036 7.25604 15.9143 7.91356 15.8919 8.57333V11.3544C15.9609 12.5062 16.2778 13.6296 16.8208 14.6477C17.2095 15.2636 17.6707 15.8306 18.1945 16.3366H3.75446Z"
              fill="#FFA412"
            />
            <path
              d="M10.9995 19.3427C11.3541 19.3346 11.6944 19.2012 11.9602 18.9663C12.226 18.7314 12.4002 18.4101 12.4519 18.0592H9.49072C9.54391 18.4196 9.72622 18.7485 10.0038 18.9846C10.2813 19.2207 10.6351 19.348 10.9995 19.3427Z"
              fill="#FFA412"
            />
          </svg>
          <span className="noti-dot"></span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/#" className="small">
                  {" "}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="ri-shopping-cart-line"></i>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      If several languages coalesce the grammar
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <img
                    src={avatar3}
                    className="rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                <div className="flex-grow-1">
                  <h6 className="mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      It will seem like simplified English.
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i> 1 hours ago
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <i className="ri-checkbox-circle-line"></i>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mb-1">Your item is shipped</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      If several languages coalesce the grammar
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <img
                    src={avatar4}
                    className="rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                <div className="flex-grow-1">
                  <h6 className="mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      As a skeptical Cambridge friend of mine occidental.
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i> 1 hour ago
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
