import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import monitor from '../../assets/images/monitor.png';
import axios from 'axios';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  FormFeedback,
  Form,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import moment from 'moment-timezone';
import classnames from 'classnames';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SettingEmailConfig from './SettingEmailConfig';
import General from './General';
// import Ressources from "./Ressources";
import { Client, Databases, Query, Account, ID } from 'appwrite';
import { flatMap } from 'lodash';
import Notifications from './Notifications';
import Facturation from './Facturation';
import IntlTelInput from 'react-intl-tel-input';
import getFrenchTag from '../../helpers/local_text_helper';
import Flatpickr from 'react-flatpickr';
// slider
import MultiRangeSlider from 'multi-range-slider-react';

import languages from '../../CommonData/languages';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import notificationData from '../../CommonData/Data/notification';

const Settings = () => {
  const timezones = moment.tz.names();
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [btnmenu1, setbtnmenu1] = useState(false);
  const [btnmenu2, setbtnmenu2] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Choisir une langue');
  const [modal_list, setmodal_list] = useState(false);
  const [addAgentModal, setAddAgentModal] = useState(false);
  const [updateAgentModal, setUpdateAgentModal] = useState(false);
  const [updateEmployeeModal, setUpdateEmployeeModal] = useState(false);
  const [employeeToUpdate, setEmployeeToUpdate] = useState();
  const [myList, setMyList] = useState([]);
  const [ownerInfos, setOwnerInfos] = useState({});
  const [company_infos, setCompanyInfos] = useState(undefined);
  const [company_employees_data, setCompanyEmployeesData] = useState(undefined);
  const [userInfos, setUserInfos] = useState(undefined);
  const [userPrefs, setUserPrefs] = useState({});
  const [toast, setToast] = useState(false);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [timesByDay, setTimesByDay] = useState({
    Monday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Tuesday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Wednesday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Thursday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Friday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Saturday: {
      checked: false,
      open: 0,
      close: 24,
    },
    Sunday: {
      checked: false,
      open: 0,
      close: 24,
    },
  });
  const [formErrors, setFormErrors] = useState({});
  const [agentData, setagentData] = useState([]);
  const [itemStates, setItemStates] = useState([]);
  const [agentSelectedTimezone, setAgentTimezone] = useState('');
  const [selectedMulti3, setselectedMulti3] = useState(null);
  const handleTimeChange = (day, timeType, time) => {
    console.log('handleTimeChange', day, timeType, time);
    console.log('timesByDay', timesByDay);
    setTimesByDay((prevState) => {
      return {
        ...prevState,
        // [day]: {
        //     ...prevState[day],
        //     checked: true,
        //     [timeType]: time
        // }

        [day]: {
          ...prevState[day],
          [timeType]: time,
        },
      };
    });
    console.log('***********timesByDay***********', timesByDay);
  };

  const languageStore = [
    { name: 'Français', ind: 'fr' },
    { name: 'Anglais', ind: 'en' },
    { name: 'Espagnol', ind: 'es' },
    { name: 'Allemand', ind: 'de' },
  ];
  const optionGroup2 = [
    { label: 'Anglais', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Espagnol', value: 'es' },
    { label: 'Allemand', value: 'de' },
  ];
  const sendLanguageFlag = (lang) => {
    const [languageData] = languageStore.filter((val) => val.ind === lang);
    if (languageData) {
      const resultLanguage = languages[lang];
      return resultLanguage.flag;
    } else {
      return '';
    }
  };
  const animatedComponents = makeAnimated();

  const handleMulti3 = (val) => {
    console.log('selectedMulti3 is', val);
    setselectedMulti3(val);
  };

  //
  const [authorizations, setAuthorizations] = useState([
    { id: 1, parents: [], text: 'Sélectionner toutes les autorisations', value: 'Toutes les autorisations sont sélectionnées', status: false },
    { id: 2, parents: [1], text: 'Accueil', value: 'dashboard', status: false },
    { id: 3, parents: [1], text: 'E-mail', value: 'email', status: false },
    { id: 4, parents: [1], text: 'Chat', value: 'chat', status: false },
    { id: 5, parents: [1], text: 'Analytiques', value: 'analytics', status: false },
    { id: 6, parents: [1], text: 'Paramètres', value: 'settings', status: false },
    { id: 7, parents: [1, 2], text: 'Accueil', value: 'dashboard', status: false },
    { id: 8, parents: [1, 3], text: 'Boite de réception', value: 'inbox', status: false },
    { id: 9, parents: [1, 3], text: 'Mail traités', value: 'treated', status: false },
    { id: 10, parents: [1, 3], text: 'Mail non-traité', value: 'untreated', status: false },
    { id: 11, parents: [1, 3], text: 'Créer/Modifier dossier', value: 'create_box', status: false },
    { id: 12, parents: [1, 3], text: 'Copier/Déplacer/Supprimer message', value: 'manage_messages', status: false },
    { id: 13, parents: [1, 4], text: 'Chat', value: 'chat', status: false },
    { id: 14, parents: [1, 5], text: 'Analytiques', value: 'analytics', status: false },
    { id: 15, parents: [1, 6], text: 'E-mail', value: 'settings_email', status: false },
    { id: 16, parents: [1, 6], text: 'Role', value: 'role', status: false },
    { id: 17, parents: [1, 6], text: 'Facturation', value: 'billing', status: false },
    { id: 18, parents: [1, 6], text: 'Forfait', value: 'package', status: false },
  ]);
  const onAuthorizationsChanged = (val) => {
    setAuthorizations(val);
  };

  const [notifications, setNotifications] = useState({
    high_trafic_alert: false, //(Alertes de Volume Élevé)
    email_transfer: false, //(Notifications de Transfert de Mail)
    urgent_response_alert: false, //(Alertes de Réponse Urgente)
    follow_up_reminder: false, //(Rappels de Suivi)
    tag_updates: false, //(Mises à Jour des Tags)
    performance_updates: false, //(Notfications de Performance)
    security_alerts: false, //(Alertes de Sécurité)
    important_customer_feedback: false, //(Feedback Client Important)
    system_updates: false, //(Mises à Jour du Système)
    technical_issue_alerts: false, //(Alertes de Problèmes Techniques)
  });

  const [allCkecked, setAllChecked] = useState(false);

  // const all_checks = new Map();

  const user = JSON.parse(localStorage.getItem('authUser'));
  // console.log('user is', user);
  const [employeesList, setEmployeesList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [updateUserPrefsLoading, setUpdateUserPrefsLoading] = useState({ loading: false, title: '', message: '' });
  const [employeeEditActions, setEmployeesEditActions] = useState({});
  const [deleteAgentLoading, setDeleteAgentLoading] = useState({});
  const [updateAgentLoading, setUpdateAgentLoading] = useState(false);

  const onUpdateAgentLoading = (value) => {
    setUpdateAgentLoading(value);
  };

  const [updateAgentsLoading, setUpdateAgentsLoading] = useState(false);
  const onLoadingChanged = (val) => {
    setUpdateAgentsLoading(val);
  };

  //
  const [btnprimary1, setBtnprimary1] = useState(false);

  // user
  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    telephone: '',
    address: '',
  });

  // Gender DropDown
  const [singlebtn, setSinglebtn] = useState(false);

  // Update Phone Modal
  const [updatePhoneModal, setupdatePhoneModal] = useState(false);
  const tog_phone_modal = () => {
    setupdatePhoneModal(!updatePhoneModal);
  };
  const [password, setPassword] = useState('');
  const onPasswordChange = (val) => {
    setPassword(val);
  };
  const [validation, setValidation] = useState({ errors: null, touched: false });
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const [modalInfo, setModalInfo] = useState({
    last_name: '',
    first_name: '',
    email: '',
    autorizations: [],
  });

  const [agentModalInfo, setAgentModalInfo] = useState({
    agent_work_times: {},
    agent_name: '',
    agent_alias: '',
    agent_language: [],
  });

  const toggleToast = () => {
    console.log('setToast before: ' + toast);
    setToast(true);
    console.log('setToast after: ' + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const togglePhoneToast = () => {
    console.log('setToast before: ' + validation.errors);
    setValidation({ ...validation, errors: false });
    console.log('setToast after: ' + validation.errors);

    setTimeout(() => {
      setValidation({ ...validation, errors: false });
    }, 3000);
  };

  const handleEmployeeAdded = async () => {
    let autorizations = [];
    authorizations.forEach((auth) => {
      if (auth.status) {
        if (auth.parents.length === 0) {
          autorizations.push(auth.value);
          return;
        } else {
          autorizations.push(auth.value);
        }
      }
    });

    modalInfo.autorizations = autorizations;
    onLoading(true);
    const employees = await getCompanyEmployeesData();
    if (!employees || employees.length === 0) {
      return;
    }

    const employeeAccount = await createEmployeeAccount({
      last_name: modalInfo.last_name,
      first_name: modalInfo.first_name,
      email: modalInfo.email,
      autorizations: modalInfo.autorizations,
      notifications: notifications,
    });
    if (!employeeAccount) {
      onLoading(false);
      return;
    }

    const newItem = {
      id: employeeAccount.$id,
      last_name: modalInfo.last_name,
      first_name: modalInfo.first_name,
      email: modalInfo.email,
      autorizations: modalInfo.autorizations,
      notifications: notifications,
    };

    const updatedList = [...employeesList, newItem];
    console.log('List des employee stringify', JSON.stringify(updatedList));
    const employeeList = await updateCompanyEmployeesData(employees[0].$id, { employees: updatedList.map((element) => JSON.stringify(element)) });
    if (!employeeList) {
      return;
    }

    console.log(updatedList);
    setEmployeesList(updatedList);
    setModalInfo({});
    // const prefs = new Map().merge(userPrefs, updatedList);
    userPrefs['employees'] = updatedList;
    // await updateUserPrefs(userPrefs);

    if (!employeeAccount) {
      return;
    }
    await createEmployeeLink(employeeAccount.$id, localStorage.getItem('companyId'));
    tog_list();
  };

  const handleAgentAdded = async () => {
    /*****************************************/
    // console.log("PrintTimes", timesByDay);
    // const errors = {};

    // daysOfWeek.forEach(day => {
    //   if (timesByDay[day].checked) {
    //     if (!timesByDay[day].open || !timesByDay[day].close) {
    //       errors[day] = "Veuillez sélectionner les heures d'ouverture et de fermeture.";
    //     }
    //     if (timesByDay[day].open > timesByDay[day].close) {
    //       errors[day] = "L'heure d'ouverture doit être antérieure à l'heure de fermeture.";
    //     }
    //   }
    // });

    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    // } else {
    //   setFormErrors({});
    // modalInfo.agent_language = selectedOption;
    agentModalInfo.agent_work_times = timesByDay;
    agentModalInfo.agent_timezone = agentSelectedTimezone;
    const newItem = {
      id: Date.now().toString(), // Nouvel ID basé sur la longueur actuelle de la liste
      agent_work_times: agentModalInfo.agent_work_times,
      agent_name: agentModalInfo.agent_name,
      agent_alias: agentModalInfo.agent_alias,
      agent_language: agentModalInfo.agent_language,
      agent_timezone: agentModalInfo.agent_timezone,
      active: false,
    };

    const updatedList = [...agentsList, newItem];
    // setItemStates(Array(updatedList.length).fill({ spanVisible: false, buttonDisabled: false, element_loading: false }));
    console.log('updatedAgentsList is', updatedList);

    onLoadingChanged(true);

    updateCompanyInfos({
      agents: updatedList.map((element) => JSON.stringify(element)),
    })
      .then((newAgentsList) => {
        onLoadingChanged(false);
        const agents = newAgentsList.map((element) => JSON.parse(element));
        setAgentsList(agents);
        clearAgentModalInfo();
        togAddAgentModal();
      })
      .catch((error) => {
        // Gérer les erreurs ici si nécessaire
        console.error("Erreur lors de la mise à jour des informations de l'entreprise :", error);
      });

    // }
  };

  const handleModalAgentUpdate = async (id) => {
    const index = agentsList.findIndex((agent) => agent.id === id);
    agentModalInfo.agent_work_times = timesByDay;
    agentModalInfo.agent_timezone = agentSelectedTimezone;
    const updatedAgentsList = [...agentsList];
    updatedAgentsList[index] = {
      ...updatedAgentsList[index],
      agent_work_times: agentModalInfo.agent_work_times,
      agent_name: agentModalInfo.agent_name,
      agent_alias: agentModalInfo.agent_alias,
      agent_language: agentModalInfo.agent_language,
      agent_timezone: agentModalInfo.agent_timezone,
    };

    console.log('updatedAgentsList is', updatedAgentsList);

    onUpdateAgentLoading(true);

    updateCompanyInfos({
      agents: updatedAgentsList.map((element) => JSON.stringify(element)),
    })
      .then((newAgentsList) => {
        onUpdateAgentLoading(false);
        const agents = newAgentsList.map((element) => JSON.parse(element));
        setAgentsList(agents);
        clearAgentModalInfo();
        togUpdateAgentModal();
      })
      .catch((error) => {
        // Gérer les erreurs ici si nécessaire
        console.error("Erreur lors de la mise à jour des informations de l'entreprise :", error);
      });
  };
  const deleteAgentActions = async (id) => {
    setDeleteAgentLoading((prevState) => ({
      ...prevState,
      [id]: true,
    }));

    let applyed;
    applyed = await deleteAgent(agentsList.find((agent) => agent.id === id).id);

    if (applyed) {
      const updatedAgentsList = agentsList.filter((agent) => agent.id !== id);
      const newAgentsList = await updateCompanyInfos({
        agents: updatedAgentsList.map((element) => JSON.stringify(element)),
      });
      const agents = newAgentsList.map((element) => JSON.parse(element));
      setAgentsList(agents);
    }
    setDeleteAgentLoading((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleModalEmployeeUpdate = async () => {
    let autorizations = [];
    authorizations.forEach((auth) => {
      if (auth.status) {
        if (auth.parents.length === 0) {
          autorizations.push(auth.value);
          return;
        } else {
          autorizations.push(auth.value);
        }
      }
    });

    const updatedList = [...employeesList];
    const index = updatedList.findIndex((employee) => employee.id === employeeToUpdate.id);
    updatedList[index] = {
      ...employeeToUpdate,
      autorizations: autorizations,
      notifications: notifications,
    };

    onLoading(true);

    const employees = await getCompanyEmployeesData();
    if (!employees || employees.length === 0) {
      return;
    }

    const employeeList = await updateCompanyEmployeesData(employees[0].$id, { employees: updatedList.map((element) => JSON.stringify(element)) });
    if (!employeeList) {
      return;
    }

    console.log(updatedList);
    setEmployeesList(updatedList);
    userPrefs['employees'] = updatedList;
    tog_updateEmployeeModal();
  };

  const decodeAuthozations = (autorizations) => {
    console.log('autorizations is:', autorizations);
    clearAllCheckBoxes();

    const updatedAuthorizations = [...authorizations];
    autorizations.forEach((auth_database) => {
      if (auth_database === 'Toutes les autorisations sont sélectionnées') {
        for (let i = 0; i < updatedAuthorizations.length; i++) {
          updatedAuthorizations[i] = { ...updatedAuthorizations[i], status: true };
        }
        return;
      } else {
        authorizations.forEach((auth, idx) => {
          if (auth.value === auth_database) {
            updatedAuthorizations[idx] = { ...updatedAuthorizations[idx], status: true };
          }
        });
      }
    });
    setAuthorizations(updatedAuthorizations);
  };

  const clearAllCheckBoxes = () => {
    const updatedAuthorizations = [...authorizations];
    for (let i = 0; i < updatedAuthorizations.length; i++) {
      updatedAuthorizations[i] = { ...updatedAuthorizations[i], status: false };
    }
    setAuthorizations(updatedAuthorizations);
  };

  const clearAgentModalInfo = () => {
    setAgentModalInfo({
      agent_work_times: {},
      agent_name: '',
      agent_alias: '',
      agent_language: [],
    });
    setTimesByDay({
      Monday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Tuesday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Wednesday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Thursday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Friday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Saturday: {
        checked: false,
        open: 0,
        close: 24,
      },
      Sunday: {
        checked: false,
        open: 0,
        close: 24,
      },
    });
  };

  const deleteUserRole = async (user_id) => {
    const employees = await getCompanyEmployeesData();
    if (!employees || employees.length === 0) {
      console.log('no employee datas');
      setEmployeesEditActions((prevState) => ({
        ...prevState,
        [user_id]: { state: true, loading: false },
      }));
      return;
    }

    const updatedList = employeesList.filter((item) => item.id !== user_id);

    const employeeList = await updateCompanyEmployeesData(employees[0].$id, { employees: updatedList.map((element) => JSON.stringify(element)) });
    if (!employeeList) {
      setEmployeesEditActions((prevState) => ({
        ...prevState,
        [user_id]: { state: true, loading: false },
      }));
      return;
    }

    const employeeLink = await getEmployeeLinks(user_id);
    console.log('specific Employee Link is', employeeLink);
    if (employeeLink && employeeLink.length > 0) {
      const theLink = employeeLink.filter((item) => item.employee_id === user_id && item.company_id === employees[0].company_id);
      if (theLink) {
        console.log('theLink is', theLink);
        const deletedEmployee = await deleteEmployeeLink(theLink[0].$id);
        if (deletedEmployee) {
          console.log(updatedList);
          setEmployeesList(updatedList);
        }
      } else {
        console.log('no theLink found', theLink);
      }
    }
    setEmployeesEditActions((prevState) => ({
      ...prevState,
      [user_id]: { state: true, loading: false },
    }));
  };

  const updateUserRole = async (user_id) => {
    onLoading(true);
    const employees = await getCompanyEmployeesData();
    if (!employees || employees.length === 0) {
      return;
    }

    const newItem = {
      id: user_id,
      last_name: modalInfo.last_name,
      first_name: modalInfo.first_name,
      email: modalInfo.email,
      autorizations: modalInfo.autorizations,
    };

    const updatedList = employeesList.map((item) => {
      if (item.id === user_id) {
        return newItem;
      }
      return item;
    });

    console.log(updatedList);
    setEmployeesList(updatedList);

    const employeeList = await updateCompanyEmployeesData(employees[0].$id, { employees: updatedList.map((element) => JSON.stringify(element)) });
    if (!employeeList) {
      return;
    }

    const employeeLink = await getEmployeeLinks(user_id);
    if (employeeLink && employeeLink.length > 0) {
      const theLink = employeeLink.filter((item) => item.employee_id === user_id && item.company_id === employees[0].company_id);
      if (theLink) await deleteEmployeeLink(theLink.$id);
    }
  };

  const toggleVerticalIcon = (tab) => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] = useState('1');

  // New Logic - Accordion
  const handleParentCheckboxChange = (parentId, value, id) => {
    // Find Children and apply the parent new value
    let updatedAuthorizations = [...authorizations];
    updatedAuthorizations.forEach((authorization, idx) => {
      if (authorization.parents.includes(parentId)) {
        updatedAuthorizations[idx] = { ...authorization, status: value };
      }
    });

    if (id) {
      // if value false then uncheck first degree parent
      if (!value) {
        updatedAuthorizations[0] = { ...updatedAuthorizations[0], status: false };
      } else {
        let allChildrenChecked = true;
        // verify if all children besides the one of the parent changed are checked
        updatedAuthorizations.forEach((auth) => {
          if (!auth.parents.includes(id) && auth.id !== 1 && auth.status === false) {
            allChildrenChecked = false;
          }
        });
        // if all children are checked then check the second degree parent
        if (allChildrenChecked) {
          updatedAuthorizations[0] = { ...updatedAuthorizations[0], status: true };
        }
      }
    }

    console.log('updatedAuthorizations is', authorizations);
    onAuthorizationsChanged(updatedAuthorizations);
  };
  const handleChildCheckboxChange = (id, parentId, value) => {
    let updatedAuthorizations = [...authorizations];
    const index = updatedAuthorizations.findIndex((auth) => auth.id === parentId);
    // if value true then check first degree parent
    if (value) {
      updatedAuthorizations[index] = { ...updatedAuthorizations[index], status: true };

      let allChildrenChecked = true;
      // verify if all children besides the one changed and the first auth are ckecked
      updatedAuthorizations.forEach((auth) => {
        if (auth.id !== id && auth.id !== 1 && auth.status === false) {
          allChildrenChecked = false;
        }
      });
      // if all children are checked then check the second degree parent
      if (allChildrenChecked) {
        updatedAuthorizations[0] = { ...updatedAuthorizations[0], status: true };
      }
    } else {
      // uncheck second degree parent
      updatedAuthorizations[0] = { ...updatedAuthorizations[0], status: false };

      let allChildrenUnChecked = true;
      // verify if all children besides the one changed are unchecked
      updatedAuthorizations.forEach((auth) => {
        if (auth.parents.includes(parentId) && auth.id !== id && auth.status === true) {
          allChildrenUnChecked = false;
        }
      });

      // if all children are unchecked then uncheck the first degree parent
      if (allChildrenUnChecked) {
        updatedAuthorizations[index] = { ...updatedAuthorizations[index], status: false };
      }
    }
    onAuthorizationsChanged(updatedAuthorizations);
  };

  const handleNotifCheckChange = (current_key, value) => {
    const updatedNotifs = { ...notifications };
    updatedNotifs[current_key] = value;
    // Vérifier si toutes les valeurs sont true
    const isAllChecked = Object.values(updatedNotifs).every((val) => val === true);
    // Attribuer la valeur à allchecked
    setAllChecked(isAllChecked);
  };

  const handleNotifAllCheck = (val) => {
    const updatedNotifs = { ...notifications };
    for (const key in updatedNotifs) {
      if (updatedNotifs.hasOwnProperty(key)) {
        updatedNotifs[key] = val;
      }
    }
    setNotifications(updatedNotifs);
  };

  function tog_list() {
    if (modal_list) {
      clearAllCheckBoxes();
      handleNotifAllCheck(false);
    }
    setmodal_list(!modal_list);
  }

  async function startAgent(agent_name) {
    try {
      const companyId = localStorage.getItem('companyId');

      const response = await axios.post(
        'https://mailer-ai.semecloud.tech/start-mailer',
        // 'http://localhost:35000/start-mailer',
        {
          id: companyId,
          company_id: companyId,
          agent_id: agent_name,
        }
      );

      console.log(`Successfully started agent ${agent_name}`, response);

      // company_infos.server_active = true;
      return true;
    } catch (error) {
      console.log(`Failed starting Agent ${agent_name}:`, error);
      return false;
    }
  }

  async function stopAgent(agent_name) {
    try {
      const companyId = localStorage.getItem('companyId');

      const response = await axios.post(
        'https://mailer-ai.semecloud.tech/stop-mailer',
        // 'http://localhost:35000/stop-mailer',
        {
          id: companyId,
          company_id: companyId,
          agent_id: agent_name,
        }
      );

      console.log(`Successfully stopped agent ${agent_name}`, response);

      // company_infos.server_active = true;
      return true;
    } catch (error) {
      console.log(`Failed stopping Agent ${agent_name}:`, error);
      return false;
    }
  }

  async function deleteAgent(agent_name) {
    try {
      const companyId = localStorage.getItem('companyId');

      const response = await axios.post(
        'https://mailer-ai.semecloud.tech/delete-mailer',
        // 'http://localhost:35000/delete-mailer',
        {
          id: companyId,
          company_id: companyId,
          agent_id: agent_name,
        }
      );

      console.log(`Successfully stopped agent ${agent_name}`, response);

      // company_infos.server_active = true;
      return true;
    } catch (error) {
      console.log(`Failed stopping Agent ${agent_name}:`, error);
      return false;
    }
  }

  function togAddAgentModal() {
    setAddAgentModal(!addAgentModal);
  }
  function togUpdateAgentModal() {
    setUpdateAgentModal(!updateAgentModal);
    clearAgentModalInfo();
  }
  function tog_updateEmployeeModal() {
    if (updateEmployeeModal) {
      clearAllCheckBoxes();
      handleNotifAllCheck();
    }
    setUpdateEmployeeModal(!updateEmployeeModal);
  }

  const onEmployeeToUpdateChanged = (emp) => {
    setEmployeeToUpdate(emp);
    setNotifications(emp.notifications);
    setAllChecked(Object.values(emp.notifications).every((val) => val === true));
  };

  const handleDropdownItemClick = (option) => {
    console.log('handleDropdownItemClick', option);
    setSelectedOption(option);
    setbtnmenu1(!btnmenu1);
  };

  function setUtc(timezone) {
    const timeutc = moment.tz(timezone).utcOffset() / 60;
    const optionText = `${timezone} (UTC${timeutc >= 0 ? '+' : ''}${timeutc})`;
    return optionText;
  }
  const onLoading = (val) => {
    setLoading(val);
  };

  async function updateUserPhone(tel, password) {
    const client = new Client();

    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = account.updatePhone(tel, password);

    return promise.then(
      function (response) {
        onRequestIsLoading(false);
        setValidation({ ...validation, errors: null });
        onPasswordChange('');
        const new_prefs = userPrefs;
        new_prefs['telephone'] = userDetails.telephone !== '' ? userDetails.telephone : new_prefs['first_name'];
        console.log('new_tel is', new_prefs['telephone']);
        updateUserPrefs(new_prefs);
        console.log('new userPhone is ', response); // Success
        tog_phone_modal();
      },
      function (error) {
        onRequestIsLoading(false);
        onPasswordChange('');
        setValidation({ ...validation, errors: error.message });
        console.log('error on userPhone update ', error); // Failure
      }
    );
  }
  async function updateUserPrefs(userPrefs) {
    const client = new Client();

    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = account.updatePrefs(userPrefs);

    return promise.then(
      function (response) {
        console.log('new userPrefs is ', response); // Success
        setUserPrefs(userPrefs);
        setUserDetails(userPrefs);
        setUpdateUserPrefsLoading({ title: 'Success', message: 'Enregistrement réussi', loading: false });
        toggleToast();
      },
      function (error) {
        setUpdateUserPrefsLoading({ title: 'Echec', message: error.message, loading: false });
        toggleToast();
        console.log('error on userPrefs update ', error); // Failure
      }
    );
  }
  async function getUserPrefs() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = account.getPrefs();
    return promise.then(
      async function (response) {
        console.log('userInfos is', response);
        setUserPrefs(response);
        setUserDetails(response);
        // setEmployeesData(response["employees"] || []);
      },
      function (error) {
        console.log(error);
      }
    );
  }

  async function getUserAccount() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = account.get();
    return promise.then(
      async function (response) {
        console.log('userAccount is', response);
        // setRequest(false);
        //     if(company_infos.mail_config){
        //    }
        //fetchMailBoxes(data);
        const user = response;
        setUserInfos(user);
        if (user.prefs && user.prefs['owner']) {
          const employeeLink = await getEmployeeLink();
          if (!employeeLink || employeeLink.length === 0) {
            await createEmployeeLink(user.$id, user.$id);
          }
          const employees = await getCompanyEmployeesData();
          if (!employees || employees.length === 0) {
            await createCompanyEmployeesData(
              JSON.stringify({
                id: user.$id,
                last_name: user.name,
                first_name: '',
                email: user.email,
              }),
              [],
              user.$id
            );
          }
        }
      },
      function (error) {
        console.log('Error getting user Account ', error);
        console.log(error); // Failure
        // setRequest(false);
      }
    );
  }

  function generateRandomPassword(minLength = 8, maxLength = 14) {
    // Tableau de caractères à utiliser pour générer le mot de passe
    const chars = [
      // Lettres minuscules
      'abcdefghijklmnopqrstuvwxyz',
      // Lettres majuscules
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      // Chiffres
      '0123456789',
      // Caractères spéciaux
      '@#$%^&*()_+-={}[]:";',
    ];

    // Longueur du mot de passe
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    // Mot de passe généré
    let password = '';

    // Génération des caractères du mot de passe
    for (let i = 0; i < length; i++) {
      // Choix d'un caractère aléatoire parmi les caractères disponibles
      const index = Math.floor(Math.random() * chars.length);
      password += chars[index][Math.floor(Math.random() * chars[index].length)];
    }

    return password;
  }

  const updateEmployeePrefs = async (pref) => {
    onLoading(true);

    const baseUrl = 'https://api.shopybase.com/v1/account/prefs';
    try {
      const response = await axios.patch(
        baseUrl,
        {
          prefs: pref,
        },

        {
          headers: {
            'Content-Type': 'application/json',
            'X-Appwrite-Project': 'ai_project_dev',
            'X-Appwrite-Response-Format': '1.0.0',
            'X-Appwrite-Key': '1.0.0',
          },
        }
      );
      onLoading(false);
      return response;
    } catch (error) {
      onLoading(false);
      console.error('Error', error);
      throw error;
    }
  };

  async function createEmployeeAccount(employee) {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const password = generateRandomPassword();
    console.log('password is ', password);
    const promise = account.create(ID.unique(), employee.email, 'password#22', employee.first_name + ' ' + employee.last_name);
    return promise.then(
      async function (response) {
        console.log('userAccount is', response);
        return response;
      },
      function (error) {
        console.log(error); // Failure
        // setRequest(false);
        return null;
      }
    );
  }

  async function getEmployeeLink() {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.listDocuments('default', 'employee_link', [Query.equal('employee_id', localStorage.getItem('companyId'))]);

    return promise.then(
      async function (response) {
        console.log('Employee link is ', response.documents); // Failure
        return response.documents;
      },
      function (error) {
        onLoading(false);
        console.log('Error creating Employee link', error); // Failure
        return null;
      }
    );
  }

  async function getEmployeeLinks(the_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.listDocuments('default', 'employee_link', [Query.equal('employee_id', the_id)]);

    return promise.then(
      async function (response) {
        console.log('specific Employee links is ', response.documents); // Failure
        return response.documents;
      },
      function (error) {
        onLoading(false);
        console.log('Error getting specific Employee links', error); // Failure
        return null;
      }
    );
  }

  async function createEmployeeLink(employee_account_id, company_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'employee_link', ID.unique(), { employee_id: employee_account_id, company_id: company_id });

    return promise.then(
      async function (response) {
        console.log('Employee link created ', response); // Failure
        return response;
      },
      function (error) {
        onLoading(false);
        console.log('Error creating Employee link', error); // Failure
        return null;
      }
    );
  }

  async function deleteEmployeeLink(employee_link_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.deleteDocument('default', 'employee_link', employee_link_id);

    return promise.then(
      async function (response) {
        console.log('Employee link deleted ', response); // Failure
        return response;
      },
      function (error) {
        onLoading(false);
        console.log('Error deleting Employee link', error); // Failure
        return null;
      }
    );
  }

  async function getCompanyEmployeesData() {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.listDocuments('default', 'employee', [Query.equal('company_id', localStorage.getItem('companyId'))]);

    return promise.then(
      async function (response) {
        const employeeData = response.documents;
        if (employeeData.length > 0) {
          setCompanyEmployeesData(employeeData[0]);
          const employeesList = employeeData[0].employees;
          console.log('EmployeesList is', employeesList);
          const employees = employeesList.map((element) => JSON.parse(element));
          setEmployeesList(employees);
          if (employeeData[0].owner) {
            // console.log('owner is', employeeData[0].owner);
            setOwnerInfos(JSON.parse(employeeData[0].owner));
          }
        }
        return employeeData;
      },
      function (error) {
        console.log('Error listing company employees ', error);
        console.log(error); // Failure
        return null;
      }
    );
  }

  const getCompanyInfos = async function () {
    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.getDocument('default', 'client_infos', localStorage.getItem('companyId'));

    promise.then(
      function (response) {
        // const updatedTimesByDay = { ...initialTimesByDay };
        // console.log("general before", response.times_by_day);
        // response.times_by_day.forEach(entry => {
        //     if (entry) {
        //         let element = JSON.parse(entry);
        //         console.log("element is", element);
        //         const day = Object.keys(element)[0];
        //         console.log("day is", day);
        //         const openingTimeMatch = element[day]['open_time'];
        //         const closingTimeMatch = element[day]['close_time'];
        //         console.log("openingTimeMatch is", openingTimeMatch);
        //         console.log("closingTimeMatch is", closingTimeMatch);

        //         if (day && openingTimeMatch && closingTimeMatch) {
        //             const openingTime = parseInt(openingTimeMatch);
        //             const closingTime = parseInt(closingTimeMatch);
        //             console.log("general new", updatedTimesByDay);
        //             updatedTimesByDay[day] = {
        //                 ...updatedTimesByDay[day],
        //                 checked: true,
        //                 open: openingTime,
        //                 close: closingTime,
        //             };
        //         }
        //     }

        // });
        // console.log('setTimesByDay', updatedTimesByDay)
        // setTimesByDay(updatedTimesByDay);
        setCompanyInfos(response);
        // console.log('company info is ', response);
        const agentsList = response.agents;
        const agents = agentsList.map((element) => JSON.parse(element));
        // console.log('agents is', agents);
        setAgentsList(agents);
        // setLoading(false);
        return response;
      },
      async function (error) {
        setLoading(false);
        console.log('Error on getCompanyInfos', error, error.code);
        if (error.code === 404) {
          await createCompanyInfos({
            company: '',
            // company_website_url: site_url,
            // more_info: site_infos_more,
            // shopify_store_url: shopify_store_url,
            // shopify_api_key: shopify_api_key,
            // email: email,
            // password: password,
            // host: host,
            // port: port,
            // smtp_host: smtp_host,
            // smtp_port: smtp_port,
            // auto_response_level: auto_response_level,
            // tls: tls_mode,
            // mail_config: true,
            // server_active: true,
            // contact_reasons: selectedTagNameList,
            // agents: agentData.map((element) => JSON.stringify(element)),
            data_source: 'shopify',
          });
        }
        return null;
      }
    );
  };

  async function createCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'client_infos', localStorage.getItem('companyId'), data);

    promise.then(
      async function (response) {
        console.log(response);
        setCompanyInfos(response);
        // console.log('company info is ', response);
        const agentsList = response.agents;
        const agents = agentsList.map((element) => JSON.parse(element));
        // console.log('agents is', agents);
        setAgentsList(agents);
      },
      function (error) {
        // onLoadingChanged(false);

        console.log('createCompanyInfos', error); // Failure
      }
    );
  }

  async function createCompanyEmployeesData(owner, employees, company_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'employee', ID.unique(), {
      owner: owner,
      employees: employees.map((element) => JSON.stringify(element)),
      company_id: company_id,
    });

    return promise.then(
      async function (response) {
        console.log('Employees list created ', response); // Failure
        return response;
      },
      function (error) {
        onLoading(false);
        console.log('Error creating Employees list', error); // Failure
        return null;
      }
    );
  }

  async function updateCompanyEmployeesData(id, data) {
    const client = new Client();
    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.updateDocument('default', 'employee', id, data);

    return promise.then(
      async function (response) {
        onLoading(false);
        console.log('Employees list created ', response); // Failure
        return response;
      },
      function (error) {
        onLoading(false);
        console.log('Error updating Employees list', error); // Failure
        return null;
      }
    );
  }

  async function updateCompanyInfos(data) {
    const client = new Client();

    const databases = new Databases(client, 'default');

    console.log('updateCompanyInfos', JSON.stringify(data));
    console.log('old', data);
    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.updateDocument('default', 'client_infos', localStorage.getItem('companyId'), JSON.stringify(data));

    return promise.then(
      async function (response) {
        console.log('AgentUpdate', response); // Success
        const agentsList = response.agents;
        return agentsList;
      },
      function (error) {
        console.log('AgentUpdate', error); // Failure
        return null;
      }
    );
  }

  const saveUserPrefsActions = () => {
    const new_prefs = userPrefs;
    setUpdateUserPrefsLoading({ ...updateUserPrefsLoading, loading: true });
    new_prefs['first_name'] = userDetails.first_name !== '' ? userDetails.first_name : new_prefs['first_name'];
    new_prefs['last_name'] = userDetails.last_name !== '' ? userDetails.last_name : new_prefs['last_name'];
    new_prefs['gender'] = userDetails.gender !== '' ? userDetails.gender : new_prefs['gender'];
    new_prefs['address'] = userDetails.address !== '' ? userDetails.address : new_prefs['address'];
    console.log('new_prefs is', new_prefs);
    updateUserPrefs(new_prefs);
  };

  useEffect(() => {
    getCompanyEmployeesData();
    getCompanyInfos();
    setSelectedTimezone(setUtc(moment.tz.guess(true)));
    setAgentTimezone(setUtc(moment.tz.guess(true)));
    getUserPrefs();
    getUserAccount();
  }, []);

  const [isAtive, setIsActive] = useState(false);

  const agentStatusChangeActions = async (index, old_val) => {
    setDeleteAgentLoading((prevState) => ({
      ...prevState,
      [agentsList[index].id]: true,
    }));

    let applyed;
    if (old_val === false) {
      applyed = await startAgent(agentsList[index].id);
    } else {
      applyed = await stopAgent(agentsList[index].id);
    }
    if (applyed) {
      const updatedAgentList = [...agentsList];
      updatedAgentList[index] = { ...updatedAgentList[index], active: !old_val };
      console.log('updatedAgentList is', updatedAgentList);
      await updateCompanyInfos({
        agents: updatedAgentList.map((element) => JSON.stringify(element)),
      });
      setAgentsList(updatedAgentList);
      setIsActive((prev) => {
        return !prev;
      });
    }
    setDeleteAgentLoading((prevState) => ({
      ...prevState,
      [agentsList[index].id]: false,
    }));
  };

  const svgs = {
    plus_icon: (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.01667 13.7836C6.62474 13.7836 6.32189 13.6767 6.10811 13.4629C5.89433 13.2313 5.78744 12.9195 5.78744 12.5276V8.14517H1.48517C1.11106 8.14517 0.817111 8.04719 0.603333 7.85123C0.389556 7.63745 0.282667 7.34351 0.282667 6.96939C0.282667 6.59528 0.389556 6.31025 0.603333 6.11428C0.817111 5.9005 1.11106 5.79362 1.48517 5.79362H5.78744V1.54478C5.78744 1.15286 5.89433 0.850005 6.10811 0.636227C6.32189 0.422449 6.63365 0.315561 7.04339 0.315561C7.43532 0.315561 7.72926 0.422449 7.92522 0.636227C8.139 0.850005 8.24589 1.15286 8.24589 1.54478V5.79362H12.5482C12.9401 5.79362 13.234 5.9005 13.43 6.11428C13.6438 6.31025 13.7507 6.59528 13.7507 6.96939C13.7507 7.34351 13.6438 7.63745 13.43 7.85123C13.234 8.04719 12.9401 8.14517 12.5482 8.14517H8.24589V12.5276C8.24589 12.9195 8.139 13.2313 7.92522 13.4629C7.72926 13.6767 7.42641 13.7836 7.01667 13.7836Z"
          fill="currentColor"
        />
      </svg>
    ),
    edit_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
          d="M0 15.6448V18.9334C0 19.2363 0.237992 19.4743 0.54089 19.4743H3.8295C3.97013 19.4743 4.11077 19.4202 4.20813 19.312L16.0212 7.50978L11.9645 3.4531L0.162267 15.2553C0.054089 15.3635 0 15.4933 0 15.6448ZM19.1535 4.37308C19.5754 3.95119 19.5754 3.26967 19.1535 2.84777L16.6221 0.316405C16.42 0.113839 16.1456 0 15.8594 0C15.5733 0 15.2989 0.113839 15.0968 0.316405L13.1171 2.29606L17.1738 6.35274L19.1535 4.37308Z"
          fill="#329993"
        />
      </svg>
    ),
    delete_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        <path
          d="M23.4933 10.5732L22.595 24.3845C22.5409 25.2252 22.1809 26.0133 21.5875 26.5897C20.9942 27.1661 20.2118 27.4878 19.3983 27.4899H8.595C7.78152 27.4878 6.99912 27.1661 6.40579 26.5897C5.81246 26.0133 5.45241 25.2252 5.39833 24.3845L4.5 10.5732H23.4933ZM17.6273 6.94824H10.366C10.0426 6.94811 9.72377 6.86843 9.43587 6.71576C9.14796 6.5631 8.89918 6.34181 8.70997 6.07011C8.52077 5.79841 8.39656 5.48406 8.34757 5.15294C8.29858 4.82181 8.3262 4.48336 8.42817 4.16545L8.819 2.95591C8.95372 2.53494 9.21316 2.16868 9.56044 1.90923C9.90771 1.64977 10.3251 1.51033 10.7533 1.51074H17.2377C17.6663 1.51028 18.0842 1.6498 18.4319 1.90947C18.7796 2.16913 19.0394 2.53573 19.1743 2.95712L19.5628 4.16424C19.6653 4.48213 19.6933 4.82068 19.6446 5.15199C19.5959 5.4833 19.4718 5.79787 19.2827 6.06977C19.0935 6.34167 18.8447 6.56312 18.5567 6.71586C18.2687 6.86859 17.9497 6.94824 17.6262 6.94824H17.6273ZM10.7545 3.32324C10.6934 3.32284 10.6337 3.3425 10.584 3.3794C10.5343 3.41631 10.4972 3.46856 10.478 3.52866L10.0883 4.7382C10.0737 4.78378 10.0697 4.83233 10.0768 4.87981C10.0839 4.92729 10.1018 4.97234 10.1291 5.01121C10.1563 5.05008 10.1922 5.08166 10.2336 5.10332C10.275 5.12498 10.3208 5.13609 10.3672 5.13574H17.6285C17.6749 5.13596 17.7207 5.1247 17.7621 5.1029C17.8035 5.08109 17.8392 5.04937 17.8664 5.01037C17.8936 4.97137 17.9113 4.92622 17.9182 4.87867C17.9252 4.83112 17.921 4.78255 17.9062 4.73699L17.5177 3.52987C17.4983 3.46954 17.461 3.4171 17.4111 3.38C17.3613 3.3429 17.3014 3.32304 17.24 3.32324H10.7545Z"
          fill="#F10944"
        />
        <path
          d="M11.6641 20.2396C11.432 20.2396 11.2094 20.1441 11.0453 19.9741C10.8813 19.8042 10.7891 19.5737 10.7891 19.3333V14.5C10.7891 14.2596 10.8813 14.0291 11.0453 13.8592C11.2094 13.6892 11.432 13.5938 11.6641 13.5938C11.8961 13.5938 12.1187 13.6892 12.2828 13.8592C12.4469 14.0291 12.5391 14.2596 12.5391 14.5V19.3333C12.5391 19.5737 12.4469 19.8042 12.2828 19.9741C12.1187 20.1441 11.8961 20.2396 11.6641 20.2396ZM16.3307 20.2396C16.0987 20.2396 15.8761 20.1441 15.712 19.9741C15.5479 19.8042 15.4557 19.5737 15.4557 19.3333V14.5C15.4557 14.2596 15.5479 14.0291 15.712 13.8592C15.8761 13.6892 16.0987 13.5938 16.3307 13.5938C16.5628 13.5938 16.7854 13.6892 16.9494 13.8592C17.1135 14.0291 17.2057 14.2596 17.2057 14.5V19.3333C17.2057 19.5737 17.1135 19.8042 16.9494 19.9741C16.7854 20.1441 16.5628 20.2396 16.3307 20.2396Z"
          fill="#EDEBEA"
        />
        <path
          d="M23.3333 5.13574H4.66667C4.12518 5.13574 3.60588 5.35853 3.22299 5.75509C2.8401 6.15165 2.625 6.6895 2.625 7.25033C2.625 7.81115 2.8401 8.349 3.22299 8.74556C3.60588 9.14212 4.12518 9.36491 4.66667 9.36491H23.3333C23.8748 9.36491 24.3941 9.14212 24.777 8.74556C25.1599 8.349 25.375 7.81115 25.375 7.25033C25.375 6.6895 25.1599 6.15165 24.777 5.75509C24.3941 5.35853 23.8748 5.13574 23.3333 5.13574Z"
          fill="#F10944"
        />
      </svg>
    ),
    close_icon: (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.37 19.6142C19.0771 19.8983 18.778 20.038 18.4725 20.0334C18.1504 20.0115 17.8473 19.8542 17.5632 19.5613L12.9511 14.8068L8.27403 19.3437C7.98118 19.6278 7.68204 19.7675 7.3766 19.7629C7.07117 19.7582 6.78476 19.6181 6.5174 19.3425C6.25003 19.0668 6.11866 18.7763 6.12331 18.4709C6.12795 18.1654 6.2767 17.8707 6.56955 17.5866L11.2466 13.0497L6.78484 8.45016C6.48405 8.14008 6.33598 7.83232 6.34062 7.52688C6.34527 7.22145 6.50263 6.91834 6.81271 6.61755C7.10556 6.33347 7.39609 6.2021 7.6843 6.22346C7.98973 6.2281 8.29284 6.38546 8.59364 6.69554L13.0554 11.295L17.7324 6.75812C18.0252 6.47404 18.3244 6.33432 18.6298 6.33896C18.9353 6.34361 19.2217 6.48374 19.489 6.75937C19.7564 7.03499 19.8878 7.32552 19.8831 7.63096C19.8785 7.93639 19.7297 8.23115 19.4369 8.51523L14.7598 13.0522L19.372 17.8067C19.6561 18.0996 19.8041 18.4073 19.8162 18.73C19.8288 19.0187 19.68 19.3135 19.37 19.6142Z"
          fill="currentColor"
        />
      </svg>
    ),
    play_icon: (
      <svg width="44px" height="44px" viewBox="0 0 24 24" fill="#267c26" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" style={{ strokeWidth: '0' }}></g>
        <g id="SVGRepo_tracerCarrier" style={{ strokeLinecap: 'round', strokeLinejoin: 'round' }}></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              stroke: 'currentColor',
              strokeWidth: '2',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M10.715 6.36694L16.4 10.6639C16.7719 10.9289 16.9927 11.3573 16.9927 11.8139C16.9927 12.2706 16.7719 12.699 16.4 12.9639L10.71 17.6639C10.2297 18.0453 9.5794 18.1339 9.01458 17.8948C8.44975 17.6558 8.06062 17.1273 8.00003 16.5169V7.51694C8.05845 6.90422 8.44802 6.37281 9.01478 6.13275C9.58154 5.89269 10.2343 5.9826 10.715 6.36694Z"></path>{' '}
        </g>
      </svg>
    ),
    pause_icon: (
      <svg width="44px" height="44px" viewBox="0 0 24 24" fill="red" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" style={{ strokeWidth: '0' }}></g>
        <g id="SVGRepo_tracerCarrier" style={{ strokeLinecap: 'round', strokeLinejoin: 'round' }}></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              stroke: 'currentColor',
              strokeWidth: '0',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M7.00002 9.125V14.875C6.99816 15.1715 7.11417 15.4566 7.32252 15.6676C7.53087 15.8786 7.81451 15.9981 8.11102 16H9.22202C9.51854 15.9981 9.80217 15.8786 10.0105 15.6676C10.2189 15.4566 10.3349 15.1715 10.333 14.875V9.125C10.3349 8.82849 10.2189 8.54338 10.0105 8.3324C9.80217 8.12142 9.51854 8.00185 9.22202 8H8.11102C7.81451 8.00185 7.53087 8.12142 7.32252 8.3324C7.11417 8.54338 6.99816 8.82849 7.00002 9.125Z"></path>{' '}
          <path
            d="M13.667 9.125V14.875C13.6652 15.1715 13.7812 15.4566 13.9895 15.6676C14.1979 15.8786 14.4815 15.9981 14.778 16H15.889C16.1855 15.9981 16.4692 15.8786 16.6775 15.6676C16.8859 15.4566 17.0019 15.1715 17 14.875V9.125C17.0019 8.82849 16.8859 8.54338 16.6775 8.3324C16.4692 8.12142 16.1855 8.00185 15.889 8H14.778C14.4815 8.00185 14.1979 8.12142 13.9895 8.3324C13.7812 8.54338 13.6652 8.82849 13.667 9.125V9.125Z"
            stroke="currentColor"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeWidth: '0', strokeLinecap: 'round', strokeLinejoin: 'round' }}></path>{' '}
        </g>
      </svg>
    ),
  };

  return (
    <React.Fragment>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '1005' }}>
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>{updateUserPrefsLoading.title}</ToastHeader>
          <ToastBody color="primary">{updateUserPrefsLoading.message}</ToastBody>
        </Toast>
      </div>

      {/* Phone Number Modal */}
      <Modal isOpen={updatePhoneModal} toggle={tog_phone_modal} centered className="help-page-modal">
        <ModalBody>
          <Button
            className="close_button"
            style={{ backgroundColor: 'transparent', border: 'none', padding: '0px', float: 'right' }}
            onClick={tog_phone_modal}>
            {svgs.close_icon}
          </Button>
          <h1 className="modal_title" style={{ marginTop: '8%', marginBottom: '6%' }}>
            {userPrefs.telephone ? 'Entrez votre nouveau numéro de téléphone' : 'Ajoutez votre numéro de téléphone'}
          </h1>
          <Row className="gap-4 mb-5" style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            <Form
              className="form-horizontal col-10 mx-auto"
              onSubmit={(e) => {
                e.preventDefault();
                onRequestIsLoading(true);
                updateUserPhone(userDetails.telephone, password);
                return false;
              }}>
              <div>
                <label className="form-label">Telephone</label>
                <div className="form-control p-0">
                  <IntlTelInput
                    containerClassName="intl-tel-input col-xxl-12"
                    inputClassName="form-control"
                    formatOnInit={false}
                    value={userDetails.telephone}
                    onPhoneNumberChange={(status, phoneNumber, country) => {
                      setUserDetails({ ...userDetails, telephone: phoneNumber.replace(/ /g, '') });
                    }}
                  />
                </div>
              </div>
              <Label className="form-label mt-4">Confirmer Mot de Passe</Label>
              <Input
                name="password"
                value={password}
                type="password"
                placeholder="Entrer Mot de passe"
                onChange={(e) => onPasswordChange(e.target.value)}
                // onClick={(e) => setValidation({ ...validation, touched: true })}
                // invalid={
                //   validation.touched && validation.errors ? true
                //     : false
                // }
              />
              {/* {validation.touched && validation.errors ? (
                <FormFeedback type="invalid"><div> {validation.errors} </div></FormFeedback>
              ) : null} */}
              <button
                type="submit"
                className="inputCustom__Button mt-5"
                style={{ border: 'none' }}
                // onClick={}
              >
                {requestIsLoading ? 'Chargement...' : <> {userPrefs.telephone ? 'Modifier' : <>{svgs.plus_icon} Ajouter</>}</>}
              </button>
            </Form>
          </Row>
        </ModalBody>
        <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '1005' }}>
          <Toast isOpen={validation.errors}>
            <ToastHeader toggle={togglePhoneToast}>{/* {validation.errors} */} Echec</ToastHeader>
            <ToastBody color="primary">{validation.errors}</ToastBody>
          </Toast>
        </div>
      </Modal>

      <div className="page-content settings-content" style={{ paddingLeft: '2em' }}>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{ paddingLeft: '0', paddingRight: '0' }}>
            <h4 className="page-title">Paramètres</h4>
          </div>
        </Col>
        <Col xl={12}>
          {/* Nav Bar */}
          <Row>
            <Col md="12">
              <Card
                className="settings-menu"
                style={{ backgroundColor: 'transparent', boxShadow: 'none', alignContent: 'center', alignItems: 'center' }}>
                <CardBody>
                  <Nav pills className="flex-row" style={{ height: 'fit-content', width: 'fit-content' }}>
                    <NavItem>
                      <NavLink
                        id="v-pills-left-home-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '1',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('1');
                        }}>
                        <i style={{ fontSize: '16px' }} className="mdi mdi-cog-outline me-2 align-middle">
                          {' '}
                        </i>{' '}
                        Général
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="v-pills-left-home-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '2',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('2');
                        }}>
                        <i className="dripicons-mail me-2 align-middle"> </i> E-mail
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        id="v-pills-left-profile-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '3',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('3');
                        }}>
                        <i className="mdi mdi-chat me-2 align-middle"></i> Chat
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        id="v-pills-left-messages-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '4',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('4');
                        }}>
                        <i className="dripicons-user me-2 align-middle"></i> Profil
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        id="v-pills-left-setting-tab"
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === "5",
                        })}
                        onClick={() => {
                          toggleVerticalIcon("5");
                        }}
                      >
                        <i className="fa fa-fw fa-database" />{" "}
                        Ressources
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        id="v-pills-left-setting-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '5',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('5');
                        }}>
                        <i className="fa fa-fw fa-robot me-2 align-middle" /> Agents
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="v-pills-left-setting-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '6',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('6');
                        }}>
                        <i className="dripicons-user-group me-2 align-middle"></i> Utilisateurs
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        id="v-pills-left-setting-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '7',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('7');
                        }}>
                        <i className="dripicons-wallet me-2 align-middle"></i> Facturation
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        id="v-pills-left-setting-tab"
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: verticalActiveTabWithIcon === '8',
                        })}
                        onClick={() => {
                          toggleVerticalIcon('8');
                        }}>
                        <i className="fa fa-fw fa-bell me-2 align-middle" /> Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Content */}
          <Row>
            <Col md="12" style={{}}>
              <TabContent activeTab={verticalActiveTabWithIcon} className="text-muted mt-4 mt-md-0">
                <TabPane tabId="1">
                  <General></General>
                </TabPane>
                <TabPane tabId="2">
                  <SettingEmailConfig></SettingEmailConfig>
                </TabPane>
                {/* <TabPane tabId="3">
                  <p> Chat</p>
                </TabPane> */}

                <TabPane tabId="4" className="profile" style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                  <Row className="align-items-center justify-content-between flex-row mb-3">
                    <div style={{ width: 'fit-content' }}>
                      <h4 className="section-title mb-0">Profil</h4>
                    </div>
                  </Row>
                  <div className="inter-ligne"></div>
                  <Row>
                    <Col xl={2}>
                      <h6 className="inner_section_title_1">Détails</h6>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          {/* <div className="d-flex">
                            <div style={{ backgroundColor: '#f7d3dd', color: '#811b47', padding: '8px 10px', borderRadius: '10px' }}>AA</div>
                            <div type="button"  className="btn-orange" style={{ color: '#313333', padding: '8px 10px', borderRadius: '10px', marginLeft: '15px', border: '1px solid #c9cccf' }}>Importer une photo</div>
                          </div> */}
                          {/* <div className="inter-ligne"></div> */}
                          <Row>
                            <Col xl={6}>
                              <div>
                                <label className="form-label">Prénom</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Prénom"
                                  value={userDetails.first_name}
                                  onChange={(e) => {
                                    setUserDetails({ ...userDetails, first_name: e.target.value });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div>
                                <label className="form-label">Nom de famille</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Nom de famille"
                                  value={userDetails.last_name}
                                  onChange={(e) => {
                                    setUserDetails({ ...userDetails, last_name: e.target.value });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-3 mb-3 simple-text font-primary">
                            Saisissez votre prénom et votre nom tels qu'ils apparaissent sur votre pièce d'identité officielle.
                          </div>
                          <Row>
                            <Col xl={6}>
                              <div>
                                <label className="form-label">Genre</label>
                                {/* <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Genre"
                                  value={userDetails.gender}
                                  onChange={(e)=> {setUserDetails({...userDetails, gender: e.target.value})}}
                                /> */}
                                <Dropdown isOpen={singlebtn} toggle={() => setSinglebtn(!singlebtn)} className="">
                                  <DropdownToggle className="form-control d-flex align-items-center" tag="button" id="">
                                    {userDetails.gender}
                                  </DropdownToggle>

                                  <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-start">
                                    <DropdownItem
                                      onClick={() => {
                                        setUserDetails({ ...userDetails, gender: 'Homme' });
                                      }}>
                                      Homme
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setUserDetails({ ...userDetails, gender: 'Femme' });
                                      }}>
                                      Femme
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setUserDetails({ ...userDetails, gender: 'Autre' });
                                      }}>
                                      Autre
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </Col>
                            <Col xl={6}>
                              {/* <div>
                                <label className="form-label">Telephone</label>
                                <IntlTelInput
                                    containerClassName="intl-tel-input col-xxl-12"
                                    inputClassName="form-control"
                                    value={userDetails.telephone}
                                    onPhoneNumberChange={(status, phoneNumber, country)=> {setUserDetails({...userDetails, telephone: phoneNumber})}}
                                  />
                              </div> */}
                              <div>
                                <label className="form-label">Adresse</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Entrez votre adresse"
                                  value={userDetails.address}
                                  onChange={(e) => {
                                    setUserDetails({ ...userDetails, address: e.target.value });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3 mb-1">
                            <Col xl={12}>
                              {/* <div>
                                <label className="form-label">Adresse</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Entrez votre adresse"
                                  value={userDetails.address}
                                  onChange={(e)=> {setUserDetails({...userDetails, address: e.target.value})}}
                                />
                              </div> */}
                            </Col>
                          </Row>

                          <div className="mt-3 mb-1 w-100 d-flex justify-content-end">
                            <button
                              onClick={(e) => {
                                saveUserPrefsActions();
                              }}
                              style={{ padding: '8px 10px', width: 'fit-content' }}
                              className="btn-orange simple-text font-primary">
                              {updateUserPrefsLoading.loading ? 'Enregistrement...' : 'Enregistrer'}
                            </button>
                          </div>

                          <div className="inter-ligne"></div>

                          <Row>
                            <Col className="simple-text font-tertiary inner_section_title_2" xl={6}>
                              E-mail
                              <h6 className="mt-2 simple-text font-primary">{userInfos && userInfos.email}</h6>
                            </Col>
                            <Col
                              xl={3}
                              style={{ color: userInfos && userInfos.emailVerification ? '#367d61' : '#F10944' }}
                              className="d-flex align-items-center simple-text font-primary">
                              {userInfos && userInfos.emailVerification ? (
                                <>
                                  <i className="mdi mdi-check-circle me-2" style={{ fontSize: '25px' }} /> Vérifié
                                </>
                              ) : (
                                <>
                                  <i className="mdi mdi-close-circle me-2" style={{ fontSize: '25px' }} /> Non Vérifié
                                </>
                              )}
                            </Col>
                            <Col xl={3} className="simple-text action-div" style={{ color: '#5D5FEF', cursor: 'pointer' }}>
                              Vérifier
                            </Col>
                          </Row>
                          <div className="inter-ligne"></div>
                          <Row>
                            <Col className="simple-text font-tertiary inner_section_title_2" xl={8}>
                              Numéro de téléphone (facultatif)
                              <h6 className="mt-2 simple-text font-primary">
                                {' '}
                                {userPrefs.telephone ? userPrefs.telephone : 'Aucun numéro de téléphone'}
                              </h6>
                            </Col>
                            <Col xl={4} className="simple-text action-div" style={{ color: '#5D5FEF', cursor: 'pointer' }} onClick={tog_phone_modal}>
                              {userPrefs.telephone ? 'Modifier' : 'Ajouter'}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="mt-3 inner_section_title_1">Service de connexion connecté</h6>
                      <p className="simple-text font-primary">Vous n'avez aucun service de connexion externe associé à votre identifiant Shopify.</p>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <h6 className="inner_section_title_2">Services de connexion connecté</h6>
                          <p className="mt-3 simple-text font-primary">
                            Vous n'avez aucun service de connexion externe associé à votre identifiant Shopify.
                          </p>
                          <div className="inter-ligne"></div>
                          <div style={{ color: '#5D5FEF', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <div className="">
                              <i className="mdi mdi-apple me-2" style={{ fontSize: '25px', color: 'black' }} />
                            </div>
                            Se connecter à Apple
                          </div>
                          <div className="inter-ligne"></div>
                          <div style={{ color: '#5D5FEF', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <div className="">
                              <i className="mdi mdi-facebook me-2" style={{ fontSize: '25px' }} />
                            </div>
                            Se connecter à Facebook
                          </div>
                          <div className="inter-ligne"></div>
                          <div style={{ color: '#5D5FEF', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <div className="">
                              <i className="mdi mdi-google me-2" style={{ fontSize: '25px' }} />
                            </div>
                            Se connecter à Google
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="mt-3 inner_section_title_1">Langue préférée</h6>
                      <p className="simple-text font-primary">
                        Il s'agit de la langue que vous verrez lorsque vous vous connecterez à Shopify. Cela n'affecte pas la langue que les clients
                        voient dans votre boutique en ligne.
                      </p>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12}>
                              <label className="form-label">Langue</label>
                              <Dropdown isOpen={btnmenu1} toggle={() => setbtnmenu1(!btnmenu1)} className="">
                                <DropdownToggle tag="button" className="btn btn-line">
                                  {selectedOption} <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg-end">
                                  <DropdownItem onClick={() => handleDropdownItemClick('Anglais')}>Anglais</DropdownItem>
                                  <DropdownItem onClick={() => handleDropdownItemClick('Français')}>Français</DropdownItem>
                                  <DropdownItem onClick={() => handleDropdownItemClick('Espagnol')}>Espagnol</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>{' '}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="mt-3 inner_section_title_1">Fuseau horaire</h6>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <h6 className="inner_section_title_2">Fuseau horaire</h6>
                          <Col md={7}>
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="floatingSelectGrid"
                                aria-label="Floating label select example"
                                value={selectedTimezone}
                                onChange={(e) => setSelectedTimezone(setUtc(e.target.value))}>
                                <option value={selectedTimezone}>{selectedTimezone}</option>
                                {timezones.map((timezone) => (
                                  <option key={timezone} value={timezone}>
                                    {setUtc(timezone)}
                                  </option>
                                ))}
                              </select>
                              <label htmlFor="floatingSelectGrid">
                                <p>Fuseau sélectionné: {selectedTimezone}</p>
                              </label>
                            </div>
                          </Col>
                          <p className="simple-text font-primary">
                            Ceci est le fuseau horaire de votre compte. Pour définir le fuseau horaire de l'interface administrateur Shopify, accédez
                            à la section « Menu général » dans Paramètres.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="mt-3 inner_section_title_1">Mot de passe</h6>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <h6 className="inner_section_title_2">Vous n'avez jamais modifié votre mot de passe.</h6>
                          <div
                            className="mt-4 btn-orange simple-text font-primary"
                            type="button"
                            style={{
                              color: '#313333',
                              padding: '8px 10px',
                              borderRadius: '10px',
                              border: '1px solid #c9cccf',
                              width: 'fit-content',
                            }}>
                            {' '}
                            Modifier votre mot de passe{' '}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="mt-3 inner_section_title_1">Authentification en deux étapes</h6>
                      <p className="simple-text font-primary">En savoir plus sur l'authentification en deux étapes</p>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <h6 className="inner_section_title_2">Méthodes d'authentification O Désactivé</h6>
                          <div className="simple-text font-primary">
                            Après avoir entré votre mot de passe, vérifiez votre identité avec une seconde méthode d'authentification.
                          </div>
                          <div className="inter-ligne"></div>

                          <Row>
                            <Col xl={1} className="d-flex align-items-center">
                              <i className="mdi mdi-security me-2" style={{ fontSize: '30px' }} />
                            </Col>
                            <Col xl={11} className="simple-text font-primary">
                              L'authentification en deux étapes sécurise encore mieux votre compte en rajoutant une étape à celle de votre mot de
                              passe pour vous connecter.
                            </Col>
                          </Row>
                          <h6 className="mt-4 font-primary">COMMENT ÇA MARCHE?</h6>
                          <div className="simple-text font-primary">
                            Lorsque vous vous connectez à Shopify, vous devez : <br />
                            1. Entrer votre adresse e-mail et votre mot de passe <br />
                            2. Suivre une seconde étape pour vérifier qu'il s'agit bien de vous. Vous pouvez entrer un code de vérification, utiliser
                            une clé de sécurité ou confirmer votre connexion depuis un appareil vérifié.
                          </div>
                          <div
                            style={{ color: 'white', padding: '8px 10px', borderRadius: '10px', width: 'fit-content', backgroundColor: '#303030' }}
                            className="mt-4 btn-orange simple-text font-primary"
                            type="button">
                            Activer les deux étapes
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="inter-ligne"></div>

                  <Row>
                    <Col xl={2}>
                      <h6 className="inner_section_title_1">Appareils</h6>
                      <p className="simple-text font-primary">
                        Vous êtes actuellement connecté(e) à Shopify sur ces appareils. Si vous ne reconnaissez pas un appareil, déconnectez-vous pour
                        maintenir la sécurité de votre compte.
                      </p>
                    </Col>
                    <Col xl={10}>
                      <Card>
                        <CardBody>
                          <h6 className="inner_section_title_2">Connecté(e)</h6>
                          <div className="inter-ligne"></div>

                          <Row>
                            <Col xl={8}>
                              <div className="d-flex">
                                <div className="me-3">
                                  {' '}
                                  <img src={monitor} style={{ width: '20px' }} />
                                </div>
                                <div className="me-3 simple-text font-primary" style={{ color: '#3c6fc4', cursor: 'pointer' }}>
                                  Firefox sur macos
                                </div>
                                <div
                                  style={{ backgroundColor: '#b2e6f0', cursor: 'pointer', borderRadius: '20px', padding: '2px 8px', color: 'black' }}>
                                  Cet appareil
                                </div>
                              </div>
                              <div className="mt-3 simple-text font-primary">
                                4 sept. 16:02 <br />
                                Abomey (Zou Department), Bénin
                              </div>
                            </Col>
                            <Col xl={4} className="d-flex align-items-center">
                              <div
                                type="button"
                                className="simple-text font-primary"
                                style={{
                                  color: '#fff',
                                  fontWeight: '600',
                                  backgroundColor: '#F10944',
                                  padding: '8px 10px',
                                  borderRadius: '10px',
                                  boxShadow: 'rgba(255, 73, 73, 0.2) 0px 14px 36px 0px',
                                }}>
                                Déconnexion
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                {/* <TabPane tabId="5">
                  <Ressources></Ressources>
                </TabPane> */}

                {/* Utilisateurs & Aautorisations */}
                <TabPane tabId="6" style={{ color: 'black', backgroundColor: 'inherit', paddingLeft: '8%', paddingRight: '8%' }}>
                  {/* UPDATE EMPLOYEE MODAL */}
                  <Modal isOpen={updateEmployeeModal} toggle={tog_updateEmployeeModal} centered className="user-modal" style={{ maxWidth: '40%' }}>
                    <ModalBody>
                      <Button
                        className="close_button"
                        style={{ backgroundColor: 'transparent', border: 'none', padding: '0px', float: 'right' }}
                        onClick={() => {
                          tog_updateEmployeeModal();
                        }}>
                        {svgs.close_icon}
                      </Button>
                      <h1 className="modal_title" style={{ marginTop: '4%', marginBottom: '4%' }}>
                        Modifier <span style={{ textTransform: 'none' }}>un utilisateur</span>
                      </h1>
                      <Row className="mb-1 pb-4" style={{ marginLeft: '4%', marginRight: '4%', borderBottom: '1px solid #CDCDCD' }}>
                        <Col xl={6}>
                          <Label for="first-name">Prénom(s)</Label>
                          <Input
                            id="first-name"
                            className="form-control font-primary simple-text"
                            value={employeeToUpdate?.first_name}
                            onChange={(e) => {
                              setEmployeeToUpdate({ ...employeeToUpdate, first_name: e.target.value });
                            }}></Input>
                        </Col>
                        <Col xl={6}>
                          <Label for="last-name">Nom</Label>
                          <Input
                            id="last-name"
                            className="form-control font-primary simple-text"
                            value={employeeToUpdate?.last_name}
                            onChange={(e) => {
                              setEmployeeToUpdate({ ...employeeToUpdate, last_name: e.target.value });
                            }}></Input>
                        </Col>
                        <p className="font-primary simple-text mt-3">
                          Saisissez le prénom et le nom de l'employé(e) tels qu'ils apparaissent sur sa pièce d'identité officielle.{' '}
                        </p>
                        <Col xl={12}>
                          <Label for="">Adresse e-mail</Label>
                          <Input
                            className="form-control font-primary simple-text"
                            value={employeeToUpdate?.email}
                            onChange={(e) => {
                              setEmployeeToUpdate({ ...employeeToUpdate, email: e.target.value });
                            }}></Input>
                        </Col>
                      </Row>
                      <Row className="py-4" style={{ marginLeft: '4%', marginRight: '4%' }}>
                        <h6 className="form-label font-tertiary">Autorisations de boutique</h6>
                        <p className="font-primary simple-text mb-0">
                          Gérez les autorisations d'employé. Certaines cases à cocher sont désactivées, car vous n'avez pas l'autorisation de les
                          changer.
                        </p>
                        <div className="row g-4 mb-3">
                          <div className="">
                            <div className="search-box ms-2">
                              <input type="text" className="form-control font-primary simple-text search" placeholder="Search..." />
                              <i className="ri-search-line search-icon mt-4"></i>
                            </div>
                          </div>
                        </div>
                        <Row className="mt-4 mb-4">
                          <Col xl={12}>
                            <label className="font-tertiary" style={{ color: 'black', cursor: 'pointer' }}>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={authorizations[0].status}
                                onChange={(e) => {
                                  let updatedAuthorizations = [...authorizations];
                                  updatedAuthorizations[0].status = e.target.checked;
                                  setAuthorizations(updatedAuthorizations);
                                  handleParentCheckboxChange(authorizations[0].id, e.target.checked);
                                }}
                              />
                              {authorizations[0].text}
                            </label>
                          </Col>
                        </Row>
                        <div className="accordion accordion-boutique" id="accordionFlushExample">
                          {authorizations.map(
                            (authorization, idx) =>
                              authorization.parents.length === 1 && (
                                <div key={idx} className="accordion-item">
                                  <h2 className="accordion-header d-flex" id={'flush-heading' + idx}>
                                    <input
                                      className="me-2"
                                      type="checkbox"
                                      checked={authorizations[idx].status}
                                      onChange={(e) => {
                                        let updatedAuthorizations = [...authorizations];
                                        updatedAuthorizations[idx].status = !updatedAuthorizations[idx].status;
                                        setAuthorizations(updatedAuthorizations);
                                        handleParentCheckboxChange(authorization.id, e.target.checked, authorization.id);
                                      }}
                                    />
                                    <button
                                      className="accordion-button collapsed accordion-new-button mt-2"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={'#flush-collapse' + idx}
                                      aria-expanded="false"
                                      aria-controls={'flush-collapse' + idx}>
                                      <label className="font-tertiary">{authorization.text}</label>
                                    </button>
                                  </h2>
                                  <div
                                    id={'flush-collapse' + idx}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={'flush-heading' + idx}
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                      <div style={{ marginLeft: '30px' }}>
                                        <ul className="checkbox-list">
                                          {authorizations.map(
                                            (authorizationChild, idx) =>
                                              authorizationChild.parents[1] === authorization.id && (
                                                <li key={idx}>
                                                  <label className="font-primary simple-text">
                                                    <input
                                                      className="me-2"
                                                      type="checkbox"
                                                      checked={authorizations[idx].status}
                                                      onChange={(e) => {
                                                        let updatedAuthorizations = [...authorizations];
                                                        updatedAuthorizations[idx].status = !updatedAuthorizations[idx].status;
                                                        setAuthorizations(updatedAuthorizations);
                                                        handleChildCheckboxChange(
                                                          authorizationChild.id,
                                                          authorizationChild.parents[1],
                                                          e.target.checked
                                                        );
                                                      }}
                                                    />
                                                    {authorizationChild.text}
                                                  </label>
                                                </li>
                                              )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </Row>
                      <Row className="py-4" style={{ marginLeft: '4%', marginRight: '4%' }}>
                        <h6 className="font-tertiary font-size-16">Notifications</h6>
                        <p className="font-primary simple-text">
                          Gérez les notifications d'employé. Certaines cases à cocher sont désactivées, car vous n'avez pas l'autorisation de les
                          changer.
                        </p>
                        <Row className="mt-4 mb-4">
                          <Col xl={12}>
                            <label className="font-tertiary" style={{ color: 'black', cursor: 'pointer' }}>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={allCkecked}
                                onChange={(e) => {
                                  setAllChecked(e.target.checked);
                                  handleNotifAllCheck(e.target.checked);
                                }}
                              />
                              Sélectionner toutes les notifications
                            </label>
                          </Col>
                        </Row>
                        <div className="accordion accordion-boutique" id="accordionFlushExample">
                          {notificationData.map((data, idx) => (
                            <div key={idx} className="accordion-item">
                              <h2 className="accordion-header d-flex" id={'flush-heading' + idx}>
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  checked={notifications[data.key]}
                                  onChange={(e) => {
                                    let updatedNotifications = { ...notifications };
                                    updatedNotifications[data.key] = e.target.checked;
                                    setNotifications(updatedNotifications);
                                    handleNotifCheckChange(data.key, e.target.checked);
                                  }}
                                />
                                <button
                                  className="accordion-button collapsed accordion-new-button mt-2"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={'#flush-collapse' + idx}
                                  aria-expanded="false"
                                  aria-controls={'flush-collapse' + idx}>
                                  <label className="font-tertiary">{data.title}</label>
                                </button>
                              </h2>
                              <div
                                id={'flush-collapse' + idx}
                                className="accordion-collapse collapse"
                                aria-labelledby={'flush-heading' + idx}
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                  <div style={{ marginLeft: '10px' }}>
                                    <ul className="checkbox-list">
                                      <li>
                                        <p className="font-primary simple-text">{data.description}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Row>
                      <Row className="my-4 justify-content-center gap-3" style={{ marginLeft: '4%', marginRight: '4%' }}>
                        <Button
                          className="col-3 font-primary simple-text d-flex justify-content-center align-items-center"
                          style={{
                            color: '#fff',
                            fontWeight: '600',
                            border: 'none',
                            background: '#FF7049',
                            boxShadow: '0px 16.21px 37.81px 0px rgba(255, 112, 73, 0.20)',
                            borderRadius: '10px',
                          }}
                          onClick={handleModalEmployeeUpdate}>
                          {loading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" /> : 'Enregister'}
                        </Button>
                        <Button
                          className="col-3 font-primary simple-text"
                          style={{ borderRadius: '10px', fontWeight: '600' }}
                          onClick={tog_updateEmployeeModal}>
                          Annuler
                        </Button>
                      </Row>
                    </ModalBody>
                  </Modal>
                  {company_employees_data ? (
                    <div className="users-tabpane col-xxl-10 col-xl-12 ms-auto me-auto">
                      <Row className="align-items-center justify-content-between flex-row mb-3">
                        <div style={{ width: 'fit-content' }}>
                          <h3 className="section-title">Utilisateurs et autorisations</h3>
                          <h5
                            className="simple-text"
                            style={{
                              color: '#666969',
                              fontStyle: 'normal',
                              fontWeight: '400',
                            }}>
                            Gérez ce que les utilisateurs peuvent voir ou faire dans votre service d'assistance
                          </h5>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: 'fit-content' }}>
                          <Button
                            className="me-4 simple-text font-secondary"
                            style={{
                              borderRadius: '10px',
                              backgroundColor: '#FF7049',
                              border: 'none',
                              padding: '16px 20px',
                              color: '#FFF',
                              fontStyle: 'normal',
                              fontWeight: '500',
                            }}
                            onClick={() => tog_list()}
                            id="create-btn">
                            <i className="ri-add-line align-bottom me-1"></i>Ajouter un employé
                          </Button>

                          <div className="">
                            <Dropdown
                              isOpen={btnprimary1}
                              toggle={() => setBtnprimary1(!btnprimary1)}
                              style={{
                                padding: '0px 8px',
                                marginTop: '4px',
                              }}>
                              <DropdownToggle
                                type="button"
                                tag="button"
                                // className={}
                                style={{
                                  marginBottom: '8px',
                                  padding: '0',
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  borderRadius: '5px',
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                                  <rect x="5" width="14" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="1.5" cy="1.5" r="1.5" fill="#FF7049" />
                                  <rect x="9" y="14" width="10" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="5.5" cy="15.5" r="1.5" fill="#FF7049" />
                                  <rect y="7" width="14" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="17.5" cy="8.5" r="1.5" fill="#FF7049" />
                                </svg>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => {}}>Désactiver tous</DropdownItem>
                                <DropdownItem onClick={() => {}}>Supprimer tous</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </Row>

                      {ownerInfos && ownerInfos.last_name && (
                        <Card
                          className="user-card"
                          style={{
                            borderLeft: '5px solid #563BFF',
                            marginBottom: '30px',
                            borderRadius: '15px',
                            boxShadow: '0px 6px 24px 1px rgba(53, 22, 252, 0.1)',
                            paddingTop: '27px',
                            paddingRight: '29px',
                            paddingBottom: '27px',
                            paddingLeft: '35px',
                          }}>
                          <CardBody className="p-0">
                            <Row className="">
                              <Col className="d-flex align-items-center col-xl-auto">
                                <input
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '6px',
                                    border: '1.5px solid #D9D9D9',
                                  }}
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  defaultValue=""
                                  // value={`option${index + 1}`}
                                />
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    fontSize: '250%',
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    padding: '10px  20px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    width: '86px',
                                    height: '86px',
                                  }}>
                                  {ownerInfos.last_name
                                    .split(' ')
                                    .map((part) => part.charAt(0).toUpperCase())
                                    .join('')}
                                </div>
                              </Col>
                              <Col xl={5} className="">
                                <div
                                  className="user-name"
                                  style={{
                                    width: 'fit-content',
                                    color: '#1E222C',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '26px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '38.454px',
                                    position: 'relative',
                                  }}>
                                  {ownerInfos.first_name} {ownerInfos.last_name}
                                </div>
                                <div
                                  style={{
                                    color: '#FF7049',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                  }}
                                  className="simple-text font-primary">
                                  Propriétaire
                                </div>
                              </Col>
                              {/* <Col xl={5} className="d-flex justify-content-end align-items-center">
                              <button
                                className={`me-3`}
                                // data-bs-toggle="modal"
                                // data-bs-target="#showModal"
                                // onClick={() => itemStates[index].spanVisible ? handleSpanClick(index) : handleButtonClick(index)}
                                // disabled={itemStates[index].buttonDisabled}
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  width: "33.059px",
                                  height: "33.059px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path style={{ fillRule: "evenodd", clipRule: "evenodd" }} d="M0 15.6448V18.9334C0 19.2363 0.237992 19.4743 0.54089 19.4743H3.8295C3.97013 19.4743 4.11077 19.4202 4.20813 19.312L16.0212 7.50978L11.9645 3.4531L0.162267 15.2553C0.054089 15.3635 0 15.4933 0 15.6448ZM19.1535 4.37308C19.5754 3.95119 19.5754 3.26967 19.1535 2.84777L16.6221 0.316405C16.42 0.113839 16.1456 0 15.8594 0C15.5733 0 15.2989 0.113839 15.0968 0.316405L13.1171 2.29606L17.1738 6.35274L19.1535 4.37308Z" fill="#329993" />
                                </svg>
                              </button>
                              <button
                                className=""
                                // data-bs-toggle="modal" data-bs-target="#deleteRecordModal"
                                // onClick={() => removeData(index)}
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  width: "43.059px",
                                  height: "33.059px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                  <path d="M23.4933 10.5732L22.595 24.3845C22.5409 25.2252 22.1809 26.0133 21.5875 26.5897C20.9942 27.1661 20.2118 27.4878 19.3983 27.4899H8.595C7.78152 27.4878 6.99912 27.1661 6.40579 26.5897C5.81246 26.0133 5.45241 25.2252 5.39833 24.3845L4.5 10.5732H23.4933ZM17.6273 6.94824H10.366C10.0426 6.94811 9.72377 6.86843 9.43587 6.71576C9.14796 6.5631 8.89918 6.34181 8.70997 6.07011C8.52077 5.79841 8.39656 5.48406 8.34757 5.15294C8.29858 4.82181 8.3262 4.48336 8.42817 4.16545L8.819 2.95591C8.95372 2.53494 9.21316 2.16868 9.56044 1.90923C9.90771 1.64977 10.3251 1.51033 10.7533 1.51074H17.2377C17.6663 1.51028 18.0842 1.6498 18.4319 1.90947C18.7796 2.16913 19.0394 2.53573 19.1743 2.95712L19.5628 4.16424C19.6653 4.48213 19.6933 4.82068 19.6446 5.15199C19.5959 5.4833 19.4718 5.79787 19.2827 6.06977C19.0935 6.34167 18.8447 6.56312 18.5567 6.71586C18.2687 6.86859 17.9497 6.94824 17.6262 6.94824H17.6273ZM10.7545 3.32324C10.6934 3.32284 10.6337 3.3425 10.584 3.3794C10.5343 3.41631 10.4972 3.46856 10.478 3.52866L10.0883 4.7382C10.0737 4.78378 10.0697 4.83233 10.0768 4.87981C10.0839 4.92729 10.1018 4.97234 10.1291 5.01121C10.1563 5.05008 10.1922 5.08166 10.2336 5.10332C10.275 5.12498 10.3208 5.13609 10.3672 5.13574H17.6285C17.6749 5.13596 17.7207 5.1247 17.7621 5.1029C17.8035 5.08109 17.8392 5.04937 17.8664 5.01037C17.8936 4.97137 17.9113 4.92622 17.9182 4.87867C17.9252 4.83112 17.921 4.78255 17.9062 4.73699L17.5177 3.52987C17.4983 3.46954 17.461 3.4171 17.4111 3.38C17.3613 3.3429 17.3014 3.32304 17.24 3.32324H10.7545Z" fill="#F10944" />
                                  <path d="M11.6641 20.2396C11.432 20.2396 11.2094 20.1441 11.0453 19.9741C10.8813 19.8042 10.7891 19.5737 10.7891 19.3333V14.5C10.7891 14.2596 10.8813 14.0291 11.0453 13.8592C11.2094 13.6892 11.432 13.5938 11.6641 13.5938C11.8961 13.5938 12.1187 13.6892 12.2828 13.8592C12.4469 14.0291 12.5391 14.2596 12.5391 14.5V19.3333C12.5391 19.5737 12.4469 19.8042 12.2828 19.9741C12.1187 20.1441 11.8961 20.2396 11.6641 20.2396ZM16.3307 20.2396C16.0987 20.2396 15.8761 20.1441 15.712 19.9741C15.5479 19.8042 15.4557 19.5737 15.4557 19.3333V14.5C15.4557 14.2596 15.5479 14.0291 15.712 13.8592C15.8761 13.6892 16.0987 13.5938 16.3307 13.5938C16.5628 13.5938 16.7854 13.6892 16.9494 13.8592C17.1135 14.0291 17.2057 14.2596 17.2057 14.5V19.3333C17.2057 19.5737 17.1135 19.8042 16.9494 19.9741C16.7854 20.1441 16.5628 20.2396 16.3307 20.2396Z" fill="#EDEBEA" />
                                  <path d="M23.3333 5.13574H4.66667C4.12518 5.13574 3.60588 5.35853 3.22299 5.75509C2.8401 6.15165 2.625 6.6895 2.625 7.25033C2.625 7.81115 2.8401 8.349 3.22299 8.74556C3.60588 9.14212 4.12518 9.36491 4.66667 9.36491H23.3333C23.8748 9.36491 24.3941 9.14212 24.777 8.74556C25.1599 8.349 25.375 7.81115 25.375 7.25033C25.375 6.6895 25.1599 6.15165 24.777 5.75509C24.3941 5.35853 23.8748 5.13574 23.3333 5.13574Z" fill="#F10944" />
                                </svg>
                              </button>
                            </Col> */}
                            </Row>
                          </CardBody>
                        </Card>
                      )}

                      {employeesList.map((employee, index) => (
                        <Card
                          className="user-card"
                          style={{
                            borderLeft: '5px solid #563BFF',
                            marginBottom: '30px',
                            borderRadius: '15px',
                            boxShadow: '0px 6px 24px 1px rgba(53, 22, 252, 0.1)',
                            paddingTop: '27px',
                            paddingRight: '29px',
                            paddingBottom: '27px',
                            paddingLeft: '35px',
                          }}>
                          <CardBody className="p-0">
                            <Row>
                              <Col className="d-flex align-items-center col-xl-auto">
                                <input
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '6px',
                                    border: '1.5px solid #D9D9D9',
                                  }}
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  value={`option${index + 1}`}
                                />
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    fontSize: '250%',
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    padding: '10px  20px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    width: '86px',
                                    height: '86px',
                                  }}>
                                  {employee.last_name
                                    .split(' ')
                                    .map((part) => part.charAt(0).toUpperCase())
                                    .join('')}
                                  {employee.first_name
                                    .split(' ')
                                    .map((part) => part.charAt(0).toUpperCase())
                                    .join('')}
                                </div>
                              </Col>
                              <Col xl={4} className="">
                                <div className="d-flex align-items-baseline gap-2">
                                  <div
                                    className="agent-name"
                                    style={{
                                      width: 'fit-content',
                                      color: '#1E222C',
                                      fontFamily: 'Poppins, sans-serif',
                                      fontSize: '26px',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '38.454px',
                                      position: 'relative',
                                    }}>
                                    {employee.first_name} {employee.last_name}
                                  </div>
                                  <span
                                    className="font-primary"
                                    style={{
                                      color: '#1E8E3E',
                                      fontSize: '20px',
                                      fontStyle: 'normal',
                                      fontWeight: '300',
                                      height: 'fit-content',
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                      <path
                                        d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
                                        fill={'#1E8E3E'}
                                      />
                                    </svg>{' '}
                                    {'Actif'}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    color: '#FF7049',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                  }}
                                  className="simple-text font-primary mb-2">
                                  Employé
                                </div>
                                <Row>
                                  <div
                                    className="col-auto user-info"
                                    style={{
                                      color: '#272B35',
                                      fontFamily: 'Poppins, sans-serif',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '18px',
                                    }}>
                                    <i className="dripicons-mail me-2 align-middle"></i>
                                    {employee.email}
                                  </div>
                                  <div
                                    className="col-auto user-info"
                                    style={{
                                      color: '#272B35',
                                      fontFamily: 'Poppins, sans-serif',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '18px',
                                    }}>
                                    <i className="dripicons-phone me-2 align-middle"></i>
                                    {employee.phone ?? '---'}
                                  </div>
                                </Row>
                              </Col>
                              <Col xl={5} className="d-flex justify-content-end simple-text font-primary user-autorizations">
                                {`${
                                  employee.autorizations
                                    ? employee.autorizations.map((auth) => ' ' + authorizations.find((item) => item.value === auth)?.text)
                                    : ''
                                }`}
                              </Col>
                              <Col xl={1} className="d-flex justify-content-end">
                                <div className="d-flex">
                                  {employeeEditActions[employee.id] && employeeEditActions[employee.id].loading ? (
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                  ) : (
                                    <Dropdown
                                      isOpen={employeeEditActions[employee.id] && employeeEditActions[employee.id].state}
                                      toggle={() => {
                                        setEmployeesEditActions((prevState) => ({
                                          ...prevState,
                                          [employee.id]: prevState[employee.id]
                                            ? { state: !prevState[employee.id].state, loading: prevState[employee.id].loading }
                                            : { state: true, loading: false },
                                        }));
                                      }}
                                      style={{
                                        display: 'flex',
                                      }}>
                                      <DropdownToggle
                                        tag="button"
                                        className={`btn email-menu-dropdown email-menu-dropdown-active                                      `}
                                        style={{
                                          border: 'none',
                                          padding: '0',
                                          marginRight: '10px',
                                        }}>
                                        <svg width="27" height="7" viewBox="0 0 27 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g id="Group 1000002886">
                                            <circle id="Ellipse 1029" cx="3" cy="3.24402" r="3" fill="#563BFF" />
                                            <circle id="Ellipse 1031" cx="13.4277" cy="3.24402" r="3" fill="#563BFF" />
                                            <circle id="Ellipse 1030" cx="23.5723" cy="3.24402" r="3" fill="#563BFF" />
                                          </g>
                                        </svg>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => {
                                            onEmployeeToUpdateChanged(employee);
                                            decodeAuthozations(employee.autorizations);
                                            tog_updateEmployeeModal();
                                          }}>
                                          Modifier
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setEmployeesEditActions((prevState) => ({
                                              ...prevState,
                                              [employee.id]: { state: false, loading: true },
                                            }));
                                            deleteUserRole(employee.id);
                                          }}>
                                          Supprimer
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            {/* <div className="mt-3 d-flex">
                                          <div className="me-3 mt-1">
                                            <div style={{ backgroundColor: 'grey', color: 'white', padding: '8px', borderRadius: '8px', width: '35px', textAlign: 'center' }}>
                                              AA
                                            </div>
                                          </div>
                                          <div className="">
                                            <div style={{ color: 'blue' }}>Alex Anihouvi</div>
                                            <div>Dernière connexion : dimanche 24 septembre 2023 18:17 UTC+1</div>
                                          </div>
                                          <div style={{marginLeft:'20%'}}>
                                            Autorisations limitées
                                          </div>
                                        </div> */}
                          </CardBody>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <div>Echec de chargement</div>
                  )}
                </TabPane>

                {/* Agents IA */}
                <TabPane tabId="5" style={{ color: 'black', backgroundColor: 'inherit', paddingLeft: '8%', paddingRight: '8%' }}>
                  {/* UPDATE AGENT MODAL */}
                  {company_infos ? (
                    <div className="agents-tabpane col-xxl-10 col-xl-12 ms-auto me-auto">
                      <Row className="align-items-center justify-content-between flex-row mb-3">
                        <div style={{ width: 'fit-content' }}>
                          <h3 className="section-title">Agents</h3>
                          <h5
                            className="simple-text"
                            style={{
                              color: '#666969',
                              fontStyle: 'normal',
                              fontWeight: '400',
                            }}>
                            Gérez les agents IA de votre service d'assistance
                          </h5>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: 'fit-content' }}>
                          <Button
                            className="me-4 simple-text font-secondary"
                            style={{
                              borderRadius: '10px',
                              backgroundColor: '#FF7049',
                              border: 'none',
                              padding: '16px 20px',
                              color: '#FFF',
                              fontStyle: 'normal',
                              fontWeight: '500',
                            }}
                            onClick={() => togAddAgentModal()}
                            id="create-btn">
                            <i className="ri-add-line align-bottom me-1"></i>Ajouter un agent
                          </Button>

                          <div className="">
                            <Dropdown
                              isOpen={btnprimary1}
                              toggle={() => setBtnprimary1(!btnprimary1)}
                              style={{
                                padding: '0px 8px',
                                marginTop: '4px',
                              }}>
                              <DropdownToggle
                                type="button"
                                tag="button"
                                // className={}
                                style={{
                                  marginBottom: '8px',
                                  padding: '0',
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  borderRadius: '5px',
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                                  <rect x="5" width="14" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="1.5" cy="1.5" r="1.5" fill="#FF7049" />
                                  <rect x="9" y="14" width="10" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="5.5" cy="15.5" r="1.5" fill="#FF7049" />
                                  <rect y="7" width="14" height="3" rx="1.5" fill="#FF7049" />
                                  <circle cx="17.5" cy="8.5" r="1.5" fill="#FF7049" />
                                </svg>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => {}}>Désactiver tous</DropdownItem>
                                <DropdownItem onClick={() => {}}>Supprimer tous</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </Row>

                      {agentsList.length === 0 ? (
                        <div className={'d-flex align-items-center justify-content-center'} style={{ height: '30vh' }}>
                          <p>Aucun agent activé</p>
                        </div>
                      ) : (
                        agentsList.map((agent, index) => (
                          <Card
                            key={index}
                            className="agent-card"
                            style={{
                              borderLeft: '5px solid #563BFF',
                              marginBottom: '30px',
                              borderRadius: '15px',
                              boxShadow: '0px 6px 24px 1px rgba(53, 22, 252, 0.1)',
                              paddingTop: '27px',
                              paddingRight: '29px',
                              paddingBottom: '27px',
                              paddingLeft: '35px',
                            }}>
                            <CardBody className="p-0">
                              <Row className="d-flex">
                                <Col className="d-flex align-items-center col-auto">
                                  <input
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      borderRadius: '6px',
                                      border: '1.5px solid #D9D9D9',
                                    }}
                                    className="form-check-input me-3"
                                    type="checkbox"
                                    value={`option${index + 1}`}
                                  />
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      fontSize: '250%',
                                      backgroundColor: 'grey',
                                      color: 'white',
                                      padding: '10px  20px',
                                      borderRadius: '8px',
                                      textAlign: 'center',
                                      width: '86px',
                                      height: '86px',
                                    }}>
                                    {agent.agent_name
                                      .split(' ')
                                      .map((part) => part.charAt(0).toUpperCase())
                                      .join('')}
                                  </div>
                                </Col>
                                <Col className="col-auto">
                                  <div className="d-flex align-items-baseline gap-2">
                                    <div
                                      className="agent-name"
                                      style={{
                                        width: 'fit-content',
                                        color: '#1E222C',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '26px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '38.454px',
                                        position: 'relative',
                                      }}>
                                      {agent.agent_name}
                                    </div>
                                    <span
                                      className="font-primary"
                                      style={{
                                        color: agent.active ? '#1E8E3E' : 'red',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '300',
                                        height: 'fit-content',
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <path
                                          d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
                                          fill={agent.active ? '#1E8E3E' : 'red'}
                                        />
                                      </svg>{' '}
                                      {agent.active ? 'Actif' : 'Inactif'}{' '}
                                    </span>
                                  </div>

                                  <div
                                    style={{
                                      color: 'black',
                                      fontStyle: 'normal',
                                      fontWeight: 'normal',
                                    }}
                                    className="simple-text font-primary mb-2">
                                    {agent.agent_alias}
                                  </div>
                                  <Row>
                                    <div
                                      className="col-auto agent-info"
                                      style={{
                                        color: '#272B35',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '18px',
                                      }}>
                                      {/* <i className="dripicons-web me-2 align-middle"></i> */}
                                      {/* {agent.agent_language} */}
                                      {agent.agent_language &&
                                        agent.agent_language.map((lang, index) => (
                                          <img
                                            style={{ marginRight: '5px' }}
                                            height={15}
                                            alt={`flag-${lang.name}`}
                                            src={sendLanguageFlag(lang.code)}
                                          />
                                        ))}
                                    </div>
                                    <div
                                      className="col-auto agent-info"
                                      style={{
                                        color: '#272B35',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '18px',
                                      }}>
                                      <i className="dripicons-clock me-2 align-middle"></i>
                                      {agent.agent_timezone}
                                    </div>
                                  </Row>
                                </Col>

                                {!deleteAgentLoading[agent.id] ? (
                                  <Col className="d-flex align-items-center justify-content-end">
                                    <div className="d-flex">
                                      <Button
                                        className={`pp_button ${agent.active ? 'pp_button_paused' : 'pp_button_playing'} me-2`}
                                        onClick={() => {
                                          agentStatusChangeActions(index, agent.active);
                                        }}
                                        style={{ height: '30px', width: '30px', borderRadius: '50%' }}>
                                        <div className="inner_div">{agent.active ? svgs.pause_icon : svgs.play_icon}</div>
                                      </Button>
                                    </div>
                                    <button
                                      className={`me-1 btn-success`}
                                      onClick={() => {
                                        togUpdateAgentModal();
                                        setAgentModalInfo(agent);
                                        setTimesByDay(agent.agent_work_times);
                                        console.log('agent.agent_work_times is', agent.agent_work_times);
                                      }}
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'inherit',
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                      }}>
                                      {svgs.edit_icon}
                                    </button>
                                    {!agent.active && (
                                      <button
                                        className=""
                                        onClick={() => {
                                          deleteAgentActions(agent.id);
                                        }}
                                        style={{
                                          border: 'none',
                                          backgroundColor: 'inherit',
                                          width: '43.059px',
                                          height: '33.059px',
                                          borderRadius: '8px',
                                          cursor: 'pointer',
                                        }}>
                                        {svgs.delete_icon}
                                      </button>
                                    )}
                                  </Col>
                                ) : (
                                  <Col className="d-flex align-items-center justify-content-end">
                                    <span className="spinner-border spinner-border-lg me-2" role="status" aria-hidden="true" />
                                  </Col>
                                )}
                              </Row>
                              {/* <div className="mt-3 d-flex">
                                          <div className="me-3 mt-1">
                                            <div style={{ backgroundColor: 'grey', color: 'white', padding: '8px', borderRadius: '8px', width: '35px', textAlign: 'center' }}>
                                              AA
                                            </div>
                                          </div>
                                          <div className="">
                                            <div style={{ color: 'blue' }}>Alex Anihouvi</div>
                                            <div>Dernière connexion : dimanche 24 septembre 2023 18:17 UTC+1</div>
                                          </div>
                                          <div style={{marginLeft:'20%'}}>
                                            Autorisations limitées
                                          </div>
                                        </div> */}
                            </CardBody>
                          </Card>
                        ))
                      )}
                    </div>
                  ) : (
                    <div>Echec de chargement</div>
                  )}
                </TabPane>
                {/* <TabPane tabId="7"> */}
                {/* <Facturation></Facturation> */}
                {/* </TabPane> */}
                <TabPane tabId="8">
                  <Notifications></Notifications>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </div>
      {/* Add emplpoyee Modal */}
      <Modal isOpen={modal_list} toggle={tog_list} centered className="user-modal">
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={tog_list}>
          AJOUTER UN UTILISATEUR
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <h6 className="font-tertiary font-size-16">Employés</h6>
            <p className="font-primary simple-text">
              Donnez à vos employés l'accès à votre boutique en leur envoyant une invitation. Si vous travaillez avec un concepteur, un développeur ou
              un distributeur, découvrez comment
              <Link> donner un accès collaborateur à la boutique.</Link>
            </p>
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Prénom</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=" "
                        required
                        value={modalInfo.first_name}
                        onChange={(e) => setModalInfo({ ...modalInfo, first_name: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Nom</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=" "
                        required
                        value={modalInfo.last_name}
                        onChange={(e) => setModalInfo({ ...modalInfo, last_name: e.target.value })}
                      />
                    </div>
                  </Col>
                </Row>
                <p className="mt-4 font-primary simple-text">
                  Saisissez le prénom et le nom de l'employé(e) tels qu'ils apparaissent sur sa pièce d'identité officielle.{' '}
                </p>
                <Row>
                  <Col md={12}>
                    <div>
                      <label className="form-label">Adresse e-mail</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=" "
                        required
                        value={modalInfo.email}
                        onChange={(e) => setModalInfo({ ...modalInfo, email: e.target.value })}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>
                <h6 className="font-tertiary font-size-16">Autorisations de boutique</h6>
                <p className="font-primary simple-text">
                  Gérez les autorisations d'employé. Certaines cases à cocher sont désactivées, car vous n'avez pas l'autorisation de les changer.
                </p>
                <Row className="g-4 mb-3">
                  <div className="">
                    <div className="search-box ms-2">
                      <input type="text" className="form-control search" placeholder="Search..." style={{ borderRadius: '5px' }} />
                      <i className="ri-search-line search-icon mt-4"></i>
                    </div>
                  </div>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col xl={12}>
                    <label className="font-tertiary" style={{ color: 'black', cursor: 'pointer' }}>
                      <input
                        className="me-2"
                        type="checkbox"
                        checked={authorizations[0].status}
                        onChange={(e) => {
                          let updatedAuthorizations = [...authorizations];
                          updatedAuthorizations[0].status = e.target.checked;
                          setAuthorizations(updatedAuthorizations);
                          handleParentCheckboxChange(authorizations[0].id, e.target.checked);
                        }}
                      />
                      {authorizations[0].text}
                    </label>
                  </Col>
                </Row>
                <div className="accordion accordion-boutique" id="accordionFlushExample">
                  {authorizations.map(
                    (authorization, idx) =>
                      authorization.parents.length === 1 && (
                        <div key={idx} className="accordion-item">
                          <h2 className="accordion-header d-flex" id={'flush-heading' + idx}>
                            <input
                              className="me-2"
                              type="checkbox"
                              checked={authorizations[idx].status}
                              onChange={(e) => {
                                let updatedAuthorizations = [...authorizations];
                                updatedAuthorizations[idx].status = !updatedAuthorizations[idx].status;
                                setAuthorizations(updatedAuthorizations);
                                handleParentCheckboxChange(authorization.id, e.target.checked, authorization.id);
                              }}
                            />
                            <button
                              className="accordion-button collapsed accordion-new-button mt-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={'#flush-collapse' + idx}
                              aria-expanded="false"
                              aria-controls={'flush-collapse' + idx}>
                              <label className="font-tertiary">{authorization.text}</label>
                            </button>
                          </h2>
                          <div
                            id={'flush-collapse' + idx}
                            className="accordion-collapse collapse"
                            aria-labelledby={'flush-heading' + idx}
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <div style={{ marginLeft: '30px' }}>
                                <ul className="checkbox-list">
                                  {authorizations.map(
                                    (authorizationChild, idx) =>
                                      authorizationChild.parents[1] === authorization.id && (
                                        <li key={idx}>
                                          <label className="font-primary simple-text">
                                            <input
                                              className="me-2"
                                              type="checkbox"
                                              checked={authorizations[idx].status}
                                              onChange={(e) => {
                                                let updatedAuthorizations = [...authorizations];
                                                updatedAuthorizations[idx].status = !updatedAuthorizations[idx].status;
                                                setAuthorizations(updatedAuthorizations);
                                                handleChildCheckboxChange(authorizationChild.id, authorizationChild.parents[1], e.target.checked);
                                              }}
                                            />
                                            {authorizationChild.text}
                                          </label>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </CardBody>
            </Card>
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>
                <h6 className="font-tertiary font-size-16">Notifications</h6>
                <p className="font-primary simple-text">
                  Gérez les notifications d'employé. Certaines cases à cocher sont désactivées, car vous n'avez pas l'autorisation de les changer.
                </p>
                <Row className="mt-4 mb-4">
                  <Col xl={12}>
                    <label className="font-tertiary" style={{ color: 'black', cursor: 'pointer' }}>
                      <input
                        className="me-2"
                        type="checkbox"
                        checked={allCkecked}
                        onChange={(e) => {
                          setAllChecked(e.target.checked);
                          handleNotifAllCheck(e.target.checked);
                        }}
                      />
                      Sélectionner toutes les notifications
                    </label>
                  </Col>
                </Row>
                <div className="accordion accordion-boutique" id="accordionFlushExample">
                  {notificationData.map((data, idx) => (
                    <div key={idx} className="accordion-item">
                      <h2 className="accordion-header d-flex" id={'flush-heading' + idx}>
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={notifications[data.key]}
                          onChange={(e) => {
                            let updatedNotifications = { ...notifications };
                            updatedNotifications[data.key] = e.target.checked;
                            setNotifications(updatedNotifications);
                            handleNotifCheckChange(data.key, e.target.checked);
                          }}
                        />
                        <button
                          className="accordion-button collapsed accordion-new-button mt-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={'#flush-collapse' + idx}
                          aria-expanded="false"
                          aria-controls={'flush-collapse' + idx}>
                          <label className="font-tertiary">{data.title}</label>
                        </button>
                      </h2>
                      <div
                        id={'flush-collapse' + idx}
                        className="accordion-collapse collapse"
                        aria-labelledby={'flush-heading' + idx}
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div style={{ marginLeft: '10px' }}>
                            <ul className="checkbox-list">
                              <li>
                                <p className="font-primary simple-text">{data.description}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={tog_list}>
                Fermer
              </button>
              <button
                type="button"
                style={{ backgroundColor: '#563bff' }}
                className="btn btn-success center"
                id="edit-btn"
                onClick={handleEmployeeAdded}>
                {loading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" /> : 'Enregister'}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Add agent Modal */}
      <Modal isOpen={addAgentModal} toggle={togAddAgentModal} centered>
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={togAddAgentModal}>
          AJOUTER UN AGENT
        </ModalHeader>
        <form className="tablelist-form" onSubmit={(e) => e.preventDefault()}>
          <ModalBody>
            <h6 className="font-tertiary font-size-16">Agent</h6>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col md={6}>
                    <div>
                      <label className="form-label">Nom de l'agent</label>
                      <input
                        className="form-control font-primary simple-text"
                        type="text"
                        placeholder=" "
                        required
                        value={agentModalInfo.agent_name}
                        onChange={(e) => setAgentModalInfo({ ...agentModalInfo, agent_name: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Alias de l'agent</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=" "
                        required
                        value={agentModalInfo.agent_alias}
                        onChange={(e) => setAgentModalInfo({ ...agentModalInfo, agent_alias: e.target.value })}
                      />
                    </div>
                    {/* <div>
                      <label className="form-label">Langue de l'agent</label>
                      <Dropdown isOpen={btnmenu2} toggle={() => setbtnmenu2(!btnmenu2)} className="form-control">
                        <DropdownToggle tag="button" className="btn w-100 d-flex justify-content-between" style={{ border: 'none' }}>
                          {agentModalInfo.agent_language} <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-lg-end">
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Anglais' })}>Anglais</DropdownItem>
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Français' })}>Français</DropdownItem>
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Epagnol' })}>Espagnol</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col md={12}>
                    <div>
                      <label className="form-label">Langue de l'agent</label>
                      <div className="mb-3 templating-select select2-container">
                        <Select
                          style={{ zIndex: '9999' }}
                          value={agentModalInfo.agent_language.map((lang) => {
                            return { label: lang.name, value: lang.code };
                          })}
                          isMulti={true}
                          onChange={(e) =>
                            setAgentModalInfo({
                              ...agentModalInfo,
                              agent_language: e.map((lang) => {
                                return { name: lang.label, code: lang.value };
                              }),
                            })
                          }
                          options={optionGroup2}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Col md={12} className="mb-4">
                  <label className="form-label">Horaires d'activité de l'agent</label>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select form-control"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      value={agentSelectedTimezone}
                      onChange={(e) => setAgentTimezone(setUtc(e.target.value))}>
                      <option value={agentSelectedTimezone}>{agentSelectedTimezone}</option>
                      {timezones.map((timezone, index) => (
                        <option key={index} value={timezone}>
                          {setUtc(timezone)}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelectGrid">
                      <p>Fuseau sélectionné: {agentSelectedTimezone}</p>
                    </label>
                  </div>
                </Col>
                <Row>
                  <Col md={12}>
                    {daysOfWeek.map((day, index) => (
                      <Row key={day} className="mb-3">
                        <Col className="col-4">
                          <div style={{ marginTop: '13px' }} key={index} className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id={`inlineCheck-${day}`}
                              onClick={() => handleTimeChange(day, 'checked', timesByDay[day].checked ? false : true)}
                            />
                            <label className="form-check-label" htmlFor={`inlineCheck-${day}`}>
                              {getFrenchTag(day)}
                            </label>
                          </div>
                        </Col>
                        <Col className="col-8">
                          <MultiRangeSlider
                            disabled={!timesByDay[day].checked}
                            min={0}
                            max={24}
                            minValue={0}
                            step={1}
                            maxValue={24}
                            minCaption={(timesByDay[day].open < 10 ? '0' + timesByDay[day].open : timesByDay[day].open) + 'h'}
                            maxCaption={
                              timesByDay[day].close < 10
                                ? '0' + timesByDay[day].close + 'h'
                                : timesByDay[day].close === 24
                                ? 'Minuit'
                                : timesByDay[day].close + 'h'
                            }
                            onChange={(e) => {
                              setTimesByDay((prevState) => {
                                return {
                                  ...prevState,
                                  [day]: {
                                    ...prevState[day],
                                    open: e.minValue,
                                    close: e.maxValue,
                                  },
                                };
                              });
                            }}
                            style={{ border: 'none', boxShadow: 'none', background: '#F7F8FB', padding: '20px 20px' }}
                            labels={['', '']}
                            ruler={false}
                            barLeftColor="#F7F8FB"
                            barInnerColor="#563BFF"
                            barRightColor="#F7F8FB"
                            thumbLeftColor="white"
                            thumbRightColor="white"
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>

                {Object.keys(formErrors).length > 0 && (
                  <div className="alert alert-danger">
                    {Object.keys(formErrors).map((day, index) => (
                      <p key={index}>{`${getFrenchTag(day)}: ${formErrors[day]}`}</p>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={togAddAgentModal}>
                Fermer
              </button>
              <button
                type="button"
                disabled={!agentModalInfo.agent_name}
                className="btn btn-success"
                style={{ backgroundColor: '#563bff', cursor: agentModalInfo.agent_name === '' ? 'not-allowed' : 'pointer' }}
                id="edit-btn"
                onClick={handleAgentAdded}>
                {updateAgentsLoading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" /> : 'Enregister'}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Update agent Modal */}
      <Modal isOpen={updateAgentModal} toggle={togUpdateAgentModal} centered>
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={togUpdateAgentModal}>
          MODIFIER AGENT
        </ModalHeader>
        <form className="tablelist-form" onSubmit={(e) => e.preventDefault()}>
          <ModalBody>
            <h6 className="font-tertiary font-size-16">Agent</h6>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col md={6}>
                    <div>
                      <label className="form-label">Nom de l'agent</label>
                      <input
                        className="form-control font-primary simple-text"
                        type="text"
                        placeholder=" "
                        disabled={true}
                        required
                        value={agentModalInfo.agent_name}
                        onChange={(e) => setAgentModalInfo({ ...agentModalInfo, agent_name: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <label className="form-label">Alias de l'agent</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=" "
                        required
                        value={agentModalInfo.agent_alias}
                        onChange={(e) => setAgentModalInfo({ ...agentModalInfo, agent_alias: e.target.value })}
                      />
                    </div>
                    {/* <div>
                      <label className="form-label">Langue de l'agent</label>
                      <Dropdown isOpen={btnmenu2} toggle={() => setbtnmenu2(!btnmenu2)} className="form-control">
                        <DropdownToggle tag="button" className="btn w-100 d-flex justify-content-between" style={{ border: 'none' }}>
                          {agentModalInfo.agent_language} <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-lg-end">
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Anglais' })}>Anglais</DropdownItem>
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Français' })}>Français</DropdownItem>
                          <DropdownItem onClick={(e) => setAgentModalInfo({ ...agentModalInfo, agent_language: 'Epagnol' })}>Epagnol</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col md={12}>
                    <div>
                      <label className="form-label">Langue de l'agent</label>
                      <div className="mb-3 templating-select select2-container">
                        <Select
                          style={{ zIndex: '9999' }}
                          value={agentModalInfo.agent_language.map((lang) => {
                            return { label: lang.name, value: lang.code };
                          })}
                          isMulti={true}
                          // onChange={(e) => handleMulti3(e)}
                          onChange={(e) =>
                            setAgentModalInfo({
                              ...agentModalInfo,
                              agent_language: e.map((lang) => {
                                return { name: lang.label, code: lang.value };
                              }),
                            })
                          }
                          options={optionGroup2}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                        />
                      </div>
                      {/* <Dropdown
                        isOpen={btnmenu2}
                        toggle={() => setbtnmenu2(!btnmenu2)}
                        className="form-control"
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn w-100 d-flex justify-content-between"
                          style={{ border: "none" }}
                        >
                          {agentModalInfo.agent_language}{" "}
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-lg-end">
                          <DropdownItem
                            onClick={(e) =>
                              setAgentModalInfo({
                                ...agentModalInfo,
                                agent_language: "Anglais",
                              })
                            }
                          >
                            Anglais
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              setAgentModalInfo({
                                ...agentModalInfo,
                                agent_language: "Français",
                              })
                            }
                          >
                            Français
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              setAgentModalInfo({
                                ...agentModalInfo,
                                agent_language: "Epagnol",
                              })
                            }
                          >
                            Epagnol
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown> */}
                    </div>
                  </Col>
                </Row>

                <Col md={12} className="mb-4">
                  <label className="form-label">Horaires d'activité de l'agent</label>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select form-control"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      value={agentSelectedTimezone}
                      onChange={(e) => setAgentTimezone(setUtc(e.target.value))}>
                      <option value={agentSelectedTimezone}>{agentSelectedTimezone}</option>
                      {timezones.map((timezone, index) => (
                        <option key={index} value={timezone}>
                          {setUtc(timezone)}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelectGrid">
                      <p>Fuseau sélectionné: {agentSelectedTimezone}</p>
                    </label>
                  </div>
                </Col>
                <Row>
                  <Col md={12}>
                    {daysOfWeek.map((day, index) => (
                      <Row key={day} className="mb-3">
                        <Col className="col-4">
                          <div style={{ marginTop: '13px' }} key={index} className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id={`inlineCheck-${day}`}
                              checked={timesByDay[day].checked}
                              onClick={() => handleTimeChange(day, 'checked', timesByDay[day].checked ? false : true)}
                            />
                            <label className="form-check-label" htmlFor={`inlineCheck-${day}`}>
                              {getFrenchTag(day)}
                            </label>
                          </div>
                        </Col>
                        <Col className="col-8">
                          <MultiRangeSlider
                            disabled={!timesByDay[day].checked}
                            min={0}
                            max={24}
                            minValue={timesByDay[day].open}
                            step={1}
                            maxValue={timesByDay[day].close}
                            minCaption={(timesByDay[day].open < 10 ? '0' + timesByDay[day].open : timesByDay[day].open) + 'h'}
                            maxCaption={
                              timesByDay[day].close < 10
                                ? '0' + timesByDay[day].close + 'h'
                                : timesByDay[day].close === 24
                                ? 'Minuit'
                                : timesByDay[day].close + 'h'
                            }
                            onChange={(e) => {
                              setTimesByDay((prevState) => {
                                return {
                                  ...prevState,
                                  [day]: {
                                    ...prevState[day],
                                    open: e.minValue,
                                    close: e.maxValue,
                                  },
                                };
                              });
                            }}
                            style={{ border: 'none', boxShadow: 'none', background: '#F7F8FB', padding: '20px 20px' }}
                            labels={['', '']}
                            ruler={false}
                            barLeftColor="#F7F8FB"
                            barInnerColor="#563BFF"
                            barRightColor="#F7F8FB"
                            thumbLeftColor="white"
                            thumbRightColor="white"
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>

                {Object.keys(formErrors).length > 0 && (
                  <div className="alert alert-danger">
                    {Object.keys(formErrors).map((day, index) => (
                      <p key={index}>{`${getFrenchTag(day)}: ${formErrors[day]}`}</p>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={togUpdateAgentModal}>
                Fermer
              </button>
              <button
                type="button"
                disabled={!agentModalInfo.agent_name}
                className="btn btn-success"
                style={{ backgroundColor: '#563bff', cursor: agentModalInfo.agent_name === '' ? 'not-allowed' : 'pointer' }}
                id="edit-btn"
                onClick={(e) => handleModalAgentUpdate(agentModalInfo.id)}>
                {updateAgentLoading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" /> : 'Enregister'}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};
export default Settings;
