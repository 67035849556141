import React, { useState, useEffect, componentDidMount, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  Form,
  Col,
  Badge,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Modal,
} from 'reactstrap';
import InboxSidebar2 from './Sidebar2';
import { Client, Account, Databases, Query } from 'appwrite';

const EmailList = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const messages = props.messages.slice(-7);
  const fakeMessages = [
    {
      snippet: '', //
      text: "Bonjour, j'ai commandé un collier personnalisé le 15 janvier et je n'ai pas encore reçu  mise à jour sur l'expédition. Pourriez-vous me dire où en est ma commande ? Merci, Julie Martin.", //
      subject: 'Suivi de ma commande de collier #5678',
      name: 'Julie Martin',
    },
    {
      snippet: '', //
      text: "Bonjour, je suis intéressé par la bague en or référence #1234. Pouvez-vous me confirmer si l'or est bien de 18 carats ? Cordialement, Alex Dupont.", //
      subject: 'Renseignements sur la bague en or',
      name: 'Alex Dupont',
    },
    {
      snippet: '', //
      text: "Bonjour, mon colis avec le numéro de suivi #7890 devait arriver hier, mais je ne l'ai toujours pas reçu. Pouvez-vous vérifier ce qui se passe ? Merci, Emma Bernard.", //
      subject: 'Colis non reçu #7890',
      name: 'Emma Bernard.',
    },
    {
      snippet: '', //
      text: "Bonjour, j'ai reçu la montre commandée #3456, mais elle ne correspond pas à mes apentes. Comment puis-je procéder pour la retourner ? Cordialement, Lucas Lemoine.", //
      subject: 'Retour de commande #3456',
      name: 'Lucas Lemoine',
    },
    {
      snippet: '', //
      text: "Bonjour, je souhaite commander un pendentif et le faire graver. Quelles sont les options de gravure disponibles ? Merci d'avance, Sarah Petit. ", //
      subject: 'Gravure personnalisée sur pendentif',
      name: 'Sarah Petit',
    },
  ];
  const the_folder = props.folder;
  console.log('EmailList is: ', props.messages);
  const [messageInfos, setMessageInfos] = useState({});
  const contactReasonMap = {};

  function goToMessageReadView(message) {
    console.log('the_folder props data is: ', the_folder);

    /*
        navigate("/read-email", {
            replace: false,
            state: { the_message: message, all_messages: messages, folder: the_folder ? the_folder : "INBOX" },
        });
        */
  }

  function changeColor(tag) {
    switch (tag) {
      case 'product_info':
        return 'primary';
      case 'product_availability':
        return 'primary';
      case 'product_advice':
        return 'primary';
      case 'product_problems':
        return 'danger';
      case 'product_price':
        return 'primary';
      case 'promotions':
        return 'primary';
      case 'order_process':
        return 'primary';
      case 'order_info':
        return 'danger';
      case 'payments':
        return 'danger';
      case 'refunds':
        return 'danger';
      case 'disputes':
        return 'danger';
      case 'billing':
        return 'warning';
      case 'delivery':
        return 'danger';
      case 'returns':
        return 'warning';
      case 'customer_account':
        return 'info';
      case 'password':
        return 'info';
      case 'security':
        return 'info';
      case 'privacy':
        return 'info';
      case 'customer_data':
        return 'info';
      case 'after_sales':
        return 'info';
      case 'technical_problems':
        return 'primary';
      case 'website_feedback':
        return 'info';
      case 'special_programs':
        return 'info';
      case 'affiliation':
        return 'info';
      case 'customer_loyalty':
        return 'info';
      case 'feedback':
        return 'info';
      case 'complaints':
        return 'warning';
      case 'company_information':
        return 'info';
      case 'ethics_social_responsibility':
        return 'info';
      case 'subscription_management':
        return 'info';
      case 'store_services':
        return 'info';
      case 'influencers':
        return 'info';
      case 'content_creators':
        return 'info';
      case 'spam':
        return 'warning';
      case 'other':
        return 'info';
      default:
        return 'warning';
    }
  }

  function formaterDate(date) {
    const dateObj = new Date(date);
    const maintenant = new Date();
    const estAujourdhui = dateObj.toDateString() === maintenant.toDateString();
    const mois = dateObj.toLocaleString('default', { month: 'short' });
    const jour = estAujourdhui ? "Aujourd'hui" : dateObj.getDate();
    const annee = dateObj.getFullYear().toString().substr(-2);
    const heures = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return estAujourdhui ? `${jour}, ${heures}:${minutes}` : `${jour} ${mois} ${annee}, ${heures}:${minutes}`;
  }

  async function fetchMessageInfo(messageID, company_infos) {
    try {
      // Fetch additional info from API for given messageID
      const info = await getMessageInfos(messageID, company_infos);
      if (info) {
        contactReasonMap[info.message_id] = info;
      } else {
      }
    } catch (error) {
      console.log('No contactReasonMap', error);
    }
  }

  async function getMessageInfos(messageID, company_infos_id) {
    const client = new Client();
    const databases = new Databases(client, 'default');
    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');
    return databases.listDocuments('default', 'message', [Query.equal('company', company_infos_id), Query.equal('message_id', messageID)]).then(
      function (response) {
        var data = response.documents;
        return data[0];
      },
      function (error) {
        console.log(error); // Echec
        console.log('Failed to fetch message info:', error);
      }
    );
  }

  async function getMessagesTags(company_infos_id) {
    for (const message of messages) {
      let messageId = '';
      if (typeof message.headers['message-id'] === 'string') {
        messageId = message.headers['message-id'];
      } else if (Array.isArray(message.headers['message-id'])) {
        messageId = message.headers['message-id'][0];
      }
      await fetchMessageInfo(messageId, company_infos_id);
    }
    console.log('getMessageInfo', contactReasonMap);
    const messgTopics = contactReasonMap;
    handleTopicsUpdate(messgTopics);
  }

  const handleTopicsUpdate = (topics) => {
    setMessageInfos(topics);
  };

  const svgs = {
    more_icon: (
      <svg width="27" height="7" viewBox="0 0 27 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1000002886">
          <circle id="Ellipse 1029" cx="3" cy="3.24402" r="3" fill="#563BFF" />
          <circle id="Ellipse 1031" cx="13.4277" cy="3.24402" r="3" fill="#563BFF" />
          <circle id="Ellipse 1030" cx="23.5723" cy="3.24402" r="3" fill="#563BFF" />
        </g>
      </svg>
    ),
  };
  return (
    <React.Fragment>
      <div>
        {/* {!messages || messages.length === 0 ? <div style={{ minHeight: "38vh" , textAlign: "center"}}>Vide</div> : <ul className="p-0" style={{ maxHeight: "38vh", overflowX:"hidden", overflowY:'auto' }}>
          {messages.map((message, index) => (
            <Row key={index} className="email-message">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => goToMessageReadView(message)}
                className=""
              >
                <div key={index} className={`d-flex`}>
                  <div className="d-flex align-items-center">
                    <Badge
                      color={changeColor(message.body.from.value[0].name)}
                      className="rounded-circle align-middle me-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "78.011px",
                        width: "78.011px",
                        flexShrink: "0",
                        padding: "6px",
                        fontSize: "200%",
                      }}
                    >
                      <p style={{ marginBottom: "0" }}>
                        {(
                          message.body.from.value[0].address ??
                          message.body.from.value[0].name
                        )
                          .split(" ")
                          .map((part) => part.charAt(0).toUpperCase())
                          .join("")
                          .slice(0, 2)}
                      </p>
                    </Badge>
                    <div className="d-flex flex-column">
                      <div className="email-message-text">
                        {message.body.from.value[0].name}
                      </div>
                      <div className="email-message-subject">
                        {message.body.subject}
                      </div>
                      <span
                        className="email-message-subject text-muted fw-normal"
                        style={{ margin: "0" }}
                      >
                        {message.snippet}
                      </span>
                      <div className="teaser">{message.body.text}</div>
                      <div>
                        {messageInfos[message.body.messageId] &&
                        messageInfos[message.body.messageId].contact_reasons !==
                          null
                          ? messageInfos[
                              message.body.messageId
                            ].contact_reasons.map((reason, index) => (
                              <div
                                style={{ marginLeft: "10px" }}
                                key={index}
                                className={`badge badge-soft-${changeColor(
                                  reason
                                )} me-1  
                                                    `}
                              >
                                {message.body.subject}
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between ms-auto">
                    <div>
                      <button
                        className=" align-self-start"
                        style={{
                          padding: "0",
                          float: "right",
                          border: "none",
                          background: "none",
                        }}
                      >
                        {svgs.more_icon}
                      </button>
                    </div>

                    <div
                      className="email-message-date align-self-end"
                      style={{ float: "right" }}
                    >
                      {formaterDate(message.attributes.date)}
                    </div>
                  </div>

                  <div>
                    <span className="">
                    </span>
                  </div>
                </div>
              </div>
              <hr className="mails-separator" style={{ margin: "0 24px" }} />
            </Row>
          ))}
        </ul>} */}
        <ul className="p-0" style={{ maxHeight: '38vh', overflowX: 'hidden', overflowY: 'auto' }}>
          {fakeMessages.map((message, index) => (
            <Row key={index} className="email-message">
              <div
                style={{ cursor: 'pointer' }}
                // onClick={() => goToMessageReadView(message)}
                className="">
                <div key={index} className={`d-flex`}>
                  <div className="d-flex align-items-center">
                    <Badge
                      color={changeColor(message.name)}
                      className="rounded-circle align-middle me-3"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '78.011px',
                        width: '78.011px',
                        flexShrink: '0',
                        padding: '6px',
                        fontSize: '200%',
                      }}>
                      <p style={{ marginBottom: '0' }}>
                        {message.name
                          .split(' ')
                          .map((part) => part.charAt(0).toUpperCase())
                          .join('')
                          .slice(0, 2)}
                      </p>
                    </Badge>
                    <div className="d-flex flex-column">
                      <div className="email-message-text">{message.name}</div>
                      <div className="email-message-subject">{message.subject}</div>
                      <span className="email-message-subject text-muted fw-normal" style={{ margin: '0' }}>
                        {message.snippet}
                      </span>
                      <div className="teaser">{message.text}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between ms-auto">
                    <div>
                      <button
                        className=" align-self-start"
                        style={{
                          padding: '0',
                          float: 'right',
                          border: 'none',
                          background: 'none',
                        }}>
                        {svgs.more_icon}
                      </button>
                    </div>

                    <div className="email-message-date align-self-end" style={{ float: 'right' }}>
                      {/* <i className="mdi mdi-link-variant me-2 font-size-15 align-middle"></i> */}
                      {formaterDate(new Date())}
                    </div>
                  </div>

                  <div>
                    <span className="">
                      {/* <div className="email-message-subject mt-2" style={{ marginLeft: "10px" }}>
                                                {getFrenchTag(reason)}
                                            </div> */}
                      {/* {message.body.subject} {" "}
                                            <div className="date" style={{ float: "right" }}>
                                                 {formaterDate(
                                                    message.attributes.date
                                                )}
                                            </div> */}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="mails-separator" style={{ margin: '0 24px' }} />
            </Row>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default EmailList;
