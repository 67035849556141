

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Calender from "../calendrier/calendar";
const Calendrier = () => {

  return (
    <React.Fragment>
      <div className="page-content calendar-content" style={{ paddingLeft: "2em" }}>

      <Row style={{paddingLeft:"8%", paddingRight:"8%"}}>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex flex-column" style={{ width: "fit-content" }}>
            <h4 className="page-title">Calendrier</h4>
          </div>
          </div>
        </Col>
      </Row>
        <Calender></Calender>
      </div>
    </React.Fragment>
  );
};
export default Calendrier;
