import React, { useEffect, useState } from 'react';
const MyCustomSwitch = (props) => {
  const { onClickHandle, notification_key, notification_state } = props;

  const toggleSwitch = () => {
    onClickHandle(!notification_state, notification_key);
  };

  return (
    <>
      <div
        style={{
          height: '45px',
          width: '133px',
          borderRadius: '7.5px',
          position: 'relative',
          display: 'inline-block',
          textAlign: 'left',
          opacity: 1,
          direction: 'ltr',
          transition: 'opacity 0.25s ease 0s',
          touchAction: 'none',
          userSelect: 'none',
          backgroundColor: '#F5F5F5',
        }}
        onClick={toggleSwitch}>
        <div
          style={{
            width: '133px',
            height: '45px',
            borderRadius: '7.5px',
            display: 'flex',
            margin: '0px',
            position: 'relative',
            cursor: 'pointer',
            transition: 'background 0.25s ease 0s',
          }}>
          <div
            style={{
              height: '28px',
              width: '30px',
              position: 'absolute',
              opacity: 1,
              pointerEvents: 'none',
              transition: 'opacity 0.25s ease 0s',
            }}></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: 'rgb(217, 217, 217)',
              transform: notification_state ? 'translate(12px)' : 'translateX(80px)',
              fontFamily: 'Nunito, sans-serif',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              paddingRight: '2px',
            }}>
            {notification_state ? 'NON' : 'OUI'}
          </div>
          <div
            style={{
              height: '80%',
              width: ' 45%',
              transform: notification_state ? 'translate(68px)' : 'translateX(5px)',
              top: '5px',
              background: notification_state ? '#329993' : '#F10944',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#FFF',
              fontFamily: 'Nunito, sans-serif',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              position: 'absolute',
              textAlign: 'left',
              opacity: 1,
              direction: 'ltr',
              borderRadius: '8px',
              transition: 'background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s',
              touchAction: 'none',
              userSelect: 'none',
            }}>
            {notification_state ? 'OUI' : 'NON'}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCustomSwitch;
