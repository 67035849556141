import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import { Client, Account } from 'appwrite';

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner } from 'reactstrap';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Social Media Imports
import { GoogleLogin } from 'react-google-login';
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// actions
import { loginUser, socialLogin } from '../../store/actions';

//Import config
import { facebook, google } from '../../config';

const Login = (props) => {
  document.title = 'Login | iAssistant - Smart chatbot';
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  //const navigate = useNavigate();

  const dispatch = useDispatch();

  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '' || '',
      password: '' || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Veuillez entrer votre Email'),
      password: Yup.string().required('Veuillez entrer votre mot de passe'),
    }),
    onSubmit: (values) => {
      //  dispatch(loginUser(values, props.router.navigate));

      onRequestIsLoading(true);

      const client = new Client();

      const account = new Account(client);

      client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');
      const promise = account.createEmailSession(validation.values.email, validation.values.password);

      promise.then(
        function (response) {
          console.log('Success on session create at login page');
          console.log(response);
          onRequestIsLoading(false);
          localStorage.setItem('authUser', JSON.stringify(response));
          props.router.navigate('/dashboard', {
            replace: false,
          });
        },
        function (error) {
          console.log('error on session check', error);
          onRequestIsLoading(false);
          //   form.error_text = error;
        }
      );
    },
  });

  const { error } = useSelector((state) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, 'google');
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, 'facebook');
  };

  useEffect(() => {
    document.body.className = 'bg-pattern';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages d-flex align-items-center" style={{ minHeight: '100vh', overflow: 'hidden' }}>
        <Container fluid={true}>
          <Row className="justify-content-center m-0">
            <Col lg={12} md={12} xl={10} xxl={10}>
              <div className="text-center">
                <Link to="/">
                  <div
                    className=""
                    style={{
                      marginTop: '3vh',
                      color: '#151d48',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '64px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}>
                    LOGO
                  </div>
                </Link>
              </div>
              <Card className="auth-card">
                <CardBody className="">
                  <Row>
                    <div className="auth-card__left col-xl-6">
                      <div className="auth-card_image-div"></div>
                    </div>
                    <div className="login-card__right col-xl-6 pt-xl-5 pb-xl-5">
                      <Form
                        className="form-horizontal col-10"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                        {error ? (
                          <Alert color="danger">
                            <div>{error}</div>
                          </Alert>
                        ) : null}
                        <Row>
                          <Col md={12}>
                            <div className="mb-4">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Entrer email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ''}
                                invalid={validation.touched.email && validation.errors.email ? true : false}
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.email}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-4">
                              <Label className="form-label">Password</Label>
                              <Input
                                name="password"
                                value={validation.values.password || ''}
                                type="password"
                                placeholder="Entrer Mot de passe"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={validation.touched.password && validation.errors.password ? true : false}
                              />
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  <div> {validation.errors.password} </div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Row className="align-items-center justify-content-between">
                              <Col xl={8}>
                                <div className="form-check">
                                  <input type="checkbox" className="form-check-input" id="customControlInline" />
                                  <label className="form-label form-check-label font-primary" htmlFor="customControlInline">
                                    Se rappeler de moi
                                  </label>
                                </div>
                              </Col>
                              <Col xl={4}>
                                <button
                                  disabled={requestIsLoading}
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{
                                    width: '100%',
                                    backgroundColor: '#FF7049',
                                    border: 'none',
                                    color: '#FFF',
                                    borderRadius: '10px',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '17.005px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '29.152px',
                                    boxShadow: '0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20)',
                                  }}
                                  type="submit">
                                  {requestIsLoading ? 'Chargement...' : 'Connexion'}
                                  {/* <Spinner animation="border" /> */}
                                </button>
                              </Col>
                            </Row>

                            <Row className="align-items-center justify-content-between mt-4">
                              <Col className="">
                                <div
                                  className=""
                                  style={{
                                    color: ' #737791',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                  }}>
                                  Pas encore de compte ?{' '}
                                  <Link to="/register" className="fw-medium text-primary">
                                    {' '}
                                    Ouvrir mon compte{' '}
                                  </Link>{' '}
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <div className="text-md-end mt-3 mt-md-0">
                                  <Link
                                    to="/auth-recoverpw"
                                    className="text-muted"
                                    style={{
                                      color: ' #737791',
                                      fontFamily: 'Poppins, sans-serif',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: 'normal',
                                    }}>
                                    Mot de passe oublié ?
                                  </Link>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-5">
                              <Col xl={5}>
                                <hr style={{ borderTopColor: '#AEB7D6' }} />
                              </Col>
                              <Col xl={2} className="d-flex align-items-center justify-content-center">
                                <span>ou</span>
                              </Col>
                              <Col xl={5}>
                                <hr style={{ borderTopColor: '#AEB7D6' }} />
                              </Col>
                            </Row>

                            <Row className="mt-5 text-center">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={facebookResponse}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="border-none"
                                    style={{
                                      background: '#1877F2',
                                      borderRadius: '10px',
                                      width: '100%',
                                      height: '54px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '15px',
                                    }}
                                    onClick={renderProps.onClick}>
                                    <i className="mdi mdi-facebook" style={{ fontSize: '30px', color: '#fff' }} />
                                    <span
                                      style={{
                                        color: '#FFF',
                                        fontFamily: 'Helvetica',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                      }}>
                                      Connexion avec Facebook
                                    </span>
                                  </Link>
                                )}
                              />
                            </Row>
                            <Row className="mt-4">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="border-none"
                                    style={{
                                      borderRadius: '10px',
                                      background: '#FFF',
                                      boxShadow: '0px 0px 117.2px 0px rgba(0, 0, 0, 0.21)',
                                      width: '100%',
                                      height: '54px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '15px',
                                    }}
                                    onClick={renderProps.onClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                      <rect width="24" height="24" transform="translate(0.5)" fill="white" />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M23.54 12.2605C23.54 11.445 23.4668 10.6609 23.3309 9.9082H12.5V14.3566H18.6891C18.4225 15.7941 17.6123 17.0121 16.3943 17.8275V20.713H20.1109C22.2855 18.7109 23.54 15.7627 23.54 12.2605Z"
                                        fill="#4285F4"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M12.4995 23.5005C15.6045 23.5005 18.2077 22.4708 20.1104 20.7144L16.3938 17.829C15.364 18.519 14.0467 18.9267 12.4995 18.9267C9.50425 18.9267 6.96902 16.9037 6.0647 14.1855H2.22266V17.1651C4.11493 20.9235 8.00402 23.5005 12.4995 23.5005Z"
                                        fill="#34A853"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M6.06523 14.1855C5.83523 13.4955 5.70455 12.7584 5.70455 12.0005C5.70455 11.2425 5.83523 10.5055 6.06523 9.81548V6.83594H2.22318C1.44432 8.38844 1 10.1448 1 12.0005C1 13.8562 1.44432 15.6125 2.22318 17.165L6.06523 14.1855Z"
                                        fill="#FBBC05"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M12.4995 5.07386C14.1879 5.07386 15.7038 5.65409 16.8956 6.79364L20.194 3.49523C18.2024 1.63955 15.5992 0.5 12.4995 0.5C8.00402 0.5 4.11493 3.07705 2.22266 6.83545L6.0647 9.815C6.96902 7.09682 9.50425 5.07386 12.4995 5.07386Z"
                                        fill="#EA4335"
                                      />
                                    </svg>
                                    <span
                                      style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                      }}>
                                      Sign In with Google
                                    </span>
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => {}}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p
                  className=""
                  style={{
                    color: '#737791',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                  }}>
                  Copyright © {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
