import React, { useState, useEffect } from "react";
//import { loadAuth2 } from 'gapi-script';
import { gapi, loadAuth2 } from "gapi-script";
import gmailApi from "react-gmail";
const CLIENT_ID =
  "505437851076-gumpc4op5af3rq22g8bieipo4ma9ut9q.apps.googleusercontent.com";
const SCOPES = "https://www.googleapis.com/auth/gmail.readonly";

const ConfigMailBox = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const [messages, setMessages] = useState([]);

  const handleSignIn = async () => {
    try {
      const auth = await loadAuth2(gapi, CLIENT_ID, SCOPES);
      // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')

      if (!auth.isSignedIn.get()) {
        await auth.signIn();
      }
      setIsSignedIn(auth.isSignedIn.get());
      // Si l'utilisateur est connecté, obtenez l'accès à l'API Gmail.
      if (auth.isSignedIn.get()) {
        const googleUser = auth.currentUser.get();
        const accessToken = googleUser.getAuthResponse().access_token;
        // Utilisez `accessToken` pour accéder à l'API Gmail.
        console.log(`Access token: ${accessToken}`);
        getMessages();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function ObjectPropertiesList(props) {
    const object = props.object;
    const entries = Object.entries(object);

    return (
      <ul>
        {entries.map(([key, value]) => (
          <li key={key}>
            <strong>{key}: </strong>
            {value}
          </li>
        ))}
      </ul>
    );
  }

  const getMessages = async () => {
    const res = await gmailApi.getMessages(false, 15);
    setMessages(gmailApi.normalizeData(res));
  };

  useEffect(() => {
    console.log(messages[1]);
  }, [messages]);
  return (
    <div>
      {isSignedIn ? (
        <div>
          <ul>
            {messages.map((message, index) => (
              <li key={message.id}>
                <div>
                <span>Sender: <br /> {message.from}</span>
                  <br />
                  <br />
                  <span>Object: <br /> {message.subject}</span>
                  <br />
                  <br />
                  <span>
                    APERÇU: <br /> {message.snippet}
                    <br />
                    <br />
                  </span>
                  <span>Contenu:</span>
                  <br />
                  {/*message.body.text.startsWith('<')*/ index == 0 ? (
          <div dangerouslySetInnerHTML={{ __html: message.body.text }}></div>
        ) : (
          <span>{message.body.text}</span>
        )}
                  <p>Date: {message.date}</p>
                  =========================================================
                </div>
              </li>
            ))}
          </ul>
          <button onClick={handleSignIn}>Déconnexion</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Se connecter à Gmail</button>
      )}
    </div>
  );
};

export default ConfigMailBox;
