import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'; // Si vous utilisez React Router
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    TabContent,
    TabPane,
    Progress,
    NavLink,
    NavItem,
    Spinner,
} from "reactstrap";
import layout1 from "../../assets/images/shopify.png";

import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const ShopifyConfiguration = () => {
    const [shopify_store_url, setShopifyStoreUrl] = useState('');
    const [shopify_api_key, setShopifyStoreApiKey] = useState('');
    const [Connected, setConnected] = useState(false);
    // const [isConnected, setIsConnected] = useState(false);
    const [the_error, setThe_error] = useState('');
    const [loading, setLoading] = useState(false);

    // const handleConnect = () => {
    //     const isConnectedSuccessfully = "isConnected";

    //     // Mettre à jour l'état isConnected en fonction du résultat de la connexion
    //     setIsConnected("isConnected");
    // };

    const onLoading = (val) => {
        setLoading(val);
    };

    const fetchProductsData = async () => {
        onLoading(true);
        console.log("shopify_store_url", shopify_store_url)
        console.log("shopify_store_url", shopify_api_key)

        const baseUrl = `https://${shopify_store_url}/admin/api/2022-07/products/count.json`;

        axios.get(baseUrl, {
            headers: {
                'X-Shopify-Access-Token': shopify_api_key,
            },
        }).then((response) => {

            if (response.status === 200) {
                onLoading(false);
                // products_total = the_response.count;
                console.log('response is', response);
            } else {
                console.log('failed', response);
                onLoading(false);
            }

        }).catch(function (error) {
            onLoading(false);
            console.log('response', error.response);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.log(`error.response: ${error}`);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
                console.log(`error.request: ${error}`);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
            }
            if (error.includes("Network Error")) {
                setThe_error("Url incorrect. Veuillez vérifier votre saisie.");
            }  else if (error.includes("401")) {
                setThe_error("Clé API incorrecte. Veuillez vérifier votre saisie ou les autorisations de votre clé API");
            } else {
                setThe_error("Échec. Veuillez vérifier votre saisie ou les autorisations de votre clé API");
            }
        });
        // let response;
        // try {
        //      response = await axios.get(baseUrl, {
        //         headers: {
        //             'X-Shopify-Access-Token': shopify_store_url,
        //         },
        //     });

        //     const the_response = response.data;

        //     if (response.status === 200) {
        //         onLoading(false);
        //         // products_total = the_response.count;
        //         // console.log('products_total is', products_total);
        //     } else {
        //         console.log('failed', the_response);
        //         onLoading(false);
        //     }
        // } catch (error) {
        //     onLoading(false);
        //     console.log('response', response);
        //     if (response && response.status === 401) {
        //         setThe_error("Clé incorrect. Veuillez vérifier votre saisi ou les autorisations de la clé"); 
        //     }
        //     console.error(`Products total fetch failed. Error: ${error}`);
        // }

    };


    useEffect(() => {

    }, []);
    return (
        <Card>
            <CardBody style={{ minHeight: "50vh" }} >
                <img className="mb-4" src={layout1} style={{ width: "150px" }} />

                <h4 className="mb-4">Connexion à votre boutique.</h4>
                <div className="mb-4"> Votre boutique Shopify sera la source d'information pour les activités de vos agents IA. Assurez-vous que les données présentes sur les différentes pages de votre boutique sont à jour.</div>
                <Row className="mb-4">
                    <Col lg="5">
                        <FormGroup className="mb-3">
                            <Label htmlFor="basicpill-vatno-input62">
                                URL de la boutique shopify
                            </Label>

                            <div className="input-group">
                                <div className="input-group-text">https://</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inlineFormInputGroupUsername"
                                    placeholder="Entrez l'url au format boutique.myshopify.com"
                                    value={shopify_store_url}
                                    onChange={(e) => setShopifyStoreUrl(e.target.value)}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg="5">
                        <FormGroup className="mb-3">
                            <Label className="form-label">Clé API SHOPIFY</Label>
                            <Input
                                name="shopify_key"
                                placeholder="Entrez ici votre clé API SHOPIFY"
                                type="text"
                                value={shopify_api_key}
                                onChange={(e) => setShopifyStoreApiKey(e.target.value)}

                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2">
                        <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                            style={{ marginTop: "28px" }}
                            onClick={fetchProductsData}
                        >
                            {loading ? <div style={{ textAlign: "center" }}><Spinner animation="border" /> </div> : "Connexion"}

                        </button>
                    </Col>

                </Row>

                {/* {isConnected && isConnected == "isConnected"   */}
                {/* {Connected && */}
                {/* <div> */}
                {/* {isConnected ? */}
                {/* <Row style={{ width: "60%", border: "solid 1.5px #ececec", borderRadius: '5px', padding: "20px" }}>
                                <div className="row justify-content-center">
                                    <Col lg="9">
                                        <img src={layout1} style={{ width: "80px" }} />
                                    </Col>
                                    <Col lg="3" className="">
                                        <div style={{ border: "solid 2px #92c793", borderRadius: '30px', padding: "6px", textAlign: "center", color: "#92c793" }} >
                                            Connected
                                        </div>
                                    </Col>
                                </div>
                                <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                                    <Col lg="2" className="row justify-content-end" >
                                        <div><i class="bx bxs-store" style={{ fontSize: "30px", backgroundColor: "#bdbdbd", borderRadius: "50%", display: "inline-flex", justifyContent: "center", alignItems: "center", width: "42px", height: "42px", color: "white" }}></i>
                                        </div>
                                    </Col>
                                    <Col lg="10" className="justify-content-start">
                                        <div className="" > Loopz Dev Store </div>
                                        <div className="" > loopz-dev-store.myshopify.com </div>
                                    </Col>
                                </div>
                            </Row> */}
                {/* : */}
                {the_error !== '' &&
                    <Row style={{ marginTop: "25px" }}>
                        <div className="row justify-content-center">
                            <Col lg="12">
                                <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-close-circle-outline text-danger display-4" />
                                    </div>
                                    <div>
                                        <h5>Connexion échouée</h5>
                                        <p className="text-muted">
                                            {the_error}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Row>
                }
                {/* } */}

                {/* </div> */}

                {/* } */}

                <Row>
                    <Col></Col>
                    <Col></Col>
                </Row>


            </CardBody>

        </Card>
    );
};

export default ShopifyConfiguration;
