import React, { useState, useEffect, componentDidMount, useCallback } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Row, FormGroup, Label, InputGroup } from 'reactstrap';
import './stylesDashboard.scss';
import { Client, Account, Databases, Query } from 'appwrite';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

const AreaOne = (props) => {
  const [mailReceive, setMailReceive] = useState();
  const [treatedMailReceive, settreatedMailReceive] = useState();
  const [messagesSentiments, setMessagesSentiments] = useState();
  const [loading, setLoading] = useState(true);
  const [hoverText, setHoverText] = useState([]);
  const [averageResponseTime, setAverageResponseTime] = useState(undefined);

  const theMessages = props.messages;
  const thetreatedMessage = props.treatedMessage;
  const untreatedMessage = props.untreatedMessage;
  const totalProcessedMails = [];

  for (let i = 0; i < thetreatedMessage.length; i++) {
    totalProcessedMails.push(thetreatedMessage[i]);
  }

  for (let i = 0; i < untreatedMessage.length; i++) {
    totalProcessedMails.push(untreatedMessage[i]);
  }

  const handleDateChange = (theData) => {
    var data = theData;
    console.log('data is: ', data);
    setMailReceive(data[0]);
    settreatedMailReceive(data[1]);
    setLoading(false);
    // Récupérer la liste des sentiments
    // const sentiments = data.map((message) => message.client_sentiment);
    // console.log("Liste des sentiments :", sentiments);
    // setMessagesSentiments(sentiments)
  };
  const handleIconMouseEnter = (value, text) => {
    setHoverText([value, text]);
  };

  const handleIconMouseLeave = () => {
    setHoverText('');
  };

  const calculateAverageResponseTime = (messages) => {
    let totalTimeDifference = 0;
    const convertMillisecondsToHMS = (milliseconds) => {
      let seconds = Math.floor((milliseconds / 1000) % 60);
      let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
      let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
      seconds = seconds < 0 ? seconds * -1 : seconds;
      minutes = minutes < 0 ? minutes * -1 : minutes;
      hours = hours < 0 ? hours * -1 : hours;

      // return `${hours}h ${minutes}m ${seconds}s`;
      return `${seconds}`;
    };

    console.log('untreatedMessage is: ', untreatedMessage);

    if (totalProcessedMails && totalProcessedMails.length > 0) {
      totalProcessedMails.forEach((message) => {
        const messageProcessDate = message.message_process_date ?? 0;
        const messageDate = message.message_process_end_date ?? 0;
        const timeDifference = messageDate - messageProcessDate;
        totalTimeDifference += timeDifference;
        console.log('messageProcessDate is: ', messageProcessDate);
        console.log('messageDate is: ', messageDate);
        console.log('timeDifference is: ', timeDifference);
      });

      console.log('totalTimeDifference is: ', totalTimeDifference);
      console.log('totalProcessedMails.length is: ', totalProcessedMails.length);
      const avr = totalTimeDifference / totalProcessedMails.length;
      console.log('calculateAverageResponseTime is: ', convertMillisecondsToHMS(avr));
      setAverageResponseTime(convertMillisecondsToHMS(avr));
      return avr;
    }
  };

  useEffect(() => {
    const today = new Date();
    handleDateChange([theMessages, thetreatedMessage]);
    calculateAverageResponseTime(theMessages);

    // const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0); // Début de la journée
    // const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59); // Fin de la journée
    // getMessageData([startDate, endDate]);
  }, [theMessages, thetreatedMessage]);

  const [btnmenu1, setbtnmenu1] = useState(false);

  const today = new Date();
  const sixDaysAgo = new Date();
  sixDaysAgo.setDate(today.getDate() - 6);

  // Définissez les options pour formater la date
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  // Formatez la date au format "jour mois année"
  const [selectedStartDate, setSelectedStartDate] = useState(sixDaysAgo.toLocaleDateString('fr-FR', options));
  const [selectedEndDate, setSelectedEndDate] = useState(today.toLocaleDateString('fr-FR', options));

  return (
    <React.Fragment>
      <div className="area-one" style={{ marginBottom: '80px' }}>
        <Row>
          <Col xl={3}>
            <Card className="Midnight-Blue">
              <CardBody>
                <div className="d-flex text-muted">
                  {/* <div className="flex-shrink-0 me-3 align-self-center">
                                    <div className="avatar-em">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i className="ri-mail-line"></i>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-white">Mails Entrants</p> <span style={{  fontSize:"13px", color: 'white' }}>(30 derniers jours)</span>
                    {mailReceive ? (
                      // <h5 className="mb-3">{mailReceive.length}</h5>
                      <Row className="">
                        <Col>
                          {/* <h5 className="box-value-white">{theMessages.length}</h5> */}
                          <h5 className="box-value-white">11 734</h5>
                        </Col>
                        <div className="col-auto d-flex align-items-end justify-content-end">
                          <div
                            style={{ position: 'relative' }}
                            className="box-icon-white"
                            onMouseEnter={() => handleIconMouseEnter(1, 'Mails reçus')}
                            onMouseLeave={handleIconMouseLeave}>
                            {hoverText[0] === 1 && (
                              <div
                                className="hover-text"
                                style={{
                                  zIndex: '9999',
                                  height: 'fit-content',
                                  top: '0',
                                  right: '0',
                                  padding: '5px 10px',
                                  fontWeight: '400',
                                  transform: 'translate(-100%, -120%)',
                                }}>
                                {hoverText[1]}
                              </div>
                            )}
                            <i className="mdi mdi-information box-icon-white"></i>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <h5 className="mb-3">Chargement...</h5>
                    )}
                    {/* <p className="text-truncate mb-0">
                                        <span className="text-success me-0">
                                            {" "}
                                            63.01%{" "}
                                            <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                        </span>{" "}
                                        Aujourd'hui
                                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} sm={6}>
            <Card className="cream">
              <CardBody>
                <div className="d-flex text-muted">
                  {/* <div className="flex-shrink-0 me-3 align-self-center">
                                    <div className="avatar-em">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i className="ri-mail-check-line"></i>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-black">Mails traités</p>
                    <span className='color-dark-brown' style={{ fontSize:"13px"}}>(69% des e-mails entrants)</span>
                    {treatedMailReceive ? (
                      // <h5 className="mb-3">{messagesSentiments.length}</h5>
                      <Row className="">
                        <Col>
                          {/* <h5 className="box-value-black">{treatedMailReceive.length}</h5> */}
                          <h5 className="box-value-black">8 096 </h5>
                        </Col>
                        <div className="col-auto d-flex align-items-end justify-content-end">
                          <div
                            style={{ position: 'relative' }}
                            className=""
                            onMouseEnter={() => handleIconMouseEnter(2, 'E-mail traités')}
                            onMouseLeave={handleIconMouseLeave}>
                            {hoverText[0] == 2 && (
                              <div
                                className="hover-text"
                                style={{
                                  zIndex: '9999',
                                  height: 'fit-content',
                                  top: '0',
                                  right: '0',
                                  padding: '5px 10px',
                                  fontWeight: '400',
                                  transform: 'translate(-100%, -120%)',
                                }}>
                                {hoverText[1]}
                              </div>
                            )}
                            <i className="mdi mdi-information box-icon-black"></i>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <h5 className="">Chargement...</h5>
                    )}

                    {/* <p className="text-truncate mb-0">
                                        <span className="text-success me-0">
                                            {" "}
                                            20.01%{" "}
                                            <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                        </span>{" "}
                                        Aujourd'hui
                                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3}>
            <Card className="Midnight-Blue">
              <CardBody>
                <div className="d-flex text-muted">
                  {/* <div className="flex-shrink-0 me-3 align-self-center">
                                    <div className="avatar-em">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i className="ri-coins-line"></i>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-white">Taux Efficacité IA</p>
                    <span>&nbsp;</span>
                    <Row className="">
                      <Col>
                        {/* <h5 className="box-value-white">
                          {thetreatedMessage.length + untreatedMessage.length === 0
                            ? '-- '
                            : ((thetreatedMessage.length * 100) / (thetreatedMessage.length + untreatedMessage.length)).toFixed(0)}
                          %
                        </h5> */}
                        <h5 className="box-value-white">
                          92%
                        </h5>
                      </Col>
                      <div className="col-auto d-flex align-items-end justify-content-end">
                        <div
                          style={{ position: 'relative' }}
                          className="box-icon-white"
                          onMouseEnter={() => handleIconMouseEnter(3, "Chiffre d'affaires")}
                          onMouseLeave={handleIconMouseLeave}>
                          {hoverText[0] === 3 && (
                            <div
                              className="hover-text"
                              style={{
                                zIndex: '9999',
                                height: 'fit-content',
                                top: '0',
                                right: '0',
                                padding: '5px 10px',
                                fontWeight: '400',
                                transform: 'translate(-100%, -120%)',
                              }}>
                              {hoverText[1]}
                            </div>
                          )}{' '}
                          <i className="mdi mdi-information box-icon-white"></i>
                        </div>
                      </div>
                    </Row>
                    {/* <p className="text-truncate mb-0">
                                        <span className="text-success me-0">
                                            {" "}
                                            12.01%{" "}
                                            <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                        </span>{" "}
                                        Aujourd'hui
                                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} sm={6}>
            <Card className="cream">
              <CardBody>
                <div className="d-flex text-muted">
                  {/* <div className="flex-shrink-0 me-3 align-self-center">
                                    <div className="avatar-em">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i className="ri-percent-line"></i>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-black">Temps Épargné</p>
                    <span className="color-dark-brown" style={{ fontSize:"13px"}}>(Basé sur 3min par email)</span>

                    <Row className="">
                      <Col className="d-flex align-items-end">
                        <h5 className="box-value-black">406</h5>
                        <span className="color-dark-brown">HEURES</span>
                      </Col>
                      <div className="col-auto d-flex align-items-end justify-content-end">
                        <div
                          style={{ position: 'relative' }}
                          className=""
                          onMouseEnter={() => handleIconMouseEnter(4, 'Ratio C.a/Email')}
                          onMouseLeave={handleIconMouseLeave}>
                          {hoverText[0] == 4 && (
                            <div
                              className="hover-text"
                              style={{
                                zIndex: '9999',
                                height: 'fit-content',
                                top: '0',
                                right: '0',
                                padding: '5px 10px',
                                fontWeight: '400',
                                transform: 'translate(-100%, -120%)',
                              }}>
                              {hoverText[1]}
                            </div>
                          )}
                          <i className="mdi mdi-information box-icon-black"></i>
                        </div>
                      </div>
                    </Row>

                    {/* <p className="text-truncate mb-0">
                                        <span className="text-success me-0">
                                            {" "}
                                            +13.84%{" "}
                                            <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                                        </span>{" "}
                                        par rapport à la période précédente
                                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={3} sm={6}>
            <Card className="cream mb-0">
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-black">C.A Boutique</p>
                    <span>&nbsp;</span>
                    {treatedMailReceive ? (
                      <Row className="">
                        <Col>
                          <h5 className="box-value-black"> 402 347€</h5>
                        </Col>
                        <div className="col-auto d-flex align-items-end justify-content-end">
                          <div
                            style={{ position: 'relative' }}
                            className=""
                            onMouseEnter={() => handleIconMouseEnter(5, 'C.A Boutique')}
                            onMouseLeave={handleIconMouseLeave}>
                            {hoverText[0] == 5 && (
                              <div
                                className="hover-text"
                                style={{
                                  zIndex: '9999',
                                  height: 'fit-content',
                                  top: '0',
                                  right: '0',
                                  padding: '5px 10px',
                                  fontWeight: '400',
                                  transform: 'translate(-100%, -120%)',
                                }}>
                                {hoverText[1]}
                              </div>
                            )}
                            <i className="mdi mdi-information box-icon-black"></i>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <h5 className="">Chargement...</h5>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3}>
            <Card className="Midnight-Blue mb-0">
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-white">C.A par l'IA</p>
                    <span>&nbsp;</span>
                    {mailReceive ? (
                      <Row className="">
                        <Col>
                          <h5 className="box-value-white">7 642€</h5>
                        </Col>
                        <div className="col-auto d-flex align-items-end justify-content-end">
                          <div
                            style={{ position: 'relative' }}
                            className="box-icon-white"
                            onMouseEnter={() => handleIconMouseEnter(6, "Basé sur des Upsells et des résolutions de problèmes qui conduisent à des ventes supplémentaires")}
                            onMouseLeave={handleIconMouseLeave}>
                            {hoverText[0] == 6 && (
                              <div
                                className="hover-text"
                                style={{
                                  zIndex: '9999',
                                  height: 'fit-content',
                                  top: '0',
                                  right: '0',
                                  padding: '5px 10px',
                                  fontWeight: '400',
                                  transform: 'translate(-100%, -120%)',
                                }}>
                                {hoverText[1]}
                              </div>
                            )}
                            <i className="mdi mdi-information box-icon-white"></i>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <h5 className="mb-3">Chargement...</h5>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} sm={6}>
            <Card className="cream mb-0">
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-black">Réponse Moy. IA</p>
                    <span>&nbsp;</span>
                    <Row className="">
                      <Col className="d-flex align-items-end">
                        {/* <h5 className="box-value-black">{averageResponseTime ?? 0}</h5> */}
                        <h5 className="box-value-black"> 34 </h5>
                        <span className="color-dark-brown">SECONDES </span>
                      </Col>
                      <div className="col-auto d-flex align-items-end justify-content-end">
                        <div
                          style={{ position: 'relative' }}
                          className=""
                          onMouseEnter={() => handleIconMouseEnter(7, 'Temps moyen de génération de réponse automatisée')}
                          onMouseLeave={handleIconMouseLeave}>
                          {hoverText[0] === 7 && (
                            <div
                              className="hover-text"
                              style={{
                                zIndex: '9999',
                                height: 'fit-content',
                                top: '0',
                                right: '0',
                                padding: '5px 10px',
                                fontWeight: '400',
                                transform: 'translate(-100%, -120%)',
                              }}>
                              {hoverText[1]}
                            </div>
                          )}
                          <i className="mdi mdi-information box-icon-black"></i>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3}>
            <Card className="Midnight-Blue mb-0">
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-grow-1">
                    <p className="mb-1 box-title-white">Économ. Salariales</p>
                    <span>&nbsp;</span>
                    <Row className="">
                      <Col>
                        <h5 className="box-value-white">9 861€</h5>
                      </Col>
                      <div className="col-auto d-flex align-items-end justify-content-end">
                        <div
                          style={{ position: 'relative' }}
                          className="box-icon-white"
                          onMouseEnter={() => handleIconMouseEnter(8, "Calculé sur la base d'un coût mensuel de 3 400€ pour un employé à temps plein de 35 heures par semaine")}
                          onMouseLeave={handleIconMouseLeave} >
                          {
                            hoverText[0] == 8 && (
                              <div
                                className="hover-text"
                                style={{
                                  zIndex: '9999',
                                  height: 'fit-content',
                                  top: '0',
                                  right: '0',
                                  padding: '5px 10px',
                                  fontWeight: '400',
                                  transform: 'translate(-100%, -120%)',
                                }}>
                                {hoverText[1]}
                              </div>
                            )
                          }
                          < i className="mdi mdi-information box-icon-white" ></i>
                        </div>
                       
                      </div>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row >
      </div >
    </React.Fragment >
  );
};

export default AreaOne;
