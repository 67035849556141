import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import VectorMaps from "./Maps"

const Chat = () => {

  return (
    <React.Fragment>
      <div className="page-content" style={{ paddingLeft: "2em" }}>

        <Breadcrumbs title="iAssistant" breadcrumbItem="Chat" />
      </div>
    </React.Fragment>
  );
};
export default Chat;
