export default function getBoxLocalName(folder) {
  switch (folder) {
    ///Drafts
    case 'INBOX.MAIA.Drafts':
      return 'Brouillon';
    case 'MAIA.Drafts':
      return 'Brouillon';
    case 'Drafts':
      return 'Brouillon';
    case 'INBOX.INBOX.Drafts':
      return 'Brouillon';
    case 'INBOX.Drafts':
      return 'Brouillon';

    ///Archive
    case 'INBOX.MAIA.Archive':
      return 'Archive';
    case 'MAIA.Archive':
      return 'Archive';
    case 'Archive':
      return 'Archive';
    case 'INBOX.Archive':
      return 'Archive';

    ///Junk
    case 'INBOX.MAIA.Junk':
      return 'Ordure';
    case 'INBOX.INBOX.Junk':
      return 'Ordure';
    case 'INBOX.Junk':
      return 'Ordure';
    case 'Junk':
      return 'Ordure';

    ///Trash
    case 'INBOX.MAIA.Trash':
      return 'Corbeille';
    case 'Trash':
      return 'Corbeille';
    case 'INBOX.INBOX.Trash':
      return 'Corbeille';

    ///Untreated
    case 'INBOX.MAIA.Untreated':
      return 'Non traités';
    case 'Untreated':
      return 'Non traités';
    case 'INBOX.Untreated':
      return 'Non traités';

    ///Treated
    case 'INBOX.MAIA.Treated':
      return 'Traités';
    case 'Treated':
      return 'Traités';
    case 'INBOX.Treated':
      return 'Traités';
    case 'Spam':
      return 'Spam';

    ///SENT
    case 'INBOX.MAIA.Sent':
      return 'Envoyés';
    case 'Sent':
      return 'Envoyés';
    case 'INBOX.INBOX.Sent':
      return 'Envoyés';
    case 'INBOX.Sent':
      return 'Envoyés';
    case 'INBOX.Sent Messages':
      return 'Envoyés';
    case 'Sent Messages':
      return 'Envoyés';
    case 'INBOX.INBOX.Sent Items':
      return 'Envoyés';
    case 'Sent Items':
      return 'Envoyés';

    ///Starred
    case 'INBOX.MAIA.Starred':
      return 'Suivis';
    case 'Starred':
      return 'Suivis';

    ///Deleted
    case 'INBOX.MAIA.Deleted Messages':
      return 'Supprimés';
    case 'Deleted Messages':
      return 'Supprimés';
    case 'INBOX.Deleted Messages':
      return 'Supprimés';
    case 'Deleted':
      return 'Supprimés';
    case 'INBOX.Deleted':
      return 'Supprimés';
    case 'INBOX.Deleted Items':
      return 'Supprimés';
    case 'Deleted Items':
      return 'Supprimés';

    default:
      return folder.includes('INBOX.MAIA')
        ? folder.slice(11).charAt(0).toUpperCase() + folder.slice(11).slice(1).toLowerCase()
        : folder.includes('MAIA')
        ? folder.slice(5).charAt(0).toUpperCase() + folder.slice(5).slice(1).toLowerCase()
        : folder.charAt(0).toUpperCase() + folder.slice(1).toLowerCase();
  }
}
