import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// action
import { registerUser, apiError } from '../../store/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

// import images
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

const Register = (props) => {
  document.title = 'Register | iAssistant - Smart chatbot';

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      username: Yup.string().required('Please Enter Your Username'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  const { user, registrationError } = useSelector((state) => ({
    user: state.account.user,
    registrationError: state.account.registrationError,
  }));

  // handleValidSubmit
  // const handleValidSubmit = values => {
  //   dispatch(registerUser(values));
  // };

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);

  useEffect(() => {
    document.body.className = 'bg-pattern';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    // <div className="bg-pattern" style={{height:"100vh"}}>
    <div className="account-pages d-flex align-items-center" style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Container fluid={true}>
        <Row className="justify-content-center m-0">
          <Col lg={12} md={12} xl={10} xxl={10}>
            <div className="text-center">
              <Link to="/" className="">
                <div
                  className=""
                  style={{
                    marginTop: '3vh',
                    color: '#151d48',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '64px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}>
                  LOGO
                </div>
              </Link>
            </div>
            <Card className="auth-card">
              <CardBody className="">
                <Row>
                  <div className="auth-card__left col-xl-6">
                    <div className="auth-card_image-div"></div>
                  </div>
                  <div className="login-card__right col-xl-6 pt-xl-5 pb-xl-5">
                    <Form
                      className="form-horizontal col-10"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      {user && user ? <Alert color="success">Register User Successfully</Alert> : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">
                          <div>{registrationError}</div>
                        </Alert>
                      ) : null}

                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ''}
                              invalid={validation.touched.username && validation.errors.username ? true : false}
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.username}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row className="align-items-center justify-content-between">
                            <Col className="col-auto">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="term-conditionCheck" />
                                <label className="form-label fw-normal" htmlFor="term-conditionCheck">
                                  I accept{' '}
                                  <Link to="#" className="text-primary">
                                    Terms and Conditions
                                  </Link>
                                </label>
                              </div>
                            </Col>
                            <Col className="col-xl-auto col-xxl-3">
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                style={{
                                  width: '100%',
                                  backgroundColor: '#FF7049',
                                  border: 'none',
                                  color: '#FFF',
                                  borderRadius: '10px',
                                  fontFamily: 'Poppins, sans-serif',
                                  fontSize: '17.005px',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '29.152px',
                                  boxShadow: '0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20)',
                                }}
                                type="submit">
                                Register
                              </button>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-between mt-4">
                            <p
                              style={{
                                color: ' #737791',
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                              }}
                              className="">
                              Already have an account ?
                              <Link to="/login" className="fw-medium text-primary">
                                {' '}
                                Login{' '}
                              </Link>{' '}
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 text-center">
              <p
                className=""
                style={{
                  color: '#737791',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                }}>
                Copyright © {new Date().getFullYear()}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    // </div>
  );
};

export default Register;
