import React, { useState, useEffect, componentDidMount } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Form,
  Col,
  CardBody,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Label,
  Progress,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import classnames from 'classnames';
//import InboxSidebar2 from "./Sidebar2";

//import { loadAuth2 } from 'gapi-script';
import { gapi, loadAuth2 } from 'gapi-script';
import gmailApi from 'react-gmail';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import avatar_1 from '../../assets/images/users/avatar-1.jpg';
import avatar_2 from '../../assets/images/users/avatar-2.jpg';
import avatar_3 from '../../assets/images/users/avatar-3.jpg';
import avatar_4 from '../../assets/images/users/avatar-4.jpg';
import avatar_5 from '../../assets/images/users/avatar-5.jpg';
import avatar_6 from '../../assets/images/users/avatar-6.jpg';
import avatar_7 from '../../assets/images/users/avatar-7.jpg';
import gmail_icon from '../../assets/images/mail-server-gmail_103.png';
import outlook_icon from '../../assets/images/mail-server-outlook_103.png';
import mail_custom_icon from '../../assets/images/mail-server-custom_103.png';
import ovh_icon from '../../assets/images/ovh-icon.png';
import hostinger_icon from '../../assets/images/hostinger.png';
import godaddy_icon from '../../assets/images/GoDaddy-Logo.wine.png';
import informaniak_icon from '../../assets/images/Infomaniak-1.png';
import { Client, Account, Databases } from 'appwrite';
import axios from 'axios';

//import '../../assets/css/icons.min.css';
import '../../assets/css/app.min.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const CLIENT_ID = '505437851076-gumpc4op5af3rq22g8bieipo4ma9ut9q.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

const SettingEmailConfig = () => {
  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
      password1: '',
      email: '',
      digits: '',
      number: '',
      alphanumeric: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('This value is required'),
      password: Yup.string().required('This value is required'),
      password1: Yup.string().when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
      }),
      email: Yup.string().email('Must be a valid Email').max(255).required('Email is required'),
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!'
        )
        .required('Please enter correct Url'),
      digits: Yup.number().required('Please Enter Your Digits'),
      number: Yup.number().required('Please Enter Your Number'),
      alphanumeric: Yup.string()
        .matches(/^[a-z0-9]+$/i, 'Enter correct Alphanumeric!')
        .required('Please Enter Your Alphanumeric'),
      textarea: Yup.string().required('Please Enter Your Textarea'),
    }),
    onSubmit: (values) => {
      console.log('values', values);
    },
  });

  const navigate = useNavigate();

  const [activeTab, setactiveTab] = useState(1);
  const [activeTabwiz, setoggleTabwiz] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedStepswiz, setpassedStepswiz] = useState([1]);
  const [auto_response_level, setAutoResponseLevel] = useState(5);

  const labels = {
    1: '10',
    2: '20',
    3: '30',
    4: '40',
    5: '50',
    6: '60',
    7: '70',
    8: '80',
    9: '90',
    10: '100',
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTabwiz(tab) {
    if (activeTabwiz !== tab) {
      var modifiedSteps = [...passedStepswiz, tab];
      if (tab >= 1 && tab <= 4) {
        setoggleTabwiz(tab);
        setpassedStepswiz(modifiedSteps);
      }
    }
  }

  const [folderbtn, setfolderbtn] = useState(false);
  const [tagbtn, settagbtn] = useState(false);
  const [menubtn, setmenubtn] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesToRead, setMessagesToRead] = useState();
  const [selectedServer, setSelectedServer] = useState(undefined);
  const [host, setHost] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [port, setPort] = useState('');
  const [smtp_host, setSMTPHost] = useState('');
  const [smtp_port, setSMTPPort] = useState('');
  const [tls_mode, setTlsMode] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [company_infos, setCompanyInfos] = useState(undefined);
  const [site_name, setSiteName] = useState('');
  const [site_url, setSiteUrl] = useState('');
  const [shopify_store_url, setShopifyStoreUrl] = useState('');
  const [shopify_api_key, setShopifyStoreApiKey] = useState('');
  const [signature, setSignature] = useState('');
  const [site_infos_more, setSiteInfosMore] = useState('');
  const [contactReasonValues, setContactReasonValues] = useState({});
  const contact_reasons_array = [
    {
      contact_reasons: 'product_info',
      explanation:
        "Demandes d'informations supplémentaires sur un produit spécifique, comme ses caractéristiques et matériaux. Ceci exclut tout conseil sur son utilisation ou problème technique.",
      french_text: 'INFORMATION PRODUIT',
    },
    {
      contact_reasons: 'product_availability',
      explanation: "Demandes concernant la disponibilité en stock d'un produit spécifique, sans référence à son prix ou à toute promotion.",
      french_text: 'DISPONIBILITÉ PRODUIT',
    },
    {
      contact_reasons: 'product_advice',
      explanation:
        "Demandes de conseils pour l'utilisation d'un produit, hors problèmes techniques ou questions concernant les caractéristiques spécifiques du produit.",
      french_text: 'CONSEILS PRODUITS',
    },
    {
      contact_reasons: 'product_problems',
      explanation:
        "Demandes liées aux problèmes de produit qui ne sont pas liées à la livraison ou au service après-vente. Ce tag ne concerne pas les problèmes liés à l'utilisation du produit, qui relèvent des conseils sur un produit.",
      french_text: 'PROBLÈMES PRODUITS',
    },
    {
      contact_reasons: 'product_price',
      explanation: "Demandes d'informations sur les prix des produits sans référence aux promotions ou à la disponibilité du produit.",
      french_text: 'PRIX',
    },
    {
      contact_reasons: 'promotions',
      explanation: 'Questions spécifiquement liées aux promotions en cours, sans référence au prix normal du produit.',
      french_text: 'PROMOTIONS',
    },
    {
      contact_reasons: 'order_process',
      explanation: 'Questions sur le processus de commande, excluant les problèmes de paiement, de remboursement et de livraison.',
      french_text: 'PROCESSUS COMMANDE',
    },
    {
      contact_reasons: 'payments',
      explanation: "Demandes spécifiques aux problèmes liés au paiement d'une commande, sans référence au processus de commande ou au remboursement.",
      french_text: 'PAIEMENTS',
    },
    {
      contact_reasons: 'refunds',
      explanation: 'Demandes spécifiques aux remboursements et non aux autres aspects de la facturation ou du paiement.',
      french_text: 'REMBOURSEMENTS',
    },
    {
      contact_reasons: 'disputes',
      explanation:
        'Demandes liées à des litiges concernant des transactions commerciales, sans référence directe aux remboursements, aux paiements ou au processus de commande.',
      french_text: 'LITIGES',
    },
    {
      contact_reasons: 'billing',
      explanation: 'Demandes liées à la facturation hors remboursements, paiements ou litiges.',
      french_text: 'FACTURATION',
    },
    {
      contact_reasons: 'delivery',
      explanation: 'Demandes uniquement liées au processus de livraison, sans référence au retour de produit ou au processus de commande.',
      french_text: 'LIVRAISON',
    },
    {
      contact_reasons: 'returns',
      explanation: "Demandes spécifiquement liées au retour d'un produit, sans référence à la livraison ou au service après-vente.",
      french_text: 'RETOUR',
    },
    {
      contact_reasons: 'customer_account',
      explanation: 'Demandes liées aux informations du compte, hors problèmes de mot de passe ou problèmes de sécurité.',
      french_text: 'COMPTE CLIENT',
    },
    {
      contact_reasons: 'password',
      explanation: "Demandes spécifiques à la réinitialisation d'un mot de passe, sans référence à d'autres aspects de la gestion du compte client.",
      french_text: 'MOT DE PASSE',
    },
    {
      contact_reasons: 'security',
      explanation:
        'Demandes concernant la sécurité du site et les problèmes de fraude, sans référence à la confidentialité des données ou à la gestion du compte client.',
      french_text: 'SÉCURITÉ',
    },
    {
      contact_reasons: 'privacy',
      explanation:
        "Demandes sur la politique de confidentialité et l'utilisation des données par l'entreprise, sans référence à la sécurité du site ou à la manière dont les données sont stockées.",
      french_text: 'CONFIDENTIALITÉ',
    },
    {
      contact_reasons: 'customer_data',
      explanation:
        'Demandes sur la manière dont les données des clients sont stockées et protégées, sans référence à la politique de confidentialité ou à la sécurité du site.',
      french_text: 'DONNÉES CLIENTS',
    },
    {
      contact_reasons: 'after_sales',
      explanation:
        'Demandes adressées au service après-vente, exclusivement pour les réparations. Cela exclut les problèmes liés aux produits eux-mêmes.',
      french_text: 'SAV',
    },
    {
      contact_reasons: 'technical_problems',
      explanation: 'Demandes liées aux problèmes techniques spécifiques au site web, sans référence à la sécurité du site.',
      french_text: 'PROBLÈMES TECHNIQUES',
    },
    {
      contact_reasons: 'website_feedback',
      explanation: 'Demandes pour donner des commentaires sur le site web, sans référence aux problèmes techniques.',
      french_text: 'COMMENTAIRES SITE WEB',
    },
    {
      contact_reasons: 'special_programs',
      explanation: "Demandes liées à des programmes qui ne sont pas liés à la fidélisation ou à l'affiliation.",
      french_text: 'PROGRAMMES SPÉCIAUX',
    },
    {
      contact_reasons: 'affiliation',
      explanation: "Demandes spécifiques à l'affiliation commerciale, sans référence aux programmes spéciaux ou à la fidélisation.",
      french_text: 'AFFILIATION',
    },
    {
      contact_reasons: 'customer_loyalty',
      explanation: 'Demandes liées aux programmes de fidélisation, excluant les autres types de programmes et les programmes spéciaux.',
      french_text: 'FIDÉLISATION CLIENTÈLE',
    },
    {
      contact_reasons: 'feedback',
      explanation: 'Demandes de feedbacks ou commentaires sur les produits, sans référence aux plaintes.',
      french_text: 'FEEDBACKS',
    },
    {
      contact_reasons: 'complaints',
      explanation: 'Demandes spécifiques liées à des plaintes de la part des clients, sans référence aux feedbacks.',
      french_text: 'PLAINTES',
    },
    {
      contact_reasons: 'company_information',
      explanation: "Demandes d'informations générales sur l'entreprise, sans référence à l'éthique ou à la responsabilité sociale de l'entreprise.",
      french_text: 'INFORMATION ENTREPRISE',
    },
    {
      contact_reasons: 'ethics_social_responsibility',
      explanation:
        "Demandes concernant la responsabilité sociale de l'entreprise et les pratiques éthiques, sans référence aux informations générales sur l'entreprise.",
      french_text: 'ÉTHIQUE & RSE',
    },
    {
      contact_reasons: 'subscription_management',
      explanation:
        "Demandes spécifiques à la gestion des abonnements, comme par exemple l'inscription à la newsletter, sans référence à la gestion du compte client.",
      french_text: 'GESTION ABONNEMENTS',
    },
    {
      contact_reasons: 'store_services',
      explanation: 'Demandes spécifiques aux services proposés en magasin, sans référence aux produits ou aux services en ligne.',
      french_text: 'SERVICES MAGASINS',
    },
    {
      contact_reasons: 'influencers',
      explanation:
        'Demandes liées aux collaborations avec des influenceurs, sans référence aux collaborations avec des créateurs de contenu non-influenceurs.',
      french_text: 'INFLUENCEURS',
    },
    {
      contact_reasons: 'content_creators',
      explanation: 'Demandes liées aux collaborations avec des créateurs de contenu qui ne sont pas des influenceurs.',
      french_text: 'CRÉATEURS CONTENUS',
    },
    {
      contact_reasons: 'spam',
      explanation: "Demandes où l'IA détecte des comportements de spam, sans référence à d'autres problèmes de sécurité.",
      french_text: 'SPAM',
    },
  ];

  //
  const [btnprimary1, setBtnprimary1] = useState(false);

  const toggleContactReason = (reason) => {
    if (contactReasonValues[reason.contact_reasons]) {
      const updatedValues = { ...contactReasonValues };
      delete updatedValues[reason.contact_reasons];
      setContactReasonValues(updatedValues);
    } else {
      const updatedValues = { ...contactReasonValues };
      updatedValues[reason.contact_reasons] = reason.contact_reasons;
      setContactReasonValues(updatedValues);
    }
  };

  const onSelectedServerChanged = (val) => {
    setSelectedServer(val);
  };

  const onLoadingChanged = (val) => {
    setLoading(val);
  };
  // const timer = setTimeout(() => { }, 20000);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const signOut = async () => {
    var auth2 = gapi.auth2.getAuthInstance();
    await auth2.signOut();
    console.log('User signed out.');
    if (!auth2.isSignedIn.get()) {
      setIsSignedIn(auth2.isSignedIn.get());

      setToastMessage('Vous avez révoqué la synchronisation à votre boite e-mail.');
      toggleToast();
    }
  };

  async function createCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'client_infos', localStorage.getItem('companyId'), data);

    promise.then(
      async function (response) {
        console.log(response); // Success
        await launchServer(40000, 1);
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage('Echec : ' + error.message);
        toggleToast();

        console.log('createCompanyInfos', error); // Failure
      }
    );
  }

  async function updateCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, 'default');

    console.log('updateCompanyInfos', JSON.stringify(data));
    console.log('new data to save', data);
    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.updateDocument('default', 'client_infos', localStorage.getItem('companyId'), JSON.stringify(data));

    promise.then(
      async function (response) {
        onLoadingChanged(false);
        setToastMessage('Modifications effectuées');
        toggleToast();
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage('Echec: ' + error.message);
        toggleToast();

        console.log(error); // Failure
      }
    );
  }

  const getCompanyInfos = async function () {
    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.getDocument('default', 'client_infos', localStorage.getItem('companyId'));

    promise.then(
      function (response) {
        //    console.log(response);
        setCompanyInfos(response);
        setSiteName(response.company ?? '');
        setSiteUrl(response.company_website_url ?? '');
        setSiteInfosMore(response.more_info ?? '');
        setShopifyStoreUrl(response.shopify_store_url ?? '');
        setShopifyStoreApiKey(response.shopify_api_key ?? '');
        setSignature(response.signature ?? '');

        setEmail(response.email ?? '');
        if (response.email) {
          if (response.email.includes('@gmail')) {
            onSelectedServerChanged('gmail');
          } else if (response.email.includes('@outllook')) {
            onSelectedServerChanged('outllook');
          } else {
            onSelectedServerChanged('custom');
          }
        }
        setPassword(response.password ?? '');
        setPort(response.port ?? '');
        setHost(response.host ?? '');
        setSMTPHost(response.smtp_host ?? '');
        setSMTPPort(response.smtp_port ?? '');
        setTlsMode(response.tls);

        setAutoResponseLevel(response.auto_response_level ?? 0);
        const reasons = response.contact_reasons;
        console.log('reasons', reasons);
        const reasonMap = {};
        for (const reason of reasons) {
          reasonMap[reason] = reason;
        }
        setContactReasonValues(reasonMap);
        console.log('reasonsMap', reasonMap);
        return response;
      },
      function (error) {
        console.log(error); // Failure
        return null;
      }
    );
  };

  /*
  const checkUserPrefs = async function () {
    const client = new Client();
 
    const account = new Account(client);
 
    client
      .setEndpoint('https://api.shopybase.com/v1')
      .setProject('ai_project_dev');
 
 
    const promise = account.getPrefs();
 
    promise.then(function (response) {
      console.log(response);
 
      setEmail(response["email"] ?? "");
      setPassword(response["password"] ?? "");
      setPort(response["port"] ?? "");
      setHost(response["host"] ?? "");
      setSMTPHost(response["smtp_host"] ?? "");
      setSMTPPort(response["smtp_port"] ?? "");
      setTlsMode(response["tls"] ?? "");
      
      setAutoResponseLevel(response["auto_response_level"] ?? 0);
 
      return response;
    }, function (error) {
      console.log(error); // Failure
      return null;
    });
  }
*/

  /*
    async function saveUserPrefs(data) {
      setLoading(true);
  
      const client = new Client();
  
      const account = new Account(client);
  
      client
        .setEndpoint('https://api.shopybase.com/v1')
        .setProject('ai_project_dev');
  
  
      const promise = account.updatePrefs(data);
  
      promise.then(function (response) {
  
        console.log(response); // Success
      }, function (error) {
  
        console.log(error); // Failure
      });
    }
    */

  async function validateConfig() {
    if (!company_infos) {
      await createCompanyInfos({
        company: site_name,
        company_website_url: site_url,
        more_info: site_infos_more,
        shopify_store_url: shopify_store_url,
        shopify_api_key: shopify_api_key,
        signature: signature,
        email: email,
        password: password,
        host: host,
        port: port,
        smtp_host: smtp_host,
        smtp_port: smtp_port,
        auto_response_level: auto_response_level,
        tls: tls_mode,
        mail_config: true,
        server_active: false,
      });
    } else {
      await updateCompanyInfos({
        company: site_name,
        company_website_url: site_url,
        more_info: site_infos_more,
        shopify_store_url: shopify_store_url,
        shopify_api_key: shopify_api_key,
        signature: signature,
        email: email,
        password: password,
        host: host,
        port: port,
        smtp_host: smtp_host,
        smtp_port: smtp_port,
        auto_response_level: auto_response_level,
        tls: tls_mode,
        mail_config: true,
        // server_active: false,
        contact_reasons: Object.keys(contactReasonValues),
      });
    }
  }

  async function updateConfig() {
    const companyId = localStorage.getItem('companyId');

    if (companyId) {
      await updateCompanyInfos({
        //    company: site_name,
        //    company_website_url: site_url,
        //    more_info: site_infos_more,
        //    shopify_store_url: shopify_store_url,
        //    shopify_api_key: shopify_api_key,
        //    signature: signature,
        email: email,
        password: password,
        host: host,
        port: port,
        smtp_host: smtp_host,
        smtp_port: smtp_port,
        //    auto_response_level: auto_response_level,
        tls: tls_mode,
        mail_config: true,
        // server_active: false,
        //   contact_reasons: Object.keys(contactReasonValues)
      });
    } else {
      console.log('no company id');
    }
  }

  const requiredValidations = () => {
    if (
      email &&
      email !== null &&
      email.length > 5 &&
      host &&
      host !== null &&
      host !== '' &&
      port &&
      port !== null &&
      port !== '' &&
      site_name &&
      site_name !== null &&
      site_name !== '' &&
      site_url &&
      site_url !== null &&
      site_url !== '' &&
      shopify_store_url &&
      shopify_store_url !== null &&
      shopify_store_url !== '' &&
      shopify_api_key &&
      shopify_api_key !== null &&
      shopify_api_key !== '' &&
      site_infos_more &&
      site_infos_more !== null &&
      site_infos_more !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  async function launchServer(lport, count) {
    const companyId = localStorage.getItem('companyId');

    axios
      .post(
        'https://mailer-ai.semecloud.tech/launch-mailer',
        // "http://localhost:30000/launch-mailer",
        {
          id: companyId,
          company_id: companyId,
        }
      )
      .then(async (response) => {
        console.log(`Server launched on port ${lport}. Launch response is`, response);

        // company_infos.server_active = true;

        await updateCompanyInfos({ server_active: true });
        goToInboxPage();
      })
      .catch(async (error) => {
        console.log(`Failed starting Agent ${lport}: `, error);
      });
  }

  function autoConfigServerInfos(server) {
    onSelectedServerChanged(server);
    if (server === 'gmail') {
      setEmail('@gmail.com');
      // IMAP
      setHost('imap.gmail.com');
      setPort('993');
      // SMTP
      setSMTPHost('');
      setSMTPPort('');
    } else if (server === 'outlook') {
      setHost('outlook.office365.com');
      setEmail('@outlook.com');
      setPort('993');
      setSMTPHost('');
      setSMTPPort('');
    } else if (server === 'godaddy') {
      setEmail('');
      setHost('imap.secureserver.net');
      setSMTPHost('smtpout.secureserver.net');
      if (tls_mode) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('80');
      }
    } else if (server === 'hostinger') {
      setEmail('');
      setHost('imap.hostinger.com');
      setSMTPHost('smtp.hostinger.com');
      if (tls_mode) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('');
        setSMTPPort('25');
      }
    } else if (server === 'infomaniak') {
      setEmail('');
      setHost('mail.infomaniak.com');
      setSMTPHost('mail.infomaniak.com');
      if (tls_mode) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('25');
      }
    } else if (server === 'ovh') {
      setEmail('');
      setHost('ss10.ovh.net');
      setSMTPHost('ss10.ovh.net');
      if (tls_mode) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('');
      }
    } else {
      setHost('');
      setEmail('');
      setPort('');
      setSMTPHost('');
      setSMTPPort('');
    }
  }

  const onTlsModeChanged = (val) => {
    if (selectedServer === 'gmail') {
    } else if (selectedServer === 'outlook') {
    } else if (selectedServer === 'godaddy') {
      if (val) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('80');
      }
    } else if (selectedServer === 'hostinger') {
      if (val) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('');
        setSMTPPort('25');
      }
    } else if (selectedServer === 'infomaniak') {
      if (val) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('25');
      }
    } else if (selectedServer === 'ovh') {
      if (val) {
        setPort('993');
        setSMTPPort('465');
      } else {
        setPort('143');
        setSMTPPort('');
      }
    } else {
    }
  };

  const handleSignIn = async () => {
    try {
      const auth = await loadAuth2(gapi, CLIENT_ID, SCOPES);
      // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')

      if (!auth.isSignedIn.get()) {
        await auth.signIn();
      }
      setIsSignedIn(auth.isSignedIn.get());
      // Si l'utilisateur est connecté, obtenez l'accès à l'API Gmail.
      if (auth.isSignedIn.get()) {
        const googleUser = auth.currentUser.get();
        const accessToken = googleUser.getAuthResponse().access_token;
        // Utilisez `accessToken` pour accéder à l'API Gmail.
        console.log(`Access token: ${accessToken}`);
        // getMessages();

        navigate('/main-inbox');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadGmailEmailsIfConnected = async () => {
    try {
      const auth = await loadAuth2(gapi, CLIENT_ID, SCOPES);
      // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')

      setIsSignedIn(auth.isSignedIn.get());
      // Si l'utilisateur est connecté, obtenez l'accès à l'API Gmail.
      if (auth.isSignedIn.get()) {
        const googleUser = auth.currentUser.get();
        const accessToken = googleUser.getAuthResponse().access_token;
        // Utilisez `accessToken` pour accéder à l'API Gmail.
        console.log(`Access token: ${accessToken}`);
        getMessages();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMessages = async () => {
    const res = await gmailApi.getMessages(false, 15);
    setMessages(gmailApi.normalizeData(res));
  };

  const toggleToast = () => {
    console.log('setToast before: ' + toast);
    setToast(true);
    console.log('setToast after: ' + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  function goToInboxPage() {
    navigate('/main-inbox', {
      replace: false,
    });
  }
  useEffect(() => {
    getCompanyInfos();
    // loadGmailEmailsIfConnected();
    // return () => clearTimeout(timer);
  }, []);

  const svgs = {
    check_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
        <path
          d="M20.9949 2.0168C20.8615 2.5003 20.5279 2.84638 20.1842 3.18738C16.4077 6.92815 12.6313 10.674 8.86001 14.4199C8.44439 14.8321 7.96721 15.051 7.37201 14.9899C6.96153 14.9492 6.61775 14.7609 6.33041 14.4759C4.39089 12.5571 2.45136 10.6435 0.52723 8.71456C-0.334782 7.84934 -0.103886 6.5159 0.958235 5.99677C1.62014 5.67105 2.36927 5.81355 2.94394 6.38357C4.16512 7.59487 5.38631 8.80617 6.60749 10.0175C6.88456 10.2923 7.16164 10.562 7.43358 10.8369C7.53107 10.9387 7.60291 10.9387 7.7004 10.8369C7.9826 10.5468 8.2802 10.2618 8.56754 9.97166C11.7077 6.85689 14.853 3.73704 17.9932 0.622277C18.4088 0.210029 18.8758 -0.0444449 19.4863 0.00644996C20.1483 0.0624343 20.7383 0.520488 20.9333 1.15158C20.9538 1.20757 20.9795 1.26355 21 1.31954C20.9949 1.55365 20.9949 1.78268 20.9949 2.0168Z"
          fill="white"
        />
      </svg>
    ),
  };
  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true} style={{ maxWidth: '100%', paddingLeft: '8%', paddingRight: '8%' }} className="email">
          <Col lg="12">
            <Card style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
              <CardBody style={{ padding: '0' }}>
                <Row className="align-items-center justify-content-between flex-row mb-3">
                  <div style={{ width: 'fit-content' }}>
                    <h4 className="section-title mb-0">Boite e-mail</h4>
                  </div>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Card className="mail-config_card" style={{ borderRadius: '15px' }}>
                      <CardBody className="mail-config_body" style={{ borderRadius: '15px', background: '#FFF', padding: '59px 39px 60px 39px' }}>
                        <h4 className="card-title">Configuration de votre boite e-mail</h4>
                        <p className="card-subtitle mb-3">Sélectionnez votre serveur de messagerie pour continuer.</p>
                        {/* <Row className="justify-content-center mt-3">
                          <Col lg={8}>
                            
                            <Row>
                              <Col lg={3}>
                                <div
                                  onClick={() => autoConfigServerInfos("gmail")}
                                  className={`border ${selectedServer === "gmail" ? 'border-success' : ''} p-3 text-center rounded mb-4 `}
                                >
                                  <Link to="">
                                    <div className="my-3">
                                      <img
                                        src={gmail_icon}
                                        className="avatar-sm"
                                        alt=""
                                      ></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3">Gmail</h5>
                                  </Link>
                                </div>
                              </Col>

                              <Col lg={3}>
                                <div onClick={() => autoConfigServerInfos("outlook")}
                                  className={`border ${selectedServer === "outlook" ? 'border-success' : ''} p-3 text-center rounded mb-4 `}
                                >
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={outlook_icon}
                                        className="avatar-sm"
                                        alt=""
                                      ></img>{" "}
                                    </div>
                                    <h5 className="font-size-15 mb-3">
                                      Outlook
                                    </h5>
                                  </Link>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div onClick={() => autoConfigServerInfos("custom")}
                                  className={`border ${selectedServer === "custom" ? 'border-success' : ''} p-3 text-center rounded mb-4 `}
                                >
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={mail_custom_icon}
                                        className="avatar-sm"
                                        alt=""
                                      ></img>{" "}
                                    </div>
                                    <h5 className="font-size-15 mb-3">
                                      Autres
                                    </h5>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row> */}
                        <Col lg={12}>
                          <Row className="" style={{ marginBottom: '40px', marginTop: '45px' }}>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('gmail')}
                                className={`emailServer_div ${selectedServer === 'gmail' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{ border: selectedServer === 'gmail' ? '2px dashed #FFA412' : '2px dashed #D1D1D1', position: 'relative' }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'gmail' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="">
                                    <div className="my-3">
                                      <img
                                        src={gmail_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">Gmail</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'outlook' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('outlook')}
                                className={`emailServer_div ${selectedServer === 'outlook' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === 'outlook' ? '2px dashed #FFA412' : '2px dashed #D1D1D1',
                                    position: 'relative',
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'outlook' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={outlook_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>{' '}
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">Outlook</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'ovh' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('ovh')}
                                className={`emailServer_div ${selectedServer === 'ovh' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{ border: selectedServer === 'ovh' ? '2px dashed #FFA412' : '2px dashed #D1D1D1', position: 'relative' }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'ovh' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={ovh_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">OVH</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'hostinger' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('hostinger')}
                                className={`emailServer_div ${selectedServer === 'hostinger' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === 'hostinger' ? '2px dashed #FFA412' : '2px dashed #D1D1D1',
                                    position: 'relative',
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'hostinger' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={hostinger_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">Hostinger</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'godaddy' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('godaddy')}
                                className={`emailServer_div ${selectedServer === 'godaddy' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === 'godaddy' ? '2px dashed #FFA412' : '2px dashed #D1D1D1',
                                    position: 'relative',
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'godaddy' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={godaddy_icon}
                                        className=""
                                        style={{
                                          width: '60px',
                                          height: '30px',
                                          objectFit: 'cover',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">GoDaddy</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'infomaniak' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('infomaniak')}
                                className={`emailServer_div ${selectedServer === 'infomaniak' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === 'infomaniak' ? '2px dashed #FFA412' : '2px dashed #D1D1D1',
                                    position: 'relative',
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'infomaniak' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={informaniak_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">Infomaniak</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: '20px',
                                  padding: '20px 20px',
                                  borderRadius: '15px',
                                  background: selectedServer === 'custom' ? '#FFFAF1' : '#F7F8FB',
                                }}
                                onClick={() => autoConfigServerInfos('custom')}
                                className={`emailServer_div ${selectedServer === 'custom' && 'emailServer_div-selected'}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{ border: selectedServer === 'custom' ? '2px dashed #FFA412' : '2px dashed #D1D1D1', position: 'relative' }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === 'custom' ? 'flex' : 'none',
                                      position: 'absolute',
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '9px',
                                      background: '#05B714',
                                      transform: 'translate(40%, -40%)',
                                      right: '0px',
                                      top: '0px',
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={mail_custom_icon}
                                        className=""
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary">Autres</h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <h4
                          className="mb-4"
                          style={{
                            color: '#2F2F2F',
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '26px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                          }}>
                          Informations sur votre entreprise
                        </h4>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-email-input42">E-mail</Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-email-input42"
                                    placeholder="Entrer l'adresse e-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-lastname-input22">Mot de passe</Label>
                                  <Input
                                    className="form-control"
                                    id="basicpill-lastname-input22"
                                    placeholder="Entrer le mot de passe de votre boîte e-mail"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    // onBlur={validation.handleBlur}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-lastname-input22">Serveur de messagerie (IMAP)</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input22"
                                    placeholder="Entrer le serveur de messagerie de votre boîte e-mail"
                                    value={host}
                                    onChange={(e) => setHost(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-phoneno-input32">Port du serveur de messagerie (IMAP)</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input32"
                                    placeholder="Entrer le port du serveur de messagerie..."
                                    value={port}
                                    onChange={(e) => setPort(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-lastname-input22">Serveur d'envoie (SMTP)</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input22"
                                    placeholder="Entrer le serveur de messagerie de votre boîte e-mail"
                                    value={smtp_host}
                                    onChange={(e) => setSMTPHost(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-phoneno-input32">Port du serveur d'envoie (SMTP)</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input32"
                                    placeholder="Entrer le port du serveur d'envoie"
                                    value={smtp_port}
                                    onChange={(e) => setSMTPPort(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <div className="form-check d-flex align-items-center gap-2">
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={tls_mode}
                                      //  onChange={(e) => setTlsMode(!tls_mode)}(e) => console.log(e.target.checked)
                                      onChange={(e) => {
                                        setTlsMode(e.target.checked);
                                        onTlsModeChanged(e.target.checked);
                                      }}
                                    />
                                    <Label className="form-check-label"> Mode TLS ?</Label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>

                            <div lg="2" className="d-flex align-items-center" style={{ textAlign: 'end', marginTop: '8px' }}>
                              <button
                                type="button"
                                className="accordion-body-buttons"
                                style={{ marginTop: '10px', marginLeft: 'auto' }}
                                onClick={() => updateConfig()}>
                                {loading ? (
                                  <div style={{ textAlign: 'center' }}>
                                    <Spinner style={{ height: '15px', width: '15px' }} animation="border" /> Sauvegarde...
                                  </div>
                                ) : (
                                  'Enregistrer'
                                )}
                              </button>
                            </div>

                            {/*
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-lastname-input22">
                                  Nom complet ou Nom de l'entreprise (Pour la signature de vos envoies d'e-mail)
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-lastname-input22"
                                  placeholder="Entrer le nom complet ou nom de l'entreprise"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
  
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-phoneno-input32">
                                  Téléphone
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input32"
                                  placeholder="Entrer votre No. de tél"
                                />
                              </FormGroup>
                            </Col>
  
                          </Row>
                          */}
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4" style={{ paddingLeft: "10px" }}>Niveau de contrôle</h4>
                <div>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <div className="p-3">
                          <h5 className="font-size-14 mb-3 mt-0">
                            Ce paramètre définie le niveau de contrôle que vous laissez à notre système d'automation
                          </h5>

                          <Slider
                                    value={auto_response_level}
                                    min={1}
                                    max={10}
                                    labels={labels}
                                    orientation="horizontal"
                                    onChange={value => {
                                      setAutoResponseLevel(value);
                                    }}
                                  />
                          <div className="d-flex flex-wrap">
                            {contact_reasons_array.map((reason, index) => (
                              <div key={index} className="form-check form-switch mb-3" style={{ flex: "0 0 50%", maxWidth: "50%" }}>
                                Contenu de la première div
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`customSwitch${index}`}
                                  defaultChecked={contactReasonValues[reason.contact_reasons]}
                                  onClick={() => toggleContactReason(reason)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`customSwitch${index}`}
                                >
                                  {reason.french_text}
                                  <div style={{ fontWeight: "lighter" }}>
                                    {reason.explanation}
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>

                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card> */}

            {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4" style={{ paddingLeft: "10px" }}>Confirmation</h4>
                <div className="row justify-content-center">
                  <Col lg="12">
                    <div className="text-center">
                      <div className="mb-4">
                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                      </div>
                      <div>
                        <h5>Résumé</h5><br></br>
                        <p className="text-muted">
                          Email: {email} <br></br>
                          Mot de passe: **************<br></br>
                          Serveur de messagerie: {host}<br></br>
                          Port {port}<br></br>
                          Nom du site: {site_name}<br></br>
                          URL du site: {site_url}<br></br>
                          URL de la boutique shopify: {shopify_store_url}<br></br>
                          Clé API SHOPIFY: {shopify_api_key}<br></br><br></br>
                          <span className="text-decoration-underline">Signature:</span> <br></br>{signature}<br></br><br></br>
                          <span className="text-decoration-underline">Informations sur l'entreprise:</span> <br></br><br></br>{site_infos_more}<br></br>
                        </p>
                        <button
                          type="button"
                          className={`btn btn-primary waves-effect waves-light ${requiredValidations() === false ? 'disabled' : ""}`} onClick={() => {
                            validateConfig();
                          }}
                        >
                          {loading ? <div style={{ textAlign: "center" }}><Spinner animation="border" /> </div> : "CONFIRMER"}
                        </button>
                      </div>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card> */}
          </Col>
          {/* Old */}

          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '11' }}>
            <Toast isOpen={toast}>
              <ToastHeader toggle={toggleToast}>{/* <img src={logo} alt="" className="me-2" height="18" /> */}</ToastHeader>
              <ToastBody color="primary">{toastMessage}</ToastBody>
            </Toast>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingEmailConfig;
