import React, { useState, useEffect, componentDidMount } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Form,
  Col,
  CardBody,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Label,
  Progress,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import classnames from 'classnames';
//import InboxSidebar2 from "./Sidebar2";

//import { loadAuth2 } from 'gapi-script';
import { gapi, loadAuth2 } from 'gapi-script';
import gmailApi from 'react-gmail';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import avatar_1 from '../../assets/images/users/avatar-1.jpg';
import avatar_2 from '../../assets/images/users/avatar-2.jpg';
import avatar_3 from '../../assets/images/users/avatar-3.jpg';
import avatar_4 from '../../assets/images/users/avatar-4.jpg';
import avatar_5 from '../../assets/images/users/avatar-5.jpg';
import avatar_6 from '../../assets/images/users/avatar-6.jpg';
import avatar_7 from '../../assets/images/users/avatar-7.jpg';
import gmail_icon from '../../assets/images/mail-server-gmail_48.png';
import outlook_icon from '../../assets/images/mail-server-outlook_48.png';
import mail_custom_icon from '../../assets/images/mail-server-custom_48.png';
import { Client, Account, Databases } from 'appwrite';
import axios from 'axios';

//import '../../assets/css/icons.min.css';
import '../../assets/css/app.min.css';
import ReadEmailView from './ReadEmail';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const CLIENT_ID = '505437851076-gumpc4op5af3rq22g8bieipo4ma9ut9q.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

const EmailConfig = () => {
  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
      password1: '',
      email: '',
      digits: '',
      number: '',
      alphanumeric: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('This value is required'),
      password: Yup.string().required('This value is required'),
      password1: Yup.string().when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
      }),
      email: Yup.string().email('Must be a valid Email').max(255).required('Email is required'),
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!'
        )
        .required('Please enter correct Url'),
      digits: Yup.number().required('Please Enter Your Digits'),
      number: Yup.number().required('Please Enter Your Number'),
      alphanumeric: Yup.string()
        .matches(/^[a-z0-9]+$/i, 'Enter correct Alphanumeric!')
        .required('Please Enter Your Alphanumeric'),
      textarea: Yup.string().required('Please Enter Your Textarea'),
    }),
    onSubmit: (values) => {
      console.log('values', values);
    },
  });

  const navigate = useNavigate();

  const [activeTab, setactiveTab] = useState(1);
  const [activeTabwiz, setoggleTabwiz] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedStepswiz, setpassedStepswiz] = useState([1]);
  const [auto_response_level, setAutoResponseLevel] = useState(5);

  const labels = {
    1: '10',
    2: '20',
    3: '30',
    4: '40',
    5: '50',
    6: '60',
    7: '70',
    8: '80',
    9: '90',
    10: '100',
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTabwiz(tab) {
    if (activeTabwiz !== tab) {
      var modifiedSteps = [...passedStepswiz, tab];
      if (tab >= 1 && tab <= 4) {
        setoggleTabwiz(tab);
        setpassedStepswiz(modifiedSteps);
      }
    }
  }

  const [folderbtn, setfolderbtn] = useState(false);
  const [tagbtn, settagbtn] = useState(false);
  const [menubtn, setmenubtn] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesToRead, setMessagesToRead] = useState();
  const [selectedServer, setSelectedServer] = useState(undefined);
  const [host, setHost] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [port, setPort] = useState('');
  const [smtp_host, setSMTPHost] = useState('');
  const [smtp_port, setSMTPPort] = useState('');
  const [tls_mode, setTlsMode] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [company_infos, setCompanyInfos] = useState(undefined);
  const [site_name, setSiteName] = useState('');
  const [site_url, setSiteUrl] = useState('');
  const [shopify_store_url, setShopifyStoreUrl] = useState('');
  const [shopify_api_key, setShopifyStoreApiKey] = useState('');
  const [signature, setSignature] = useState('');
  const [site_infos_more, setSiteInfosMore] = useState('');
  const [contactReasonValues, setContactReasonValues] = useState({});
  const contact_reasons_array = [
    {
      contact_reasons: 'product_info',
      explanation:
        "Demandes d'informations supplémentaires sur un produit spécifique, comme ses caractéristiques et matériaux. Ceci exclut tout conseil sur son utilisation ou problème technique.",
      french_text: 'INFORMATION PRODUIT',
    },
    {
      contact_reasons: 'product_availability',
      explanation: "Demandes concernant la disponibilité en stock d'un produit spécifique, sans référence à son prix ou à toute promotion.",
      french_text: 'DISPONIBILITÉ PRODUIT',
    },
    {
      contact_reasons: 'product_advice',
      explanation:
        "Demandes de conseils pour l'utilisation d'un produit, hors problèmes techniques ou questions concernant les caractéristiques spécifiques du produit.",
      french_text: 'CONSEILS PRODUITS',
    },
    {
      contact_reasons: 'product_problems',
      explanation:
        "Demandes liées aux problèmes de produit qui ne sont pas liées à la livraison ou au service après-vente. Ce tag ne concerne pas les problèmes liés à l'utilisation du produit, qui relèvent des conseils sur un produit.",
      french_text: 'PROBLÈMES PRODUITS',
    },
    {
      contact_reasons: 'product_price',
      explanation: "Demandes d'informations sur les prix des produits sans référence aux promotions ou à la disponibilité du produit.",
      french_text: 'PRIX',
    },
    {
      contact_reasons: 'promotions',
      explanation: 'Questions spécifiquement liées aux promotions en cours, sans référence au prix normal du produit.',
      french_text: 'PROMOTIONS',
    },
    {
      contact_reasons: 'order_process',
      explanation: 'Questions sur le processus de commande, excluant les problèmes de paiement, de remboursement et de livraison.',
      french_text: 'PROCESSUS COMMANDE',
    },
    {
      contact_reasons: 'payments',
      explanation: "Demandes spécifiques aux problèmes liés au paiement d'une commande, sans référence au processus de commande ou au remboursement.",
      french_text: 'PAIEMENTS',
    },
    {
      contact_reasons: 'refunds',
      explanation: 'Demandes spécifiques aux remboursements et non aux autres aspects de la facturation ou du paiement.',
      french_text: 'REMBOURSEMENTS',
    },
    {
      contact_reasons: 'disputes',
      explanation:
        'Demandes liées à des litiges concernant des transactions commerciales, sans référence directe aux remboursements, aux paiements ou au processus de commande.',
      french_text: 'LITIGES',
    },
    {
      contact_reasons: 'billing',
      explanation: 'Demandes liées à la facturation hors remboursements, paiements ou litiges.',
      french_text: 'FACTURATION',
    },
    {
      contact_reasons: 'delivery',
      explanation: 'Demandes uniquement liées au processus de livraison, sans référence au retour de produit ou au processus de commande.',
      french_text: 'LIVRAISON',
    },
    {
      contact_reasons: 'returns',
      explanation: "Demandes spécifiquement liées au retour d'un produit, sans référence à la livraison ou au service après-vente.",
      french_text: 'RETOUR',
    },
    {
      contact_reasons: 'customer_account',
      explanation: 'Demandes liées aux informations du compte, hors problèmes de mot de passe ou problèmes de sécurité.',
      french_text: 'COMPTE CLIENT',
    },
    {
      contact_reasons: 'password',
      explanation: "Demandes spécifiques à la réinitialisation d'un mot de passe, sans référence à d'autres aspects de la gestion du compte client.",
      french_text: 'MOT DE PASSE',
    },
    {
      contact_reasons: 'security',
      explanation:
        'Demandes concernant la sécurité du site et les problèmes de fraude, sans référence à la confidentialité des données ou à la gestion du compte client.',
      french_text: 'SÉCURITÉ',
    },
    {
      contact_reasons: 'privacy',
      explanation:
        "Demandes sur la politique de confidentialité et l'utilisation des données par l'entreprise, sans référence à la sécurité du site ou à la manière dont les données sont stockées.",
      french_text: 'CONFIDENTIALITÉ',
    },
    {
      contact_reasons: 'customer_data',
      explanation:
        'Demandes sur la manière dont les données des clients sont stockées et protégées, sans référence à la politique de confidentialité ou à la sécurité du site.',
      french_text: 'DONNÉES CLIENTS',
    },
    {
      contact_reasons: 'after_sales',
      explanation:
        'Demandes adressées au service après-vente, exclusivement pour les réparations. Cela exclut les problèmes liés aux produits eux-mêmes.',
      french_text: 'SAV',
    },
    {
      contact_reasons: 'technical_problems',
      explanation: 'Demandes liées aux problèmes techniques spécifiques au site web, sans référence à la sécurité du site.',
      french_text: 'PROBLÈMES TECHNIQUES',
    },
    {
      contact_reasons: 'website_feedback',
      explanation: 'Demandes pour donner des commentaires sur le site web, sans référence aux problèmes techniques.',
      french_text: 'COMMENTAIRES SITE WEB',
    },
    {
      contact_reasons: 'special_programs',
      explanation: "Demandes liées à des programmes qui ne sont pas liés à la fidélisation ou à l'affiliation.",
      french_text: 'PROGRAMMES SPÉCIAUX',
    },
    {
      contact_reasons: 'affiliation',
      explanation: "Demandes spécifiques à l'affiliation commerciale, sans référence aux programmes spéciaux ou à la fidélisation.",
      french_text: 'AFFILIATION',
    },
    {
      contact_reasons: 'customer_loyalty',
      explanation: 'Demandes liées aux programmes de fidélisation, excluant les autres types de programmes et les programmes spéciaux.',
      french_text: 'FIDÉLISATION CLIENTÈLE',
    },
    {
      contact_reasons: 'feedback',
      explanation: 'Demandes de feedbacks ou commentaires sur les produits, sans référence aux plaintes.',
      french_text: 'FEEDBACKS',
    },
    {
      contact_reasons: 'complaints',
      explanation: 'Demandes spécifiques liées à des plaintes de la part des clients, sans référence aux feedbacks.',
      french_text: 'PLAINTES',
    },
    {
      contact_reasons: 'company_information',
      explanation: "Demandes d'informations générales sur l'entreprise, sans référence à l'éthique ou à la responsabilité sociale de l'entreprise.",
      french_text: 'INFORMATION ENTREPRISE',
    },
    {
      contact_reasons: 'ethics_social_responsibility',
      explanation:
        "Demandes concernant la responsabilité sociale de l'entreprise et les pratiques éthiques, sans référence aux informations générales sur l'entreprise.",
      french_text: 'ÉTHIQUE & RSE',
    },
    {
      contact_reasons: 'subscription_management',
      explanation:
        "Demandes spécifiques à la gestion des abonnements, comme par exemple l'inscription à la newsletter, sans référence à la gestion du compte client.",
      french_text: 'GESTION ABONNEMENTS',
    },
    {
      contact_reasons: 'store_services',
      explanation: 'Demandes spécifiques aux services proposés en magasin, sans référence aux produits ou aux services en ligne.',
      french_text: 'SERVICES MAGASINS',
    },
    {
      contact_reasons: 'influencers',
      explanation:
        'Demandes liées aux collaborations avec des influenceurs, sans référence aux collaborations avec des créateurs de contenu non-influenceurs.',
      french_text: 'INFLUENCEURS',
    },
    {
      contact_reasons: 'content_creators',
      explanation: 'Demandes liées aux collaborations avec des créateurs de contenu qui ne sont pas des influenceurs.',
      french_text: 'CRÉATEURS CONTENUS',
    },
    {
      contact_reasons: 'spam',
      explanation: "Demandes où l'IA détecte des comportements de spam, sans référence à d'autres problèmes de sécurité.",
      french_text: 'SPAM',
    },
  ];

  const toggleContactReason = (reason) => {
    if (contactReasonValues[reason.contact_reasons]) {
      const updatedValues = { ...contactReasonValues };
      delete updatedValues[reason.contact_reasons];
      setContactReasonValues(updatedValues);
    } else {
      const updatedValues = { ...contactReasonValues };
      updatedValues[reason.contact_reasons] = reason.contact_reasons;
      setContactReasonValues(updatedValues);
    }
  };

  const onSelectedServerChanged = (val) => {
    setSelectedServer(val);
  };

  const onLoadingChanged = (val) => {
    setLoading(val);
  };
  // const timer = setTimeout(() => { }, 20000);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const signOut = async () => {
    var auth2 = gapi.auth2.getAuthInstance();
    await auth2.signOut();
    console.log('User signed out.');
    if (!auth2.isSignedIn.get()) {
      setIsSignedIn(auth2.isSignedIn.get());

      setToastMessage('Vous avez révoqué la synchronisation à votre boite e-mail.');
      toggleToast();
    }
  };

  async function createCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.createDocument('default', 'client_infos', localStorage.getItem('companyId'), data);

    promise.then(
      async function (response) {
        console.log(response); // Success
        await launchServer(40000, 1);
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage('Echec : ' + error.message);
        toggleToast();

        console.log('createCompanyInfos', error); // Failure
      }
    );
  }
  async function updateCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.updateDocument('default', 'client_infos', localStorage.getItem('companyId'), data);

    promise.then(
      async function (response) {
        onLoadingChanged(false);
        setToastMessage('Modifications effectuées');
        toggleToast();

        console.log(response); // Success
        if (response && response.server_active === false) {
          await launchServer(40000, 1);
        } else {
          console.log('server already launched');
        }
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage('Echec: ' + error.message);
        toggleToast();

        console.log(error); // Failure
      }
    );
  }

  const getCompanyInfos = async function () {
    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.getDocument('default', 'client_infos', localStorage.getItem('companyId'));

    promise.then(
      function (response) {
        //    console.log(response);
        setCompanyInfos(response);
        setSiteName(response.company ?? '');
        setSiteUrl(response.company_website_url ?? '');
        setSiteInfosMore(response.more_info ?? '');
        setShopifyStoreUrl(response.shopify_store_url ?? '');
        setShopifyStoreApiKey(response.shopify_api_key ?? '');
        setSignature(response.signature ?? '');

        setEmail(response.email ?? '');
        setPassword(response.password ?? '');
        setPort(response.port ?? '');
        setHost(response.host ?? '');
        setSMTPHost(response.smtp_host ?? '');
        setSMTPPort(response.smtp_port ?? '');
        setTlsMode(response.tls);

        setAutoResponseLevel(response.auto_response_level ?? 0);
        const reasons = response.contact_reasons;
        // console.log("reasons", reasons);
        const reasonMap = {};
        for (const reason of reasons) {
          reasonMap[reason] = reason;
        }
        setContactReasonValues(reasonMap);
        // console.log("reasonsMap", reasonMap);
        return response;
      },
      function (error) {
        console.log(error); // Failure
        return null;
      }
    );
  };

  /*
  const checkUserPrefs = async function () {
    const client = new Client();

    const account = new Account(client);

    client
      .setEndpoint('https://api.shopybase.com/v1')
      .setProject('ai_project_dev');


    const promise = account.getPrefs();

    promise.then(function (response) {
      console.log(response);

      setEmail(response["email"] ?? "");
      setPassword(response["password"] ?? "");
      setPort(response["port"] ?? "");
      setHost(response["host"] ?? "");
      setSMTPHost(response["smtp_host"] ?? "");
      setSMTPPort(response["smtp_port"] ?? "");
      setTlsMode(response["tls"] ?? "");
      
      setAutoResponseLevel(response["auto_response_level"] ?? 0);

      return response;
    }, function (error) {
      console.log(error); // Failure
      return null;
    });
  }
*/

  /*
    async function saveUserPrefs(data) {
      setLoading(true);
  
      const client = new Client();
  
      const account = new Account(client);
  
      client
        .setEndpoint('https://api.shopybase.com/v1')
        .setProject('ai_project_dev');
  
  
      const promise = account.updatePrefs(data);
  
      promise.then(function (response) {
  
        console.log(response); // Success
      }, function (error) {
  
        console.log(error); // Failure
      });
    }
    */

  async function validateConfig() {
    if (!company_infos || company_infos.mail_config !== true) {
      await createCompanyInfos({
        company: site_name,
        company_website_url: site_url,
        more_info: site_infos_more,
        shopify_store_url: shopify_store_url,
        shopify_api_key: shopify_api_key,
        signature: signature,
        email: email,
        password: password,
        host: host,
        port: port,
        smtp_host: smtp_host,
        smtp_port: smtp_port,
        auto_response_level: auto_response_level,
        tls: tls_mode,
        mail_config: true,
        server_active: false,
      });
    } else {
      await updateCompanyInfos({
        company: site_name,
        company_website_url: site_url,
        more_info: site_infos_more,
        shopify_store_url: shopify_store_url,
        shopify_api_key: shopify_api_key,
        signature: signature,
        email: email,
        password: password,
        host: host,
        port: port,
        smtp_host: smtp_host,
        smtp_port: smtp_port,
        auto_response_level: auto_response_level,
        tls: tls_mode,
        mail_config: true,
        contact_reasons: Object.keys(contactReasonValues),
      });
    }
  }

  const requiredValidations = () => {
    if (
      email &&
      email !== null &&
      email.length > 5 &&
      host &&
      host !== null &&
      host !== '' &&
      port &&
      port !== null &&
      port !== '' &&
      site_name &&
      site_name !== null &&
      site_name !== '' &&
      site_url &&
      site_url !== null &&
      site_url !== '' &&
      shopify_store_url &&
      shopify_store_url !== null &&
      shopify_store_url !== '' &&
      shopify_api_key &&
      shopify_api_key !== null &&
      shopify_api_key !== '' &&
      site_infos_more &&
      site_infos_more !== null &&
      site_infos_more !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  async function launchServer(lport, count) {
    const companyId = localStorage.getItem('companyId');
    axios
      .post(
        'https://mailer-ai.semecloud.tech/launch-mailer',
        // "http://localhost:30000/launch-mailer",
        {
          id: companyId,
          company_id: companyId,
        }
      )
      .then(async (response) => {
        console.log(`Server launched on port ${lport}. Launch response is`, response);

        // company_infos.server_active = true;

        await updateCompanyInfos({ server_active: true });
        goToInboxPage();
      })
      .catch(async (error) => {
        console.log(`Server launch failed on port ${lport}: `, error);
        count += 1;
        lport += 1;
        if (count < 10) {
          await launchServer(lport, count);
        } else if (count === 10) {
          goToInboxPage();
        }
      });
  }

  function autoConfigServerInfos(server) {
    onSelectedServerChanged(server);

    if (server === 'gmail') {
      setHost('imap.gmail.com');
      setEmail('@gmail.com');
      setPort('993');
    } else if (server === 'outlook') {
      setHost('outlook.office365.com');
      setEmail('@outlook.com');
      setPort('993');
    } else {
      setHost('');
      setEmail('');
      setPort('');
    }
  }

  const handleSignIn = async () => {
    try {
      const auth = await loadAuth2(gapi, CLIENT_ID, SCOPES);
      // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')

      if (!auth.isSignedIn.get()) {
        await auth.signIn();
      }
      setIsSignedIn(auth.isSignedIn.get());
      // Si l'utilisateur est connecté, obtenez l'accès à l'API Gmail.
      if (auth.isSignedIn.get()) {
        const googleUser = auth.currentUser.get();
        const accessToken = googleUser.getAuthResponse().access_token;
        // Utilisez `accessToken` pour accéder à l'API Gmail.
        console.log(`Access token: ${accessToken}`);
        // getMessages();

        navigate('/main-inbox');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadGmailEmailsIfConnected = async () => {
    try {
      const auth = await loadAuth2(gapi, CLIENT_ID, SCOPES);
      // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')

      setIsSignedIn(auth.isSignedIn.get());
      // Si l'utilisateur est connecté, obtenez l'accès à l'API Gmail.
      if (auth.isSignedIn.get()) {
        const googleUser = auth.currentUser.get();
        const accessToken = googleUser.getAuthResponse().access_token;
        // Utilisez `accessToken` pour accéder à l'API Gmail.
        console.log(`Access token: ${accessToken}`);
        getMessages();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMessages = async () => {
    const res = await gmailApi.getMessages(false, 15);
    setMessages(gmailApi.normalizeData(res));
  };

  const toggleToast = () => {
    console.log('setToast before: ' + toast);
    setToast(true);
    console.log('setToast after: ' + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  function formaterDate(date) {
    const dateObj = new Date(date);
    const maintenant = new Date();
    const estAujourdhui = dateObj.toDateString() === maintenant.toDateString();
    const mois = dateObj.toLocaleString('default', { month: 'short' });
    const jour = estAujourdhui ? "Aujourd'hui" : dateObj.getDate();
    const annee = dateObj.getFullYear().toString().substr(-2);
    const heures = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return estAujourdhui ? `${jour}, ${heures}:${minutes}` : `${jour} ${mois} ${annee}, ${heures}:${minutes}`;
  }

  function goToInboxPage() {
    navigate('/main-inbox', {
      replace: false,
    });
  }
  useEffect(() => {
    getCompanyInfos();
    // loadGmailEmailsIfConnected();
    // return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Breadcrumbs title="Email" breadcrumbItem="Configuration" />
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Configuration pour votre service d'auto-réponse e-mails</h4>
                <div id="progrss-wizard" className="twitter-bs-wizard">
                  <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                    <NavItem
                      className={classnames({
                        active: activeTabwiz === 1,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabwiz === 1,
                        })}
                        onClick={() => {
                          toggleTabwiz(1);
                        }}>
                        <span className="step-number">01</span>
                        <span className="step-title" style={{ paddingLeft: '10px' }}>
                          Boite e-mail
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({
                        active: activeTabwiz === 2,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabwiz === 2,
                        })}
                        onClick={() => {
                          toggleTabwiz(2);
                        }}>
                        <span className="step-number">02</span>
                        <span className="step-title" style={{ paddingLeft: '10px' }}>
                          Informations sur votre entreprise
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({
                        active: activeTabwiz === 3,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabwiz === 3,
                        })}
                        onClick={() => {
                          toggleTabwiz(3);
                        }}>
                        <span className="step-number">03</span>
                        <span className="step-title" style={{ paddingLeft: '10px' }}>
                          Niveau de contrôle
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({
                        active: activeTabwiz === 4,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabwiz === 4,
                        })}
                        onClick={() => {
                          toggleTabwiz(4);
                        }}>
                        <span className="step-number">04</span>
                        <span className="step-title" style={{ paddingLeft: '10px' }}>
                          Confirmation
                        </span>
                      </NavLink>
                    </NavItem>
                  </ul>
                  <div id="bar" className="mt-4">
                    <div className="mb-4">
                      <Progress value={25 * activeTabwiz} color="success" animated></Progress>
                    </div>
                  </div>

                  <TabContent activeTab={activeTabwiz} className="twitter-bs-wizard-tab-content">
                    <TabPane tabId={1}>
                      <Card className="mb-0">
                        <CardBody>
                          <h4 className="font-size-20 text-center">Configuration de votre boite e-mail</h4>
                          <br></br>
                          <p>
                            Nous allons établir une connexion à votre boîte mail.<br></br> Sélectionnez votre serveur de messagerie pour continuer.
                          </p>
                          <p></p>
                          <hr />
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <Row className="justify-content-center mt-3">
                                    <Col lg={8}>
                                      <Row>
                                        <Col lg={3}>
                                          <div
                                            onClick={() => autoConfigServerInfos('gmail')}
                                            className={`border ${selectedServer === 'gmail' ? 'border-success' : ''} p-3 text-center rounded mb-4 `}>
                                            <Link to="">
                                              <div className="my-3">
                                                <img src={gmail_icon} className="avatar-sm" alt=""></img>
                                              </div>
                                              <h5 className="font-size-15 mb-3">Gmail</h5>
                                            </Link>
                                          </div>
                                        </Col>

                                        <Col lg={3}>
                                          <div
                                            onClick={() => autoConfigServerInfos('outlook')}
                                            className={`border ${
                                              selectedServer === 'outlook' ? 'border-success' : ''
                                            } p-3 text-center rounded mb-4 `}>
                                            <Link to="#">
                                              <div className="my-3">
                                                <img src={outlook_icon} className="avatar-sm" alt=""></img>{' '}
                                              </div>
                                              <h5 className="font-size-15 mb-3">Outlook</h5>
                                            </Link>
                                          </div>
                                        </Col>
                                        <Col lg={3}>
                                          <div
                                            onClick={() => autoConfigServerInfos('custom')}
                                            className={`border ${selectedServer === 'custom' ? 'border-success' : ''} p-3 text-center rounded mb-4 `}>
                                            <Link to="#">
                                              <div className="my-3">
                                                <img src={mail_custom_icon} className="avatar-sm" alt=""></img>{' '}
                                              </div>
                                              <h5 className="font-size-15 mb-3">Autres</h5>
                                            </Link>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-email-input42">E-mail</Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="basicpill-email-input42"
                                placeholder="Entrer l'adresse e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-lastname-input22">Mot de passe</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input22"
                                placeholder="Entrer le mot de passe de votre boîte e-mail"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-lastname-input22">Serveur de messagerie (IMAP)</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input22"
                                placeholder="Entrer le serveur de messagerie de votre boîte e-mail"
                                value={host}
                                onChange={(e) => setHost(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-phoneno-input32">Port du serveur de messagerie (IMAP)</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-phoneno-input32"
                                placeholder="Entrer le port du serveur de messagerie..."
                                value={port}
                                onChange={(e) => setPort(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-lastname-input22">Serveur d'envoie (SMTP)</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input22"
                                placeholder="Entrer le serveur de messagerie de votre boîte e-mail"
                                value={smtp_host}
                                onChange={(e) => setSMTPHost(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-phoneno-input32">Port du serveur d'envoie (SMTP)</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-phoneno-input32"
                                placeholder="Entrer le port du serveur d'envoie"
                                value={smtp_port}
                                onChange={(e) => setSMTPPort(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup className="mb-3">
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="invalidCheck"
                                  defaultChecked={tls_mode}
                                  //  onChange={(e) => setTlsMode(!tls_mode)}(e) => console.log(e.target.checked)
                                  onChange={(e) => setTlsMode(e.target.checked)}
                                />
                                <Label className="form-check-label" htmlFor="invalidCheck">
                                  {' '}
                                  Mode TLS ?
                                </Label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/*
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-lastname-input22">
                                Nom complet ou Nom de l'entreprise (Pour la signature de vos envoies d'e-mail)
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input22"
                                placeholder="Entrer le nom complet ou nom de l'entreprise"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">

                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-phoneno-input32">
                                Téléphone
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-phoneno-input32"
                                placeholder="Entrer votre No. de tél"
                              />
                            </FormGroup>
                          </Col>

                        </Row>
                        */}
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validationType.handleSubmit();
                            return false;
                          }}>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-vatno-input62">Nom du site</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-vatno-input62"
                                  placeholder="Entrez le nom du site"
                                  value={site_name}
                                  onChange={(e) => setSiteName(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-vatno-input62">URL du site</Label>

                                <div className="input-group">
                                  <div className="input-group-text">https://</div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="URL du site"
                                    value={site_url}
                                    onChange={(e) => setSiteUrl(e.target.value)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-vatno-input62">URL de la boutique shopify</Label>

                                <div className="input-group">
                                  <div className="input-group-text">https://</div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inlineFormInputGroupUsername"
                                    placeholder="Entrez l'url au format boutique.myshopify.com"
                                    value={shopify_store_url}
                                    onChange={(e) => setShopifyStoreUrl(e.target.value)}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label className="form-label">Clé API SHOPIFY</Label>
                                <Input
                                  name="shopify_key"
                                  placeholder="Entrez ici votre clé API SHOPIFY"
                                  type="text"
                                  value={shopify_api_key}
                                  onChange={(e) => setShopifyStoreApiKey(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            {/*
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-cstno-input72">
                                  Domaine d'activité
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cstno-input72"
                                  placeholder="Entrer le/les domaine(s) d'activité"
                                />
                              </FormGroup>
                            </Col>
                            */}
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-cstno-input72">Signature</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cstno-input72"
                                  placeholder="Entrer la signature que vous souhaitez voir à la fin de vos mails envoyés"
                                  value={signature}
                                  onChange={(e) => setSignature(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="basicpill-servicetax-input82">Informations sur l'entreprise</Label>
                              <textarea
                                maxLength="6500"
                                type="text"
                                className="form-control"
                                id="basicpill-servicetax-input82"
                                placeholder="Fournissez ici toutes les informations utiles sur votre entreprise"
                                value={site_infos_more}
                                onChange={(e) => setSiteInfosMore(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div>
                        <Form>
                          <Row>
                            <Col md={12}>
                              <div className="p-3">
                                <h5 className="font-size-14 mb-3 mt-0">
                                  Ce paramètre définie le niveau de contrôle que vous laissez à notre système d'automation
                                </h5>

                                {/* <Slider
                                  value={auto_response_level}
                                  min={1}
                                  max={10}
                                  labels={labels}
                                  orientation="horizontal"
                                  onChange={value => {
                                    setAutoResponseLevel(value);
                                  }}
                                /> */}
                                <div className="d-flex flex-wrap">
                                  {contact_reasons_array.map((reason, index) => (
                                    <div key={index} className="form-check form-switch mb-3" style={{ flex: '0 0 50%', maxWidth: '50%' }}>
                                      {/* Contenu de la première div */}
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`customSwitch${index}`}
                                        defaultChecked={contactReasonValues[reason.contact_reasons]}
                                        onClick={() => toggleContactReason(reason)}
                                      />
                                      <label className="form-check-label" htmlFor={`customSwitch${index}`}>
                                        {reason.french_text}
                                        <div style={{ fontWeight: 'lighter' }}>{reason.explanation}</div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <Col lg="12">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Résumé</h5>
                              <br></br>
                              <p className="text-muted">
                                Email: {email} <br></br>
                                Mot de passe: **************<br></br>
                                Serveur de messagerie: {host}
                                <br></br>
                                Port {port}
                                <br></br>
                                Nom du site: {site_name}
                                <br></br>
                                URL du site: {site_url}
                                <br></br>
                                URL de la boutique shopify: {shopify_store_url}
                                <br></br>
                                Clé API SHOPIFY: {shopify_api_key}
                                <br></br>
                                <br></br>
                                <span className="text-decoration-underline">Signature:</span> <br></br>
                                {signature}
                                <br></br>
                                <br></br>
                                <span className="text-decoration-underline">Informations sur l'entreprise:</span> <br></br>
                                <br></br>
                                {site_infos_more}
                                <br></br>
                              </p>
                              <button
                                type="button"
                                className={`btn btn-primary waves-effect waves-light ${requiredValidations() === false ? 'disabled' : ''}`}
                                onClick={() => {
                                  validateConfig();
                                }}>
                                {loading ? (
                                  <div style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" />{' '}
                                  </div>
                                ) : (
                                  'CONFIRMER'
                                )}
                              </button>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                  </TabContent>

                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                    <li className={activeTabwiz === 1 ? 'previous disabled me-2' : 'previous me-2'}>
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTabwiz(activeTabwiz - 1);
                        }}>
                        Précédent
                      </Link>
                    </li>
                    <li className={activeTabwiz === 4 ? 'next disabled' : 'next'}>
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTabwiz(activeTabwiz + 1);
                        }}>
                        Suivant
                      </Link>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '11' }}>
            <Toast isOpen={toast}>
              <ToastHeader toggle={toggleToast}>{/* <img src={logo} alt="" className="me-2" height="18" /> */}</ToastHeader>
              <ToastBody color="primary">{toastMessage}</ToastBody>
            </Toast>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailConfig;
