import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import { Client, Account, Databases, Query } from 'appwrite';

const chartStyle = {
  width: '100%',
  //   height: "44px",
};

const LiveGaugeChart = () => {
  const [value, setValue] = useState(0.5); // Initial value
  const [company_infos, setCompanyInfos] = useState();

  async function getCompanyInfos() {
    const client = new Client();

    const databases = new Databases(client, 'default');

    client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

    const promise = databases.getDocument('default', 'client_infos', localStorage.getItem('companyId'));

    promise.then(
      async function (response) {
        var data = response;
        console.log('data.token_usage', data.token_usage);
        setCompanyInfos(data);
        setValue(!data.token_usage ? 0 : (data.token_usage * 100) / 20000 / 100);
      },
      function (error) {
        console.log(error); // Failure
      }
    );
  }

  // Simulate live data updates
  useEffect(() => {
    getCompanyInfos();
    const interval = setInterval(() => {
      const newValue = Math.random(); // Generate a random value between 0 and 1
    }, 100000); // Update every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: '77px', height: '44px' }}>
        <GaugeChart
          id="gauge-chart1"
          style={chartStyle}
          marginInPercent={0}
          cornerRadius={0}
          nrOfLevels={6}
          percent={value}
          arcPadding={0.03}
          arcWidth={0.23}
          colors={['#FED6CB', '#FFC4B5', '#FFB49F', '#FF9275', '#FF805F', '#FF7049']}
          needleColor={'#151D48'}
          needleBaseColor={'#151D48'}
          hideText={true}
          // arcsLength={[0.3, 0.17, 0.17, 0.17, 0.17, 0.3]}
          animate={false}
          // animDelay={500}
          // animateDuration={3000}
          // formatTextValue={null}
        />
      </div>
      <div className="gauge-stats-container ms-3">
        <span
          className="gauge-stats__number"
          style={{
            color: '#9DA4B1',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
          }}>
          {company_infos ? company_infos.token_usage : '0'} /{' '}
        </span>
        <span
          className="gauge-stats__total"
          style={{
            color: '#151D48',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
          }}>
          20000
        </span>
      </div>
    </div>
  );
};

export default LiveGaugeChart;
